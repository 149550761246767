
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useArenaList } from '@/modules/arena/item/model/list';
import { RouterLink } from 'vue-router';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'ArenaItemList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, beforeFetch, fetchListIncludedBetName, routes, actions } = useArenaList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Bet',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.betName,
                        props: { to: `/app/game/bet/${row.betId}/detail` }
                    })
                },
                { label: 'Price', field: 'price' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        onclick: () => routes.clone(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-medal',
                        theme: 'info',
                        tooltip: 'Show medal',
                        onclick: () => routes.medal(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-crown',
                        theme: 'info',
                        tooltip: 'Show ranking',
                        onclick: () => routes.rank(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable && row.status === 0,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 3
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-archive',
                        theme: 'warning',
                        tooltip: 'Archive',
                        isShow: row.canArchive,
                        onclick: () => actions.archive(row.id)
                    }),
                    group: 4
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchListIncludedBetName(currentPage.value);
        });

        return { table, fetchListIncludedBetName, routes };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Published', value: 2, background: 'info' },
    { label: 'Started', value: 3, background: 'warning' },
    { label: 'Closed', value: 4, background: 'secondary' },
    { label: 'Ended', value: 5, background: 'secondary' },
    { label: 'Unpublished', value: 6, background: 'success' },
    { label: 'Disabled', value: 7, background: 'info' },
    { label: 'Rewarded', value: 8, background: 'warning' },
    { label: 'Archive', value: 9, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
