
import { defineComponent } from 'vue';

/* bind and emit an update:modelValue custom event with the new value when a native input event is triggered
 * ref: https://vuejs.org/guide/components/v-model.html
 */
export default defineComponent({
    name: 'AppFileUpload',
    props: {
        accept: {
            type: String,
            default: 'image/*'
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        function onValueChange(event: any) {
            var newFile = event.target.files[0];

            var formatAccept = props.accept;
            var canChange = false;

            if (formatAccept.startsWith('image')) {
                if (formatAccept == 'image/*' || newFile.type == formatAccept) {
                    canChange = true;
                }
            } else {
                canChange = true;
            }

            if (canChange) {
                emit('update:modelValue', newFile);
            }
        }

        return { onValueChange };
    }
});
