
import { defineComponent } from 'vue';
import { AppError } from '@/components';
import DateTimePicker from '@vuepic/vue-datepicker';

export default defineComponent({
    name: 'AppDateTimePicker',
    components: { AppError, DateTimePicker },
    props: {
        modelValue: { type: Date, default: null },
        validations: { type: Object, default: null },
        disabled: { type: Boolean, default: false }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        function onValueChange(value: any) {
            emit('update:modelValue', value);

            if (props.validations) {
                props.validations.$touch();
            }
        }

        return { onValueChange };
    }
});
