
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { color } from '@/utils/helpers';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'RfmGroupList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, fetchList, actions, routes } = useRfmGroupList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                {
                    label: 'Name',
                    columnClasses: ['w-50'],
                    display: (row: any) =>
                        `<font color="${color(row.id)}"><i class="fas fa-bookmark"></i></font> ${row.name}`
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-calendar-alt',
                        theme: 'success',
                        tooltip: 'Show recencies',
                        onclick: () => routes.recency(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-file-invoice-dollar',
                        theme: 'success',
                        tooltip: 'Show frequencies',
                        onclick: () => routes.frequency(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-dollar-sign',
                        theme: 'success',
                        tooltip: 'Show monetaries',
                        onclick: () => routes.monetary(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-users-cog',
                        theme: 'primary',
                        tooltip: 'Show personas',
                        onclick: () => routes.persona(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-sliders-h',
                        theme: 'warning',
                        tooltip: 'Show segmentation',
                        onclick: () => routes.segmentation(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, routes };
    }
});
