import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Game Parameter"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Phase Names" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.phaseNames,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.phaseNames) = $event)),
              validations: _ctx.v$.phaseNames
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Phase Times" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.phaseTimes,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.phaseTimes) = $event)),
              validations: _ctx.v$.phaseTimes
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Rank Penalty Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.rankPenaltyPercent,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.rankPenaltyPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.rankPenaltyPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bot Pay Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.botPayPercent,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.botPayPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.botPayPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bot Playable Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.botPlayableTime,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.botPlayableTime) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.botPlayableTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bet Range" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.betRange,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.betRange) = $event)),
              validations: _ctx.v$.betRange
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Rank Boost Charge" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.rankBoostCharge,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.rankBoostCharge) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.rankBoostCharge
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Rank Boost Reject Limit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.rankBoostRejectLimit,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.rankBoostRejectLimit) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.rankBoostRejectLimit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Lobby Limit Table" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.lobbyLimitTable,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.lobbyLimitTable) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.lobbyLimitTable
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              icon: "fa-save",
              theme: "info",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            (_ctx.model.canRemove)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}