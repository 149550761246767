import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useItemEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        ruby: 0,
        quantity: null,
        image: null,
        imageStatus: null,
        imageFile: null,
        canDelete: false
    });

    const validations = {
        imageFile: {},
        name: { required },
        ruby: { required, min: minValue(0), max: maxValue(32000) },
        quantity: { min: minValue(1), max: maxValue(100) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await itemService.item.get(route.params.itemId);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push({ name: 'SystemItemList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await itemService.item.update(
                model.id,
                model.name,
                model.ruby,
                model.quantity,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Update Successfully');
                router.push({ name: 'SystemItemList' });
            }
        },

        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'SystemItemList' });
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
