
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker } from '@/components';
import { useProgressAdd } from '@/modules/progress/model/add';

export default defineComponent({
    name: 'ProgressAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, create } = useProgressAdd();

        onMounted(() => {
            fetch();
        });

        return { v$, model, create };
    }
});
