import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useArenaList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const bets = {} as any;
    const store = useStore();
    const arenaItemStore = {
        getPage: () => store.getters['arenaItem/page'],
        setPage: (page: number) => store.dispatch('arenaItem/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(arenaService.item.list, {
        pagingStore: arenaItemStore
    });

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push({ name: 'Home' });
            return;
        }

        const tmpVariants = variantResponse.data;
        const tmpBets = betResponse.data;

        for (let i = 0; i < tmpBets.length; i++) {
            const bet = tmpBets[i];
            for (let j = 0; j < tmpVariants.length; j++) {
                const variant = tmpVariants[j];
                if (bet.variantId === variant.id) {
                    bets[bet.id] = variant.name + ' - ' + bet.name;
                    break;
                }
            }
        }
    };

    const fetchListIncludedBetName = async (page: number) => {
        await changePage(page);

        itemList.value = itemList.value.map(item => {
            return { ...item, betName: bets[item.betId] };
        });
    };

    const actions = {
        archive: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to archive this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.archive(id);
                        if (response.code === 200) {
                            toast.success('Archive successfully');
                            fetchListIncludedBetName(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchListIncludedBetName(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchListIncludedBetName(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchListIncludedBetName(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ArenaItemAdd' }),
        edit: (id: any) => router.push({ name: 'ArenaItemEdit', params: { id: id } }),
        clone: (id: any) => router.push({ name: 'ArenaItemClone', params: { id: id } }),
        medal: (id: any) => router.push({ name: 'ArenaItemMedal', params: { id: id } }),
        rank: (id: any) => router.push({ name: 'ArenaItemRank', params: { id: id } })
    };

    return { itemList, total, currentPage, beforeFetch, fetchListIncludedBetName, actions, routes };
}
