import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Function",
      classes: "pb-0"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_app_button, {
            name: "Add new bet",
            icon: "fa-plus",
            theme: "info",
            disabled: _ctx.model.variantId == null,
            onClick: _ctx.routes.add
          }, null, 8, ["disabled", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_app_form_row, {
          label: "Variant",
          class: "col-md-6 mx-auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.model.variantOptions,
              modelValue: _ctx.model.variantId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.variantId) = $event)),
              modelModifiers: { number: true },
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeVariant()))
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          "page-size": Number.MAX_SAFE_INTEGER,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetch
        }, null, 8, ["columns", "rows", "total", "page-size", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}