
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useQuestTypeDetail } from '@/modules/quest/type/model/detail';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'QuestTypeDetail',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, fetch, actions } = useQuestTypeDetail();

        onMounted(() => {
            fetch();
        });

        return { model, actions, displayTypeList, timeUnits };
    }
});

const displayTypeList = [
    { label: 'Special', value: 1, background: 'success' },
    { label: 'Normal', value: 0, background: 'info' }
];
