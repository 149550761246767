import useVuelidate from '@vuelidate/core';
import { decimal, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';

export function useMixins() {
    const model = reactive({
        id: null,
        name: '',
        price: null,
        packageId: null,
        credit: null,
        isPublished: false,
        isBestseller: false,
        image: null,
        imageFile: null,
        thumbnail: null,
        thumbnailFile: null,
        iapOptions: [],
        iapItems: [],
        canDelete: false,
        canClone: false
    });

    const validations = {
        name: { required },
        price: { required, decimal, minValue: minValue(0) },
        credit: { required, decimal, minValue: minValue(0) },
        isPublished: { required },
        isBestseller: { required },
        imageFile: {},
        thumbnailFile: {}
    };

    const v$ = useVuelidate(validations, model);

    return { model, v$ };
}
