
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { AppImageHolder } from '@/components';
import { IColumn, ITableSetting, IAction, renderActions } from './model';
import { pageSize, formatDate } from '@/utils/helpers';
import { getPagination } from '@/components/table/pagination';
import { useStore } from 'vuex';
import { SCREEN_SIZE_TYPE } from '@/utils/themes';
import * as bootstrap from 'admin-lte/plugins/bootstrap/js/bootstrap.min.js';

export default defineComponent({
    name: 'my-table',
    emits: ['refresh-data', 'row-clicked'],
    components: { AppImageHolder },
    props: {
        title: {
            type: String,
            default: ''
        },
        columns: {
            default: [] as IColumn[]
        },
        rows: {
            default: []
        },
        rowClasses: {
            type: [Array, Function],
            default: [] as string[]
        },
        // Display the number of items on one page
        pageSize: {
            type: Number,
            default: pageSize
        },
        // Total number of transactions
        total: {
            type: Number,
            default: 100
        },
        // Current page number
        page: {
            type: Number,
            default: 1
        },

        isSlotMode: {
            type: Boolean,
            default: false
        },
        actions: {
            default: [] as IAction[]
        }
    },
    watch: {
        pageSize() {
            this.setting.pageSize = this.pageSize;
        }
    },
    setup(props, { emit, slots }) {
        const store = useStore();
        const localTable = ref<HTMLElement | null>(null);
        const defaultPageSize = ref(props.pageSize);
        const isShowGotoPageInput = ref(false);
        const isShowGotoPageButton = computed(
            () =>
                setting.maxPage > 10 &&
                [SCREEN_SIZE_TYPE.LG, SCREEN_SIZE_TYPE.MD, SCREEN_SIZE_TYPE.SM].includes(store.getters['ui/screenSize'])
        );

        const setting: ITableSetting = reactive({
            isSlotMode: props.isSlotMode,
            page: props.page,
            pageSize: defaultPageSize.value,
            maxPage: computed(() => {
                if (props.total <= 0) {
                    return 0;
                }
                let maxPage = Math.floor(props.total / setting.pageSize);
                let mod = props.total % setting.pageSize;
                if (mod > 0) {
                    maxPage++;
                }
                return maxPage;
            }),
            // The starting value of the page number
            offset: computed(() => {
                return (setting.page - 1) * setting.pageSize + 1;
            }),
            // Maximum number of pages0
            limit: computed(() => {
                let limit = setting.page * setting.pageSize;
                return props.total >= limit ? limit : props.total;
            }),
            // Paging array
            paging: computed(() => (setting.maxPage <= 1 ? [] : getPagination(props.total, pageSize, setting.page)))
        });

        const movePage = (page: number) => {
            setting.page = page;
        };

        const prevPage = () => {
            if (setting.page == 1) {
                return false;
            }
            setting.page--;
        };

        const nextPage = () => {
            if (setting.page >= setting.maxPage) {
                return false;
            }
            setting.page++;
        };

        const changePage = (page: number) => {
            emit('refresh-data', page);
        };

        const updateSettingPage = (page: number) => {
            if (page <= 1) {
                setting.page = 1;
            } else if (page >= setting.maxPage) {
                setting.page = setting.maxPage;
            } else {
                setting.page = page;
            }
        };

        // Monitor page switching
        watch(() => setting.page, changePage);
        // Monitor manual page switching
        watch(() => props.page, updateSettingPage);

        onMounted(() => {
            document.querySelectorAll('[data-toggle="tooltip"]').forEach(el => new bootstrap.Tooltip(el));
        });

        return {
            slots,
            localTable,
            setting,
            prevPage,
            movePage,
            nextPage,
            formatDate,
            renderActions,
            isShowGotoPageInput,
            isShowGotoPageButton
        };
    }
});
