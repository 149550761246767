
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppButton, AppTable } from '@/components';
import useAppList from '@/modules/apps/model/list';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'Applications',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, isSuperUser, selectedApplication, actions, routes } = useAppList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Status', display: (row: any) => renderStatus(row) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-users',
                        theme: 'info',
                        tooltip: 'Accounts',
                        isShow: row.id === selectedApplication.value,
                        onclick: () => routes.account()
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, isSuperUser, actions, routes };
    }
});

function renderStatus(row: any) {
    return row.removeTime ? '<span class="badge badge-danger">Deleting</span>' : '';
}
