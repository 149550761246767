import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import useVuelidate from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        levelTimeout: '0',
        groupTimeout: '0',
        findingMatchTimeout: '0',
        canRemove: false
    });

    const validations = {
        levelTimeout: { required, integer, minValue: minValue(1) },
        groupTimeout: { required, integer, minValue: minValue(1) },
        findingMatchTimeout: { required, integer, minValue: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await arenaService.setting.get();
        if (response.code === 200) {
            const result_1 = response.data;
            model.levelTimeout = result_1.levelTimeout.toString();
            model.groupTimeout = result_1.groupTimeout.toString();
            model.findingMatchTimeout = result_1.findingMatchTimeout.toString();
            model.canRemove = result_1.canRemove;
        } else if (response.code === 404) {
            model.levelTimeout = '0';
            model.groupTimeout = '0';
            model.findingMatchTimeout = '0';
            model.canRemove = false;
        } else {
            toast.error(response.data);
            // router.push({ name: 'Home' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await arenaService.setting.update(
                model.levelTimeout,
                model.groupTimeout,
                model.findingMatchTimeout
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.setting.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                            v$.value.$reset();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
