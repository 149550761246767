import { AppButton } from '@/components';
import { Component, markRaw } from 'vue';

export interface ITableSetting {
    isSlotMode: boolean;
    page: number;
    pageSize: number;
    maxPage: number;
    offset: number;
    limit: number;
    paging: Array<number | string>;
}

export interface IColumn {
    label: string;
    field: string;
    display?: (row: any) => string;
    component?: (row: any) => {
        type: Component;
        props?: any;
        message?: string;
    };
    width?: string;
    tooltip?: string;
    headerClasses?: Array<any>;
    headerStyles?: Array<any>;
    columnClasses?: Array<any>;
    columnStyles?: any;
}

export interface IAction {
    props: (row: any) => any; // binding with button's props
    group?: any;
}

export function renderActions(actionList: Array<IAction>, data: any) {
    const result = [] as Array<{ type: Component; props: any }>;

    const groupedActions = actionList.reduce((groups: any, action) => {
        const groupKey = action?.group ?? -1;
        if (!groups[groupKey]) {
            groups[groupKey] = [];
        }
        groups[groupKey].push(action);
        return groups;
    }, {});

    let lastGroup: string = null;
    for (const groupKey in groupedActions) {
        if (Object.prototype.hasOwnProperty.call(groupedActions, groupKey)) {
            const group = groupedActions[groupKey].filter((action: IAction) => action.props(data)?.isShow != false);

            if (group.length) {
                group.forEach((action: IAction) => {
                    result.push({
                        type: markRaw(AppButton),
                        props: {
                            ...action.props(data),
                            size: 'xs',
                            class: lastGroup == groupKey ? 'ml-0' : 'ml-1'
                        }
                    });

                    lastGroup = groupKey;
                });
            }
        }
    }

    return result;
}
