
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppSwitch } from '@/components';
import { AppButton, AppFileUpload, AppImageHolder } from '@/components';
import useAppEdit from '@/modules/apps/model/edit';

export default defineComponent({
    name: 'Applications',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppSwitch, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions, currencyOptions } = useAppEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, currencyOptions };
    }
});
