
import { defineComponent, onMounted } from 'vue';
import { AppDateTimePicker, AppFileUpload, AppImageHolder } from '@/components';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { usePiggybankBoost } from '@/modules/piggybank/model/boost';

export default defineComponent({
    name: 'PiggybankBoost',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions } = usePiggybankBoost();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, renderStatus };
    }
});

const statusList = [
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Started', value: 2, background: 'success' },
    { label: 'Ended', value: 3, background: 'warning' },
    { label: 'Disabled', value: 4, background: 'danger' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status) || { label: 'None', background: 'secondary' };

    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
