
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useQuestTypeAdd } from '@/modules/quest/type/model/add';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'QuestTypeAdd',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, v$, create } = useQuestTypeAdd();

        return { v$, model, create, displayTypeList, timeUnits };
    }
});

const displayTypeList = [
    { label: 'Special', value: 1, background: 'success' },
    { label: 'Normal', value: 0, background: 'info' }
];
