import http from '@/utils/axios';

export const chatService = {
    account: {
        getStatus(accountId: any) {
            return http.doGet('admin/chat/account/' + accountId);
        },

        ban(accountId: any) {
            return http.doPut('admin/chat/account/' + accountId + '/ban');
        },

        unban(accountId: any) {
            return http.doDelete('admin/chat/account/' + accountId + '/ban');
        },

        mute(accountId: any) {
            return http.doPut('admin/chat/account/' + accountId + '/mute');
        },

        unmute(accountId: any) {
            return http.doDelete('admin/chat/account/' + accountId + '/mute');
        }
    },

    setting: {
        get() {
            return http.doGet('admin/chat/setting');
        },

        remove() {
            return http.doDelete('admin/chat/setting');
        },

        update(banTimeout: any, minGameWin: any) {
            return http.doPost('admin/chat/setting', {
                banTimeout: banTimeout,
                minGameWin: minGameWin
            });
        }
    },

    sentence: {
        list() {
            return http.doGet('admin/chat/sentence');
        },

        get(itemId: string) {
            return http.doGet('admin/chat/sentence/' + itemId);
        },

        create(name: any, position: any) {
            return http.doPost('admin/chat/sentence', {
                name: name,
                position: position
            });
        },

        update(itemId: string, name: any, position: any) {
            return http.doPut('admin/chat/sentence/' + itemId, {
                name: name,
                position: position
            });
        },

        remove(itemId: string) {
            return http.doDelete('admin/chat/sentence/' + itemId);
        },

        getText(itemId: string, locale: string) {
            return http.doGet('admin/chat/sentence/' + itemId + '/text/' + locale);
        },

        updateText(itemId: string, locale: string, content: any) {
            return http.doPut('admin/chat/sentence/' + itemId + '/text/' + locale, {
                content: content
            });
        },

        removeText(itemId: string, locale: string) {
            return http.doDelete('admin/chat/sentence/' + itemId + '/text/' + locale);
        }
    }
};
