
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppSelect } from '@/components';
import { AppFileUpload, AppImageHolder, AppSwitch, AppButton } from '@/components';
import { useShopItemEdit } from '@/modules/shop/model/edit';

export default defineComponent({
    name: 'ShopEdit',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppFileUpload, AppImageHolder, AppSwitch, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, actions, routes } = useShopItemEdit();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { v$, model, routes, actions };
    }
});
