import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarCategoryEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const parentCategory = ref(null);

    const model = reactive({
        id: route.params.categoryId,
        parentId: null,
        name: '',
        totalItem: null,
        position: 0,
        gender: 0,
        posX: 0,
        posY: 0,
        image: null,
        imageStatus: null,
        imageFile: null,
        isPublished: false,
        isRequired: false,
        isBody: false,
        canDelete: false
    });

    const validations = {
        name: { required },
        gender: { required },
        position: { required, min: minValue(0) },
        isPublished: { required },
        isRequired: { required },
        isBody: { required },
        imageFile: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        let response = await avatarService.category.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);

            if (model.parentId > 0) {
                response = await avatarService.category.get(model.parentId);
                if (response.code === 200) {
                    parentCategory.value = response.data;
                } else {
                    toast.error(response.data);
                    router.push({ name: 'SysAvatarCategoryList' });
                }
            }
        } else {
            toast.error(response.data);
            router.push({ name: 'SysAvatarCategoryList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await avatarService.category.update(
                model.id,
                model.name,
                model.position,
                model.gender,
                model.isPublished,
                model.isRequired,
                model.posX,
                model.posY,
                model.isBody,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({
                    name: 'SysAvatarCategoryList',
                    query: model.parentId > 0 ? { parentId: model.parentId } : {}
                });
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this category?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.category.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({
                                name: 'SysAvatarCategoryList',
                                query: model.parentId > 0 ? { parentId: model.parentId } : {}
                            });
                        }
                    }
                }
            });
        }
    };

    const routes = {
        items: () => router.push({ name: 'SysAvatarItemList', query: { categoryId: model.id } }),
        sub: () => router.push({ name: 'SysAvatarCategoryList', query: { parentId: model.id } })
    };

    return { model, parentCategory, v$, fetch, actions, routes, genderList };
}

const genderList = [
    { label: 'Female', value: 0 },
    { label: 'Male', value: 1 }
];
