import { Role } from '@/interfaces/state';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { useFetchList } from '@/utils/useFetch';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export default function () {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const isSuperUser = computed((): boolean => store.getters['auth/roles'].includes(Role.SUPER_USER));
    const selectedApplication = computed(() => store.getters['application/appId']);
    const { itemList, total, fetchList } = useFetchList(applicationService.app.list, null);

    const actions = {
        remove: (appId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.app.remove(appId);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ApplicationAdd' }),
        edit: (id: any) => router.push({ name: 'ApplicationEdit', params: { id: id } }),
        account: () => router.push({ name: 'AppAccountList' })
    };

    return { itemList, total, fetchList, isSuperUser, selectedApplication, actions, routes };
}
