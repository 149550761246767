
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { useQuestBoostTranslation } from '@/modules/quest/boost/model/translate';

export default defineComponent({
    name: 'QuestBoostTranslation',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, beforeFetch, actions, localeSupportedOptions } = useQuestBoostTranslation();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, fetch, actions, localeSupportedOptions };
    }
});
