import { useVDialog } from '@/plugins/dialog/v-dialog';
import { progressService } from '@/services/progress';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useProgressLevelList() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const progressId = route.params.progressId;

    const { itemList, total, fetchList } = useFetchList(progressService.level.list, {
        options: ref(progressId)
    });

    const actions = {
        remove: (level: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await progressService.level.remove(progressId, level);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ProgressLevelAdd', params: { progressId: progressId } }),
        edit: (level: any) =>
            router.push({ name: 'ProgressLevelEdit', params: { progressId: progressId, level: level } }),
        translate: (level: any) =>
            router.push({ name: 'ProgressLevelTranslate', params: { progressId: progressId, level: level } })
    };

    return { itemList, total, routes, fetchList, actions };
}
