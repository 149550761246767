
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useLeagueRewardAdd } from '@/modules/league/tier/reward/model/add';

export default defineComponent({
    name: 'LeagueRewardAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, beforeFetch, create } = useLeagueRewardAdd();

        onMounted(() => beforeFetch());

        return { v$, model, beforeFetch, create };
    }
});
