
import { defineComponent } from 'vue';
import { AppFormRow, AppFileUpload, AppButton, AppCard } from '@/components';
import { useTransactionExport } from '@/modules/transaction/text/model/export';

export default defineComponent({
    name: 'TransactionExport',
    components: { AppFormRow, AppFileUpload, AppButton, AppCard },
    setup() {
        const { model, exportXml, importXml } = useTransactionExport();

        return { model, exportXml, importXml };
    }
});
