
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppButton } from '@/components';
import { useGameVariantAdd } from '@/modules/game/variant/model/add';

export default defineComponent({
    name: 'GameVariantAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppButton },
    setup() {
        const { model, v$, create } = useGameVariantAdd();

        return { model, v$, create };
    }
});
