import http from '@/utils/axios';

export const googleService = {
    linkedApp: {
        get(storeId: any) {
            return http.doGet('/admin/google/linkedapp/' + storeId);
        },

        remove(storeId: any) {
            return http.doDelete('/admin/google/linkedapp/' + storeId);
        },

        update(storeId: number, clientId: string, clientSecret: string, redirectUri: string) {
            return http.doPut('/admin/google/linkedapp/' + storeId, {
                clientId: clientId,
                clientSecret: clientSecret,
                redirectUri: redirectUri
            });
        }
    },

    payment: {
        get() {
            return http.doGet('/admin/google/payment');
        },

        remove() {
            return http.doDelete('/admin/google/payment');
        },

        update(
            packageName: string,
            clientId: string,
            clientSecret: string,
            accessToken: string,
            refreshToken: string,
            prefixSchema: string
        ) {
            return http.doPut('/admin/google/payment', {
                packageName: packageName,
                clientId: clientId,
                clientSecret: clientSecret,
                accessToken: accessToken,
                refreshToken: refreshToken,
                prefixSchema: prefixSchema
            });
        }
    }
};
