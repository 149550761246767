import { avatarService } from '@/services/avatar';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImport() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        file: null
    });

    const importFile = async () => {
        const response = await avatarService.archive(model.file);
        if (response.code === 200) {
            toast.success('Import successfully');
            router.go(0);
        }
    };

    return { model, importFile };
}
