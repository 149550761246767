
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker } from '@/components';
import { useQuestItemTime } from '@/modules/quest/item/model/time';

export default defineComponent({
    name: 'QuestItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, actions } = useQuestItemTime();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions };
    }
});
