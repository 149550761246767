
import { defineComponent, onMounted, reactive, toRef } from 'vue';
import { AppCard, AppButton, AppTable } from '@/components';
import { useDuplicateCycleDeck } from '@/modules/duplicate/cycle/item/model/deck';

export default defineComponent({
    name: 'DuplicateDecks',
    components: { AppButton, AppTable, AppCard },
    setup() {
        const { itemList, fetch, routes } = useDuplicateCycleDeck();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            columns: [
                { label: 'GameID', field: 'gameId' },
                { label: 'Round', field: 'round' },
                { label: 'Decks', display: (row: any) => row.decks.join('<br>') }
            ] as Array<any>
        });

        onMounted(() => {
            fetch();
        });

        return { table, fetch, routes };
    }
});
