import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { rfmService } from '@/services/rfm';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionItemDetail() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        status: null,
        displayType: 0,
        fromTime: null,
        toTime: null,
        expectedPackage: 0,
        prototypeId: null,
        personas: [],
        canEnable: false,
        canDisable: false,
        canDelete: false
    });

    const fetch = async (id: any) => {
        let personaList: [];

        const personaResponse = await rfmService().persona.list();
        if (personaResponse.code === 200) {
            personaList = personaResponse.data;
        }

        const response = await promotionService.item.get(id);
        if (response.code === 200) {
            const result = response.data;
            model.id = result.id;
            model.name = result.name;
            model.status = result.status;
            model.displayType = result.displayType;
            model.fromTime = new Date(result.fromTime);
            model.toTime = new Date(result.toTime);
            model.expectedPackage = result.expectedPackage;
            model.prototypeId = result.usedPrototypeId;
            model.canEnable = result.canEnable;
            model.canDisable = result.canDisable;
            model.canDelete = result.canDelete;
            model.personas = result.personas;
            model.personas.forEach((item: any) => {
                personaList.forEach((per: any) => {
                    if (per.id === item.rfmPersonaId) {
                        item.rfmPersonaName = per.name;
                    }
                });
            });
        } else {
            toast.error('Not found');
            router.push({ name: 'PromotionItemList' });
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'PromotionItemList' });
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetch(model.id);
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetch(model.id);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        edit: () => router.push({ name: 'PromotionItemEdit', params: { id: model.id } }),
        translate: () => router.push({ name: 'PromotionItemTranslate', params: { id: model.id } })
    };

    return { model, fetch, routes, actions };
}
