
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton, AppDateTimePicker } from '@/components';
import { useVersionAdd } from '@/modules/version/model/add';

export default defineComponent({
    name: 'VersionAdd',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton, AppDateTimePicker },
    setup() {
        const { model, v$, create } = useVersionAdd();

        return { v$, model, storeOptions, create };
    }
});

const storeOptions = [
    { value: 0, label: 'Apple' },
    { value: 1, label: 'Google' }
];
