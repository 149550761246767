import { accountService } from '@/services/account';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useAccountAdd() {
    const toast = useToast();

    const model = reactive({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        repassword: ''
    });

    const validations = {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        password: { required },
        repassword: {
            required,
            validValue: helpers.withMessage('Passwords do not match', (value: any) => value === model.password)
        }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await accountService.create(model.firstname, model.lastname, model.email, model.password);
            if (response.code === 200) {
                toast.success('Create successfully');
                // refresh model
                v$.value.$reset();
                model.firstname = '';
                model.lastname = '';
                model.email = '';
                model.password = '';
                model.repassword = '';
            }
        }
    };

    return { model, v$, fetch, actions };
}
