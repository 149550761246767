import { useIAPList } from '@/modules/iap/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { shopService } from '@/services/shop';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useShopItemEdit() {
    const router = useRouter();
    const toast = useToast();
    const dialog = useVDialog();

    const { model, v$ } = useMixins();

    const { itemList: iapList, fetchList } = useIAPList();

    const fetch = async (id: any) => {
        await fetchList();
        model.iapItems = iapList.value;
        model.iapOptions = iapList.value.map(item => ({ value: item.id, label: item.name }));

        const response = await shopService().item.get(id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data || 'failed');
            router.push({ name: 'ShopItemList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await shopService().item.update(
                model.id,
                model.name,
                model.price,
                model.credit,
                model.isPublished,
                model.packageId,
                model.isBestseller,
                model.imageFile,
                model.thumbnailFile
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({ name: 'ShopItemList' });
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await shopService().item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        selectIAP: (iapId: any) => {
            model.iapItems.forEach(item => {
                if (item.id == iapId) {
                    model.packageId = item.packageId;
                    model.price = item.price;
                }
            });
        }
    };

    const routes = {
        clone: () => router.push({ name: 'ShopItemClone', params: { id: model.id } }),
        translate: () => router.push({ name: 'ShopItemTranslate', params: { id: model.id } })
    };

    return { model, v$, routes, fetch, actions };
}
