
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppSelect } from '@/components';
import { AppFileUpload, AppImageHolder, AppSwitch, AppButton } from '@/components';
import { useShopItemAdd } from '@/modules/shop/model/add';

export default defineComponent({
    name: 'ShopAdd',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppFileUpload, AppImageHolder, AppSwitch, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, actions } = useShopItemAdd();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { v$, model, actions };
    }
});
