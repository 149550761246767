
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton, AppFormRow, AppSelect } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useQuestItemList } from '@/modules/quest/item/model/list';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'QuestItemList',
    components: { AppTable, AppCard, AppButton, AppFormRow, AppSelect },
    setup() {
        const { itemList, total, fetchList, beforeFetch, typeId, typeList, routes, actions } = useQuestItemList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'AvgGame', display: (row: any) => renderValueRange(row.fromAvgGame, row.toAvgGame) },
                { label: 'Bet', display: (row: any) => renderValueRange(row.fromGameBet, row.toGameBet) },
                { label: 'Game Type', display: (row: any) => renderGameType(row.gameType) },
                { label: 'Game', field: 'game' },
                { label: 'Reward', field: 'reward' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-clock',
                        theme: 'info',
                        tooltip: 'Edit time',
                        onclick: () => routes.editTime(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'info',
                        tooltip: 'Enable next',
                        isShow: row.canEnableNext,
                        onclick: () => actions.enableNext(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'info',
                        tooltip: 'Disable next',
                        isShow: row.canDisableNext,
                        onclick: () => actions.disableNext(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchList();
        });

        return { table, fetchList, routes, typeId, typeList };
    }
});

function renderValueRange(fromValue: any, toValue: any) {
    const from = fromValue >= 0 ? `[${fromValue}, ` : '(∞, ';
    const to = toValue >= 0 ? `${toValue}]` : '∞)';

    return from + to;
}

function renderGameType(status: any) {
    const type = gameTypeList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}

const gameTypeList = [
    { label: 'Win', value: 0, background: 'info' },
    { label: 'Complete', value: 1, background: 'success' },
    { label: 'ArenaWin', value: 2, background: 'info' },
    { label: 'ArenaComplete', value: 3, background: 'success' },
    { label: 'SlotWin', value: 4, background: 'info' },
    { label: 'SlotSpin', value: 5, background: 'success' },
    { label: 'ScratchCardComplete', value: 6, background: 'success' },
    { label: 'ScratchCardWin', value: 7, background: 'info' },
    { label: 'DuplicateGameComplete', value: 8, background: 'success' }
];
