
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import AppFooter from './footer/footer.vue';
import AppHeader from './header/header.vue';
import { useMain } from './main';
import AppMenuSidebar from './menu-sidebar/menu-sidebar.vue';

export default defineComponent({
    name: 'AppMain',
    components: { AppHeader, AppMenuSidebar, AppFooter },
    setup() {
        const {
            darkModeSelected,
            menuSidebarCollapsed,
            controlSidebarCollapsed,
            screenSize,
            isLoading,
            fetchProfile,
            onToggleMenuSidebar
        } = useMain();

        const appElement = ref<HTMLElement>(null);

        onMounted(async () => {
            appElement.value = document.getElementById('app') as HTMLElement;
            appElement.value.classList.add('sidebar-mini');
            appElement.value.classList.add('layout-fixed');
            document.body.classList.add('layout-fixed');
            document.body.classList.add('layout-navbar-fixed');

            await fetchProfile();
        });

        onUnmounted(() => {
            appElement.value.classList.remove('sidebar-mini');
            appElement.value.classList.remove('layout-fixed');
        });

        const watchLayoutChanges = computed(() => {
            if (!appElement.value) {
                return null;
            }

            appElement.value.classList.remove('dark-mode');
            appElement.value.classList.remove('sidebar-closed');
            appElement.value.classList.remove('sidebar-collapse');
            appElement.value.classList.remove('sidebar-open');
            appElement.value.classList.remove('control-sidebar-slide-open');

            if (darkModeSelected) {
                appElement.value.classList.add('dark-mode');
            }

            if (!controlSidebarCollapsed.value) {
                appElement.value.classList.add('control-sidebar-slide-open');
            }

            if (menuSidebarCollapsed.value && screenSize.value === 'lg') {
                appElement.value.classList.add('sidebar-collapse');
            } else if (menuSidebarCollapsed.value && screenSize.value === 'xs') {
                appElement.value.classList.add('sidebar-open');
            } else if (!menuSidebarCollapsed.value && screenSize.value !== 'lg') {
                appElement.value.classList.add('sidebar-closed');
                appElement.value.classList.add('sidebar-collapse');
            }
            return appElement.value.classList.value;
        });

        watch(watchLayoutChanges, () => {});

        return { screenSize, isLoading, onToggleMenuSidebar };
    }
});
