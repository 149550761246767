import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar_breadcrumb = _resolveComponent("app-avatar-breadcrumb")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_avatar_breadcrumb, {
      class: "col-lg-8 mx-auto",
      "category-id": _ctx.model.categoryId
    }, null, 8, ["category-id"]),
    _createVNode(_component_app_card, {
      class: "col-lg-8 mx-auto",
      label: "Update Avatar Item"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          (_ctx.category)
            ? (_openBlock(), _createBlock(_component_app_form_row, {
                key: 0,
                label: "Category"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.category.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category.name) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_app_form_row, { label: "Total sale" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                modelValue: _ctx.model.limitSale,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.limitSale) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.name) = $event)),
                validations: _ctx.v$.name
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Price" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                type: "number",
                modelValue: _ctx.model.price,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.price) = $event)),
                modelModifiers: { number: true },
                validations: _ctx.v$.price
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Limit sale" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_input, {
                type: "number",
                modelValue: _ctx.model.limitSale,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.limitSale) = $event)),
                modelModifiers: { number: true },
                validations: _ctx.v$.limitSale
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is published" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isPublished,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.isPublished) = $event)),
                validations: _ctx.v$.isPublished
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is new" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isNew,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.isNew) = $event)),
                validations: _ctx.v$.isNew
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is default" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isDefault,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.isDefault) = $event)),
                validations: _ctx.v$.isDefault
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is special" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isSpecial,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.isSpecial) = $event)),
                validations: _ctx.v$.isSpecial
              }, null, 8, ["modelValue", "validations"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_button, {
                name: "Update",
                theme: "info",
                icon: "fa-save",
                disabled: !_ctx.v$.$anyDirty,
                onClick: _withModifiers(_ctx.actions.update, ["prevent"])
              }, null, 8, ["disabled", "onClick"]),
              _createVNode(_component_app_button, {
                name: "View images",
                theme: "info",
                icon: "fa-image",
                onClick: _withModifiers(_ctx.routes.image, ["prevent"])
              }, null, 8, ["onClick"]),
              (_ctx.model.canDelete)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 0,
                    name: "Delete",
                    theme: "danger",
                    icon: "fa-trash-alt",
                    onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}