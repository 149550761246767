
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder } from '@/components';
import { usePiggybankAdd } from '@/modules/piggybank/model/add';

export default defineComponent({
    name: 'PiggybankAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = usePiggybankAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, iapOptions };
    }
});
