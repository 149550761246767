
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker } from '@/components';
import useItemSettingEdit from '@/modules/item/app/setting/model/edit';

export default defineComponent({
    name: 'ItemSettingEdit',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker },
    setup() {
        const route = useRoute();
        const { model, v$, timeUnits, fetch, update } = useItemSettingEdit();

        onMounted(() => {
            fetch(route.params.id);
        });

        return { v$, model, timeUnits, update };
    }
});
