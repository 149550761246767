
import { defineComponent, onBeforeMount } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppSwitch } from '@/components';
import { AppButton, AppFileUpload, AppImageHolder } from '@/components';
import useAppAdd from '@/modules/apps/model/add';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'Applications',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppSwitch, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const toast = useToast();
        const router = useRouter();
        const { model, v$, currencyOptions, create, isSuperUser } = useAppAdd();

        onBeforeMount(() => {
            if (!isSuperUser.value) {
                toast.error('Permission denied!');
                router.push({ name: 'ApplicationList' });
            }
        });

        return { v$, model, currencyOptions, create };
    }
});
