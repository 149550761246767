import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function usePromotionCode() {
    const dialog = useVDialog();
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const promotionCodeStore = {
        getPage: () => store.getters['promotionCode/page'],
        setPage: (page: number) => store.dispatch('promotionCode/changePage', page),
        getFeature: () => store.getters['promotionCode/feature'],
        setFeature: (feature: number) => store.dispatch('promotionCode/changeFeature', feature)
    };

    const featureId = ref(promotionCodeStore.getFeature());
    const { itemList, total, currentPage, changePage } = useFetchList(promotionService.code.list, {
        pagingStore: promotionCodeStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        routerByFeature: () => {
            const data = options.features.find(feature => feature.value == featureId.value);
            if (data) {
                router.push({ name: data.routeName });
            }
        }
    };

    const routes = {
        add: () => router.push({ name: 'PromotionCodeAdd', query: { feature: 10, data: 0 } }),
        detail: (id: any) => router.push({ name: 'PromotionCodeDetail', params: { id: id } })
    };

    return { featureId, itemList, total, currentPage, routes, actions, options, changePage };
}

const options = {
    features: [
        // { label: 'AvatarBundle', value: 1, background: 'badge-info', routeName: 'SysAvatarBundleList' },
        { label: 'ArenaTicket', value: 2, background: 'badge-success', routeName: 'ArenaTicketList' },
        // { label: 'QuestBoost', value: 3, background: 'badge-info', routeName: 'QuestBoostList' },
        { label: 'CreditTicket', value: 4, background: 'badge-success', routeName: 'CreditTicketList' },
        { label: 'ScratchTicket', value: 5, background: 'badge-info', routeName: 'ScratchTicketList' },
        { label: 'AddChips', value: 10, background: 'badge-primary', routeName: null }
    ],
    arenas: [
        { label: 'ArenaStandard', value: 1, background: 'badge-success' },
        { label: 'ArenaPremium', value: 2, background: 'badge-info' }
    ],
    scratchTickets: [
        { label: 'ScratchSilver', value: 1, background: 'badge-success' },
        { label: 'ScratchGolden', value: 2, background: 'badge-info' }
    ],
    statusList: [
        { value: 0, label: 'None', background: 'badge-secondary' },
        { value: 1, label: 'Enabled', background: 'badge-info' },
        { value: 2, label: 'Started', background: 'badge-success' },
        { value: 3, label: 'Ended', background: 'badge-warning' },
        { value: 4, label: 'Disabled', background: 'badge-warning' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        return `<span class='badge ${type?.background ?? ''}'>${type?.label ?? ''}</span>`;
    }
};
