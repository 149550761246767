import { accountService } from '@/services/account';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useProfile() {
    const toast = useToast();

    const model = reactive({
        firstname: '',
        lastname: '',
        email: '',
        oldPassword: '',
        password: '',
        repassword: ''
    });

    const validations = {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        oldPassword: { required },
        password: { required },
        repassword: {
            required,
            validValue: helpers.withMessage('Passwords do not match', (value: any) => value === model.password)
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await accountService.get();
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await accountService.update(
                model.firstname,
                model.lastname,
                model.email,
                model.password,
                model.oldPassword
            );

            if (response.code === 200) {
                toast.success('Update Successfully');
                fetch();
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, fetch, actions };
}
