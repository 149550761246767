import http from '@/utils/axios';

export const creditService = {
    transaction: {
        list(paging: any, accountId: any) {
            return http.doGet('admin/credit/transaction/' + accountId, {
                ...paging
            });
        },

        listPayment(paging: any, accountId: any) {
            return http.doGet('admin/credit/transaction/' + accountId + '/payment', {
                ...paging
            });
        }
    },

    getAvgPayment(accountId: any) {
        return http.doGet('admin/credit/' + accountId + '/payment/average');
    },

    ticket: {
        create(name: any, amount: any) {
            return http.doPost('admin/credit/ticket', {
                name: name,
                amount: amount
            });
        },

        update(itemId: string, name: any, amount: any) {
            return http.doPut('admin/credit/ticket/' + itemId, {
                name: name,
                amount: amount
            });
        },

        get(itemId: string) {
            return http.doGet('admin/credit/ticket/' + itemId);
        },

        list() {
            return http.doGet('admin/credit/ticket');
        },

        remove(itemId: string) {
            return http.doDelete('admin/credit/ticket/' + itemId);
        }
    }
};
