import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Function",
      classes: "mx-auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_app_button, {
          name: "Add new ticket",
          icon: "fa-plus",
          theme: "info",
          onClick: _ctx.routes.add
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          "page-size": Number.MAX_SAFE_INTEGER,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetchList
        }, null, 8, ["columns", "rows", "total", "page-size", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}