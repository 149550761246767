import http from '@/utils/axios';

export const accountService = {
    create(firstname: any, lastname: any, email: any, password: any) {
        return http.doPost('admin/account', {
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password
        });
    },

    update(firstname: any, lastname: any, email: any, password: any, oldPassword: any) {
        return http.doPut('admin/account', {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            oldPassword: oldPassword
        });
    },

    get() {
        return http.doGet('admin/account');
    }
};
