import http from '@/utils/axios';

export const rubyService = {
    transaction: {
        list(paging: any, accountId: any) {
            return http.doGet('admin/ruby/transaction/' + accountId, {
                ...paging
            });
        }
    }
};
