import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueRewardList() {
    const dialog = useVDialog();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const options = ref({
        itemId: route.params.itemId,
        tier: route.params.tier
    });

    const tierItem = ref(null);

    const { itemList, total, fetchList } = useFetchList(leagueService.reward.list, {
        options: options
    });

    const beforeFetch = async () => {
        const response = await leagueService.tier.get(options.value.itemId, options.value.tier);
        if (response.code === 200) {
            tierItem.value = response.data;
        } else {
            router.push({ name: 'LeagueList' });
        }
    };

    const actions = {
        remove: (pos: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.reward.remove(
                            options.value.itemId,
                            options.value.tier,
                            pos
                        );

                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () =>
            router.push({
                name: 'LeagueRewardAdd',
                params: { itemId: options.value.itemId, tier: options.value.tier }
            }),
        edit: (pos: any) =>
            router.push({
                name: 'LeagueRewardEdit',
                params: { itemId: options.value.itemId, tier: options.value.tier, position: pos }
            })
    };

    return { itemList, total, tierItem, fetchList, beforeFetch, actions, routes };
}
