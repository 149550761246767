
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import AppDropdownMenu from './DropdownMenu.vue';

export default defineComponent({
    name: 'AppDropdown',
    components: { AppDropdownMenu },
    props: {
        size: { type: String, default: 'md' }
    },
    setup() {
        const dropdownElement = ref<HTMLElement>(null);
        const isOpen = ref(false);

        function toggleDropdown() {
            isOpen.value = !isOpen.value;
        }

        onMounted(() => {
            dropdownElement.value.addEventListener('click', toggleDropdown);
        });

        onUnmounted(() => {
            dropdownElement.value?.removeEventListener('click', toggleDropdown);
        });

        return { isOpen, dropdownElement };
    }
});
