import { useVDialog } from '@/plugins/dialog/v-dialog';
import { stripeService } from '@/services/stripe';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useStripe() {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        secretKey: '',
        webhookSecretKey: ''
    });

    const validations = {
        secretKey: { required },
        webhookSecretKey: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await stripeService().get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.secretKey = result.secretKey;
            model.webhookSecretKey = result.webhookSecretKey;
        } else if (response.code === 404) {
            model.isNew = true;
            model.secretKey = '';
            model.webhookSecretKey = '';
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await stripeService().update(model.secretKey, model.webhookSecretKey);
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await stripeService().remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            v$.value.$reset();
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { v$, model, fetch, actions };
}
