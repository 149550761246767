
import { defineComponent } from 'vue';
import { version } from '@/../package.json';

export default defineComponent({
    name: 'AppFooter',
    setup() {
        const currentYear = new Date().getUTCFullYear();

        return { version, currentYear };
    }
});
