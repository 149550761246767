
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { useSubscriptionList } from '@/modules/subscription/item/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderTimeUnit } from '@/utils/helpers';

export default defineComponent({
    name: 'SubscriptionList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, currentPage, changePage, routes, actions } = useSubscriptionList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'Id', field: 'id', headerStyles: [] },
                { label: 'Name', field: 'name' },
                { label: 'Package Id', field: 'packageId' },
                { label: 'Price', field: 'price' },
                { label: 'Cycle Chip', field: 'cycleChip' },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Time Duration', field: 'timeDuration' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        onclick: () => routes.detail(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        onclick: () => routes.clone(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await changePage(currentPage.value);
        });

        return { table, changePage, routes };
    }
});
