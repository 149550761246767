import http from '@/utils/axios';

const lucky = {
    get() {
        return http.doGet('/admin/gift/lucky');
    },
    update(
        amount: any,
        receiveLimit: any,
        gamePercent: any,
        slotPercent: any,
        questPercent: any,
        timeUnit: any,
        timeDuration: any,
        startTime: any,
        endTime: any
    ) {
        return http.doPut('/admin/gift/lucky', {
            amount: amount,
            receiveLimit: receiveLimit,
            gamePercent: gamePercent,
            slotPercent: slotPercent,
            questPercent: questPercent,
            timeUnit: timeUnit,
            timeDuration: timeDuration,
            startTime: startTime,
            endTime: endTime
        });
    },
    remove() {
        return http.doDelete('/admin/gift/lucky');
    }
};

const setting = {
    get() {
        return http.doGet('/admin/gift/setting');
    },
    update(sendLimit: any, receiveLimit: any, amount: any) {
        return http.doPut('admin/gift/setting', {
            sendLimit: sendLimit,
            receiveLimit: receiveLimit,
            amount: amount
        });
    }
};

export function giftService() {
    return {
        lucky,
        setting
    };
}
