
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppCard, AppButton } from '@/components';
import { useMixins } from '@/modules/game/bet/model/mixins';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'Bet Detail',
    components: { AppCard, AppButton },
    setup() {
        const route = useRoute();
        const { model, routes, options, fetch, beforeFetch, remove } = useMixins();

        onMounted(async () => {
            await beforeFetch();
            await fetch(route.params.id);
        });

        return { model, routes, options, formatDate, remove };
    }
});
