
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard } from '@/components';
import { useAccountTransaction } from '@/modules/account/app/model/transaction';
import { formatDate } from '@/utils/helpers';
import { IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'AppAccountTransactionList',
    components: { AppTable, AppCard },
    setup() {
        const { queryParams, itemList, total, currentPage, fetchList } = useAccountTransaction();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Type', field: 'type' },
                { label: 'Title', field: 'title' },
                { label: 'Amount', field: 'amount' },
                {
                    label: queryParams.typeId === 2 ? 'Ruby' : 'Credit',
                    field: queryParams.typeId === 2 ? 'ruby' : 'credit'
                },
                { label: 'Store', field: 'store' },
                { label: 'Date Time', display: row => formatDate(row.createTime) },
                { label: 'Detail', field: 'detail' }
            ] as Array<IColumn>
        });

        const title = () => {
            switch (queryParams.typeId) {
                case 0:
                    return `Credit Transactions (AccountID: ${queryParams.accountId})`;
                case 1:
                    return `Payment Transactions (AccountID: ${queryParams.accountId})`;
                case 2:
                    return `Ruby Transactions (AccountID: ${queryParams.accountId})`;
                default:
                    return '';
            }
        };

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { itemList, table, fetchList, title };
    }
});
