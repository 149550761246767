
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppCard, AppSimpleTable, AppButton } from '@/components';
import { usePromotionItemDetail } from '@/modules/promotion/item/model/detail';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionItemDetail',
    components: { AppCard, AppSimpleTable, AppButton },
    setup() {
        const route = useRoute();
        const { model, routes, fetch, actions } = usePromotionItemDetail();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { model, routes, displayTypeOptions, renderStatus, formatDate, actions };
    }
});

const displayTypeOptions = [
    { value: 0, label: 'Shop' },
    { value: 1, label: 'Popup' },
    { value: 2, label: 'Event' },
    { value: 3, label: 'Progressive' }
];

const renderStatus = (value: any) => {
    switch (value) {
        case 0:
            return `<span class='badge badge-info'>None</span>`;
        case 1:
            return `<span class='badge badge-success'>Enable</span>`;
        case 2:
            return `<span class='badge badge-success'>Started</span>`;
        case 3:
            return `<span class='badge badge-warning'>Stopped</span>`;
        case 4:
            return `<span class='badge badge-warning'>Disabled</span>`;
    }
};
