import { useLocale } from '@/modules/locale/model/locale';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { translationService } from '@/services/translation';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { reactive, toRef, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useTranslationList() {
    const store = useStore();
    const translationStore = {
        getPage: () => store.getters['translation/page'],
        setPage: (page: number) => store.dispatch('translation/changePage', page),
        getFilter: () => store.getters['translation/filter'],
        setFilter: () => store.dispatch('translation/changeFilter', model.filter)
    };

    const toast = useToast();
    const { localeListSupported, getListSupported } = useLocale();

    const model = reactive({
        filter: { locale: null, filterType: 0, criteria: null },
        locales: [],
        types: [
            { label: 'Name', value: 0 },
            { label: 'Value', value: 1 }
        ]
    });

    const { itemList, total, currentPage, changePage } = useFetchList(translationService.list, {
        pagingStore: translationStore,
        options: toRef(model, 'filter'),
        onSuccess: () => translationStore.setFilter()
    });

    const validations = {
        filter: {
            criteria: { minLength: minLength(3) }
        }
    };

    const v$ = useVuelidate(validations, model);

    const beforeFetch = async () => {
        // load filter from store
        model.filter = translationStore.getFilter();

        await getListSupported();
        model.locales = localeListSupported.value.map(l => ({ label: l.language, value: l.id }));

        if (!model.filter?.locale?.length) {
            model.filter.locale = model.locales?.[0]?.value ?? null;
            translationStore.setFilter();
        }
    };

    const dialog = useVDialog();
    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await translationService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await changePage(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const router = useRouter();
    const routes = {
        add: () => router.push({ name: 'TranslationAdd' }),
        edit: (id: any) => router.push({ name: 'TranslationEdit', params: { id: id } }),
        translate: (id: any, locale: any) =>
            router.push({ name: 'TranslationTranslate', params: { id: id }, query: { locale: locale } })
    };

    watchEffect(() => {
        if (!model.filter?.criteria?.length) {
            model.filter.criteria = null;
        }
    });

    return { model, v$, itemList, total, currentPage, beforeFetch, changePage, actions, routes };
}
