
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarBundleItem } from '@/modules/avatar/bundle/model/item';

export default defineComponent({
    name: 'AvatarBundleItems',
    components: { AppTable, AppCard },
    setup() {
        const { itemList, total, fetchItems, bundle, actions, routes } = useAvatarBundleItem();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Price', field: 'price' },
                { label: 'Is published', display: (row: any) => renderBooleanColumn(row.isPublished) },
                { label: 'Is new', display: (row: any) => renderBooleanColumn(row.isNew) },
                { label: 'Is default', display: (row: any) => renderBooleanColumn(row.isDefault) },
                { label: 'Total sale', field: 'totalSale' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-image',
                        theme: 'info',
                        tooltip: 'View images',
                        onclick: () => routes.image(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-unlink',
                        theme: 'warning',
                        tooltip: 'Unlink',
                        isShow: bundle.value.status === 0,
                        onclick: () => actions.unlink(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchItems();
        });

        return { table, fetchItems, routes };
    }
});

const booleanList = [
    { label: 'Yes', value: true, background: 'success' },
    { label: 'No', value: false, background: 'secondary' }
];

function renderBooleanColumn(value: any) {
    const data = booleanList.find(item => item.value == value);
    return `<span class='badge badge-${data?.background}'>${data?.label ?? ''}</span>`;
}
