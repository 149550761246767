
import { defineComponent, onMounted } from 'vue';
import useChallenge from '@/modules/challenge/model/edit';
import {
    AppFormRow,
    AppInput,
    AppButton,
    AppCard,
    AppSelect,
    AppFileUpload,
    AppImageHolder,
    AppDateTimePicker
} from '@/components';

export default defineComponent({
    name: 'ChallengeEdit',
    components: {
        AppFormRow,
        AppInput,
        AppButton,
        AppSelect,
        AppCard,
        AppFileUpload,
        AppImageHolder,
        AppDateTimePicker
    },
    setup() {
        const { model, v$, appOptions, fetch, update } = useChallenge();

        onMounted(() => {
            fetch();
        });

        return { v$, model, appOptions, update };
    }
});
