
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import { AppFileUpload, AppImageHolder, AppSimpleTable, AppDateTimePicker } from '@/components';
import { usePromotionItemAdd } from '@/modules/promotion/item/model/add';

export default defineComponent({
    name: 'PromotionItemAdd',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppFileUpload,
        AppImageHolder,
        AppSelect,
        AppSimpleTable,
        AppButton,
        AppDateTimePicker
    },
    setup() {
        const { model, v$, fetch, actions } = usePromotionItemAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, displayTypeOptions };
    }
});

const displayTypeOptions = [
    { value: 0, label: 'Shop' },
    { value: 1, label: 'Popup' },
    { value: 2, label: 'Event' },
    { value: 3, label: 'Progressive' }
];
