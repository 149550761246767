
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppDateTimePicker } from '@/components';
import { useVersionEdit } from '@/modules/version/model/edit';

export default defineComponent({
    name: 'VersionEdit',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppDateTimePicker },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, update } = useVersionEdit();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { model, v$, storeOptions, update };
    }
});

const storeOptions = [
    { value: 0, label: 'Apple' },
    { value: 1, label: 'Google' }
];
