
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { useGameVariantTranslate } from '@/modules/game/variant/model/translate';

export default defineComponent({
    name: 'GameVariantTranslate',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { model, localeSupportedOptions, beforeFetch, fetch, actions } = useGameVariantTranslate();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { model, localeSupportedOptions, fetch, actions };
    }
});
