
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppSwitch } from '@/components';
import { useAvatarBackgroundAdd } from '@/modules/avatar/background/model/add';

export default defineComponent({
    name: 'AvatarBackgroundAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppSwitch },
    setup() {
        const { model, v$, create } = useAvatarBackgroundAdd();

        return { v$, model, create };
    }
});
