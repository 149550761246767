import versionService from '@/services/version';
import { validateDateAfter } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { helpers, or, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useVersionEdit() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        store: 0,
        version: '',
        assetLink: '',
        releasedDate: null,
        canEditReleasedDate: false
    });

    const validations = {
        assetLink: { required },
        releasedDate: {
            validDate: helpers.withMessage(
                `Value must be greater than current time`,
                or(() => !model.canEditReleasedDate, validateDateAfter())
            )
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async (id: any) => {
        const response = await versionService.get(id);
        if (response.code === 200) {
            const result = response.data;
            Object.assign(model, response.data);
            model.releasedDate = result.releasedDate ? new Date(result.releasedDate) : null;
        } else {
            toast.error(response.data);
            router.push({ name: 'VersionList' });
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const newReleasedDate = model.releasedDate && model.canEditReleasedDate ? model.releasedDate.getTime() : null;
        const response = await versionService.update(model.id, model.assetLink, newReleasedDate);
        if (response.code === 200) {
            toast.success('Successfully');
            router.push({ name: 'VersionList' });
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, update, fetch };
}
