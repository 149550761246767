import { challengeService } from '@/services/challenge';
import { App } from '@/store/applications';
import { validateDate } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export default function () {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: null,
        toApp: null,
        amount: null,
        link: null,
        startTime: null,
        endTime: null,
        image: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        link: { required },
        amount: { required, numeric, minValue: minValue(1) },
        toApp: { required, numeric, minValue: minValue(1) },
        startTime: { required, validateDate: validateDate() },
        endTime: { required, validateDate: validateDate() }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await challengeService.create(
            model.name,
            model.toApp,
            model.amount,
            model.link,
            model.startTime.getTime(),
            model.endTime.getTime(),
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully', { timeout: 2000 });
            router.push({ name: 'ChallengeList' });
        }
    };

    const appOptions = computed(() => {
        const apps = store.getters['application/apps'] as Array<App>;
        return apps.map(app => ({ label: app.name, value: app.id }));
    });

    return { v$, model, appOptions, validations, create };
}
