import { passwordService } from '@/services/password';
import useVuelidate from '@vuelidate/core';
import { email, helpers, integer, or, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useAccountPassword() {
    const toast = useToast();

    const model = reactive({
        email: '',
        password: '',
        repassword: ''
    });

    const validations = {
        email: { required, validValue: helpers.withMessage('Incorrect format', or(integer, email)) },
        password: { required },
        repassword: {
            required,
            validValue: helpers.withMessage('Passwords do not match', (value: any) => value === model.password)
        }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const isAccountId = /^\d+$/.test(model.email);
            const accountId = isAccountId ? model.email : null;
            const email = isAccountId ? null : model.email;

            const response = await passwordService.update(accountId, email, model.password);
            if (response.code === 200) {
                toast.success('Update successfully');
                // refresh model
                v$.value.$reset();
                model.email = '';
                model.password = '';
                model.repassword = '';
            }
        }
    };

    return { model, v$, fetch, actions };
}
