import { useVDialog } from '@/plugins/dialog/v-dialog';
import { tournamentService } from '@/services/tournament';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTournamentLeaderboardMedal() {
    const dialog = useVDialog();
    const route = useRoute();
    const toast = useToast();

    const leaderboardItemId = route.params.id;
    const model = reactive({
        total: 0,
        list: [],
        linkedItems: [],
        unlinkedItems: []
    });

    const fetch = async () => {
        const response = await tournamentService.medal.getLinked(leaderboardItemId);
        if (response.code === 200) {
            model.list = response.data;
            model.total = model.list.length;
            model.linkedItems = [];
            model.unlinkedItems = [];

            model.list.forEach(medal => {
                if (medal.linkedLeaderboard) {
                    model.linkedItems.push(medal.id);
                } else {
                    model.unlinkedItems.push(medal.id);
                }
            });
        }
    };

    const actions = {
        link: (medalId: any) => {
            dialog.confirm({
                message: 'Are you want to link this medal?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.medal.link(leaderboardItemId, medalId);
                        if (response.code === 200) {
                            toast.success('Success');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unlink: (medalId: any) => {
            dialog.confirm({
                message: 'Are you want to unlink this medal?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.medal.unlink(leaderboardItemId, medalId);
                        if (response.code === 200) {
                            toast.success('Success');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        linkAll: () => {
            dialog.confirm({
                message: 'Are you want to link all medals?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.medal.link(
                            leaderboardItemId,
                            model.unlinkedItems.join(',')
                        );

                        if (response.code === 200) {
                            toast.success('Success');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        unlinkAll: () => {
            dialog.confirm({
                message: 'Are you want to unlink all medals?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await tournamentService.medal.unlink(
                            leaderboardItemId,
                            model.linkedItems.join(',')
                        );

                        if (response.code === 200) {
                            toast.success('Success');
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, fetch, actions };
}
