
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'AppImageHolder',
    props: {
        status: {
            type: Number,
            default: null
        },
        image: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        // append the random to the end to fix the cache problem on updated images with the same src path
        const path = computed(() => process.env.VUE_APP_IMAGE_HOST + props.image + '?n=' + Math.random());

        return { path };
    }
});
