
import { computed, defineComponent } from 'vue';

export default defineComponent({
    name: 'AppSimpletable',
    props: {
        fields: { default: [] },
        items: { default: [] },
        isVisibleHeader: { default: true }
    },
    setup(props) {
        const headers = computed(() => {
            if (!props.isVisibleHeader) {
                return [];
            }

            return props.fields.map((key: string) => {
                let title = key.replace(/([A-Z])/g, ' $1');
                return title.charAt(0).toUpperCase() + title.slice(1);
            });
        });

        return { headers };
    }
});
