import { useRfmFrequencyList } from '@/modules/rfm/frequency/model/list';
import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { useRfmMonetaryList } from '@/modules/rfm/monetary/model/list';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useRfmRecencyList } from '@/modules/rfm/recency/model/list';
import { rfmService } from '@/services/rfm';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useRfmSegmentation() {
    const toast = useToast();
    const store = useStore();

    const model = reactive({
        data: [],
        groupId: store.getters['rfm/groupId'],
        hasChanged: false,
        newSegmentationList: [],
        personasByGroup: []
    });

    watch(
        () => model.groupId,
        value => store.dispatch('rfm/changeGroupId', value)
    );

    const { itemList: recencyList, fetchList: fetchRerencies } = useRfmRecencyList();
    const { itemList: monetaryList, fetchList: fetchMonetaries } = useRfmMonetaryList();
    const { itemList: frequencyList, fetchList: fetchFrequencies } = useRfmFrequencyList();
    const { personaOptions, fetchPersonaOptions } = useRfmPersonaList();
    const { rfmGroupOptions, fetchRfmGroupOptions } = useRfmGroupList();
    const { itemList: oldSegmentationList, fetchList: fetchOldSegmentations } = useFetchList(
        rfmService().segmentation.list,
        { options: toRef(model, 'groupId') }
    );

    const recencyListByGroup: any[] = [];
    const monetaryListByGroup: any[] = [];
    const frequencyListByGroup: any[] = [];

    const beforeFetch = async () => {
        await Promise.all([
            fetchRerencies(),
            fetchMonetaries(),
            fetchFrequencies(),
            fetchPersonaOptions(),
            fetchRfmGroupOptions()
        ]);

        if (!rfmGroupOptions.value.map(group => group.value).includes(model.groupId)) {
            model.groupId = Number(rfmGroupOptions.value?.[0]?.value);
        }

        rfmGroupOptions.value
            .map(group => group.value)
            .forEach(groupId => {
                recencyListByGroup[groupId] = recencyList.value
                    .filter(r => r.groupId === groupId)
                    .sort((r1, r2) => r1.fromDay - r2.fromDay);

                frequencyListByGroup[groupId] = frequencyList.value
                    .filter(f => f.groupId === groupId)
                    .sort((f1, f2) => f1.fromTransaction - f2.fromTransaction);

                monetaryListByGroup[groupId] = monetaryList.value
                    .filter(m => m.groupId === groupId)
                    .sort((m1, m2) => m1.fromPayment - m2.fromPayment);

                for (let i = 0; i < recencyListByGroup[groupId].length; i++) {
                    if (i + 1 < recencyListByGroup[groupId].length) {
                        recencyListByGroup[groupId][i].toDay = recencyListByGroup[groupId][i + 1].fromDay - 1;
                    }
                }

                for (let i = 0; i < frequencyListByGroup[groupId].length; i++) {
                    if (i + 1 < frequencyListByGroup[groupId].length) {
                        frequencyListByGroup[groupId][i].toTransaction =
                            frequencyListByGroup[groupId][i + 1].fromTransaction - 1;
                    }
                }

                for (let i = 0; i < monetaryListByGroup[groupId].length; i++) {
                    if (i + 1 < monetaryListByGroup[groupId].length) {
                        monetaryListByGroup[groupId][i].toPayment =
                            monetaryListByGroup[groupId][i + 1].fromPayment - 0.01; // 1 cent euro
                    }
                }
            });
    };

    const fetch = async () => {
        await fetchOldSegmentations();
        model.newSegmentationList = JSON.parse(JSON.stringify(oldSegmentationList.value));
        model.hasChanged = false;

        // model clone from oldSegmentationList to do not change on oldSegmentationList
        let index = 0;
        const data = JSON.parse(JSON.stringify(oldSegmentationList.value));
        data.forEach(function (item: any) {
            item.index = index++;
            recencyListByGroup[model.groupId].forEach(function (r: any) {
                if (item.recencyId == r.id) {
                    item.fromDay = r.fromDay;
                    item.toDay = r.toDay;
                }
            });

            frequencyListByGroup[model.groupId].forEach(function (f: any) {
                if (item.frequencyId == f.id) {
                    item.fromTransaction = f.fromTransaction;
                    item.toTransaction = f.toTransaction;
                }
            });

            monetaryListByGroup[model.groupId].forEach(function (m: any) {
                if (item.monetaryId == m.id) {
                    item.fromPayment = m.fromPayment;
                    item.toPayment = m.toPayment;
                }
            });
        });

        model.data = data;
        model.personasByGroup = personaOptions.value.filter(p => p.groupId === model.groupId);
    };

    const actions = {
        save: async () => {
            const tmpRecencyList = [];
            const tmpFrequencyList = [];
            const tmpMonetaryList = [];
            const tmpPersonaList = [];

            for (let i = 0; i < model.newSegmentationList.length; i++) {
                tmpRecencyList[i] = model.newSegmentationList[i].recencyId;
                tmpFrequencyList[i] = model.newSegmentationList[i].frequencyId;
                tmpMonetaryList[i] = model.newSegmentationList[i].monetaryId;
                tmpPersonaList[i] = model.newSegmentationList[i].personaId;
            }

            const response = await rfmService().segmentation.update(
                model.groupId,
                tmpRecencyList.toString(),
                tmpFrequencyList.toString(),
                tmpMonetaryList.toString(),
                tmpPersonaList.toString()
            );

            if (response.code === 200) {
                toast.success('Save successfully');
                fetch();
            }
        },
        changePersona: (newItem: any) => {
            model.newSegmentationList.forEach(function (item) {
                if (compareKey(item, newItem)) {
                    item.personaId = newItem.personaId;
                }
            });

            model.hasChanged = true;
        }
    };

    return { beforeFetch, fetch, model, rfmGroupOptions, actions };
}

function compareKey(currentItem: any, newItem: any) {
    return (
        currentItem.recencyId == newItem.recencyId &&
        currentItem.frequencyId == newItem.frequencyId &&
        currentItem.monetaryId == newItem.monetaryId
    );
}
