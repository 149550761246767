
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useTranslationAdd } from '@/modules/translation/model/add';

export default defineComponent({
    name: 'TranslationAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const route = useRoute();

        const { model, v$, fetch, actions } = useTranslationAdd();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { v$, model, actions };
    }
});
