import { promotionService } from '@/services/promotion';
import { validateDate, validateDateAfter } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionCodeAdd() {
    const toast = useToast();
    const router = useRouter();

    const model = reactive({
        id: null,
        feature: null,
        data: null,
        name: '',
        code: '',
        status: null,
        quantity: 0,
        receiveLimit: -1,
        receiveCounter: 0,
        startTime: new Date(),
        endTime: new Date(),
        canEnable: false,
        canDisable: false,
        canDelete: false,
        dataOption: []
    });

    const validations = {
        feature: { required },
        data: { required },
        name: { required },
        code: { required },
        quantity: { required, integer, minValue: minValue(1) },
        receiveLimit: { required, integer, minValue: minValue(-1) },
        startTime: { required, validateDate: validateDate(), validateDateAfter: validateDateAfter() },
        endTime: { required, validateDate: validateDate(), validateDateAfter: validateDateAfter('startTime') }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await promotionService.code.create(
            model.name,
            model.code,
            model.feature,
            model.data,
            model.quantity,
            model.receiveLimit,
            model.startTime.getTime(),
            model.endTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'PromotionCodeList' });
        }
    };

    return { model, v$, options, create };
}

const options = {
    features: [
        // { label: 'AvatarBundle', value: 1, background: 'badge-info', routeName: 'SysAvatarBundleList' },
        { label: 'ArenaTicket', value: 2, background: 'badge-success', routeName: 'ArenaTicketList' },
        // { label: 'QuestBoost', value: 3, background: 'badge-info', routeName: 'QuestBoostList' },
        { label: 'CreditTicket', value: 4, background: 'badge-success', routeName: 'CreditTicketList' },
        { label: 'ScratchTicket', value: 5, background: 'badge-info', routeName: 'ScratchTicketList' },
        { label: 'AddChips', value: 10, background: 'badge-primary', routeName: null }
    ],
    arenas: [
        { label: 'ArenaStandard', value: 1, background: 'badge-success' },
        { label: 'ArenaPremium', value: 2, background: 'badge-info' }
    ],
    scratchTickets: [
        { label: 'ScratchSilver', value: 1, background: 'badge-success' },
        { label: 'ScratchGolden', value: 2, background: 'badge-info' }
    ],
    statusList: [
        { value: 0, label: 'None', background: 'badge-secondary' },
        { value: 1, label: 'Enabled', background: 'badge-info' },
        { value: 2, label: 'Started', background: 'badge-success' },
        { value: 3, label: 'Ended', background: 'badge-warning' },
        { value: 4, label: 'Disabled', background: 'badge-warning' }
    ]
};
