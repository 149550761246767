
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker } from '@/components';
import useItemSettingAdd from '@/modules/item/app/setting/model/add';

export default defineComponent({
    name: 'ItemSettingAdd',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker },
    setup() {
        const { model, v$, timeUnits, create } = useItemSettingAdd();

        return { v$, model, timeUnits, create };
    }
});
