
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import useSubscription from '@/modules/subscription/item/model/detail';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'SubscriptionDetait',
    components: { AppCard, AppButton },
    setup() {
        const { model, routes, fetch, remove } = useSubscription();

        const rederTimeUnit = (value: any) => {
            switch (Number(value)) {
                case 0:
                    return `<span class='badge badge-success'>Minute</span>`;
                case 1:
                    return `<span class='badge badge-success'>Hour</span>`;
                case 2:
                    return `<span class='badge badge-success'>Day</span>`;
                case 3:
                    return `<span class='badge badge-success'>Week</span>`;
                case 4:
                    return `<span class='badge badge-success'>Month</span>`;
                default:
                    return `<span class='badge badge-success'>Error</span>`;
            }
        };

        onMounted(async () => {
            await fetch();
        });

        return { model, routes, rederTimeUnit, formatDate, remove };
    }
});
