import { useVDialog } from '@/plugins/dialog/v-dialog';
import { surpriseService } from '@/services/surprise';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useSurpriseSetting() {
    const dialog = useVDialog();
    const toast = useToast();

    const model = reactive({
        autoSendPercent: 0,
        autoSendSurpriseItems: '',
        surpriseCategoryForBet: '',
        botAutoSendPercent: 0,
        botAutoReceivePercent: 0,
        canRemove: false
    });

    const validations = {
        autoSendPercent: { required, integer, minValue: minValue(0) },
        autoSendSurpriseItems: {
            format: validateFormat(/^\d+(,\d+)*$/, 'Please enter in the correct format: Ex: 2,3,5 [in integer numbers]')
        },
        surpriseCategoryForBet: {
            format: validateFormat(/^(\d+(,\d+)*)(;\d+(,\d+)*)*$/, 'Please enter in the correct format: Ex: 7,7;9,9')
        },
        botAutoSendPercent: { required, integer, minValue: minValue(0) },
        botAutoReceivePercent: { required, integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await surpriseService.setting.get();
        if (response.code === 200) {
            const result = response.data;
            model.autoSendPercent = result.autoSendPercent;
            model.surpriseCategoryForBet = result.surpriseCategoryForBet;
            model.botAutoSendPercent = result.botAutoSendPercent;
            model.botAutoSendPercent = result.botAutoSendPercent;
            model.autoSendSurpriseItems = result.autoSendSurpriseItems;
            model.botAutoReceivePercent = result.botAutoReceivePercent;
            model.canRemove = result.canRemove;
        } else if (response.code === 404) {
            model.autoSendPercent = 0;
            model.autoSendSurpriseItems = '';
            model.surpriseCategoryForBet = '';
            model.botAutoSendPercent = 0;
            model.botAutoReceivePercent = 0;
            model.canRemove = false;
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await surpriseService.setting.update(
                model.autoSendPercent,
                model.autoSendSurpriseItems,
                model.surpriseCategoryForBet,
                model.botAutoSendPercent,
                model.botAutoReceivePercent
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this setting?',
                onConfirm: async result => {
                    if (result) {
                        const response = await surpriseService.setting.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, actions, fetch };
}
