
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder } from '@/components';
import { usePiggybankEdit } from '@/modules/piggybank/model/edit';

export default defineComponent({
    name: 'PiggyBankEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, fetchIapOptions, actions, iapOptions, routes } = usePiggybankEdit();

        onMounted(async () => {
            await fetchIapOptions();
            await fetch();
        });

        return { v$, model, actions, routes, iapOptions, renderStatus };
    }
});

const statusList = [
    { label: 'Disabled', value: 3, background: 'warning' },
    { label: 'Cleaning', value: 2, background: 'warning' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'None', value: 0, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
