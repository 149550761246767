import { newsService } from '@/services/news';
import { useVuelidate } from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useNewsContent() {
    const toast = useToast();
    const router = useRouter();
    const { model, updateContentValidation, options, fetch } = useMixins();

    const v$ = useVuelidate(updateContentValidation, model);

    const actions = {
        updateContent: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const fromRegisterTime = model?.fromRegisterTime?.getTime() ?? null;
            const toRegisterTime = model?.toRegisterTime?.getTime() ?? null;

            let target = null;
            if (model.targetType == 0) {
                target = 'link:' + model.target;
            } else if (model.targetType == 1) {
                target = 'popup:' + model.target;
            } else if (model.targetType == 2) {
                target = 'deeplink:' + model.target;
            }

            const response = await newsService.updateContent(
                model.id,
                model.name,
                target,
                fromRegisterTime,
                toRegisterTime,
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({ name: 'NewsList' });
            }
        }
    };

    return { model, v$, options, fetch, actions };
}
