import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update League Tier"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.season === 2)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "Point"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.point,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.point) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.point
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.image
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.imageFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_app_button, {
              name: "Reward",
              theme: "info",
              icon: "fa-save",
              onClick: _withModifiers(_ctx.routes.reward, ["prevent"])
            }, null, 8, ["onClick"]),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Delete",
                  theme: "danger",
                  icon: "fa-trash-alt",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}