
import { defineComponent, onMounted, ref, watch } from 'vue';
import { avatarService } from '@/services/avatar';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'app-avatar-breadcrumb',
    props: {
        categoryId: {
            default: null
        }
    },
    setup(props) {
        const router = useRouter();
        const list = ref([]);

        async function fetch() {
            if (props.categoryId && props.categoryId > 0) {
                const response = await avatarService.category.breadcrumb(props.categoryId);
                if (response.code === 200) {
                    list.value = response.data;
                }
            } else {
                list.value = [];
            }
        }

        function routerLinkToAvatarCategory(parentId: any) {
            router.push({ name: 'SysAvatarCategoryList', query: parentId ? { parentId: parentId } : {} });
        }

        onMounted(() => fetch());

        watch(() => props.categoryId, fetch);

        return { list, routerLinkToAvatarCategory };
    }
});
