
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppButton, AppFileUpload } from '@/components';
import { useSurpriseItemAdd } from '@/modules/surprise/category/item/model/add';

export default defineComponent({
    name: 'SurpriseItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppButton, AppFileUpload },
    setup() {
        const { model, v$, create } = useSurpriseItemAdd();

        return { v$, model, create };
    }
});
