import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/mail/setting');
};

const update = (name: any, email: any, type: any) => {
    return http.doPut('/admin/mail/setting', {
        name: name,
        email: email,
        type: type
    });
};

const remove = () => {
    return http.doDelete('/admin/mail/setting');
};

export function mailService() {
    return {
        get,
        remove,
        update
    };
}
