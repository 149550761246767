
import { computed, defineComponent } from 'vue';
import { IUser } from '@/interfaces/user';
import { AppMenuItem } from '@/components';
import store from '@/store';
import { getMenu } from './model';

export default defineComponent({
    name: 'AppMenuSidebar',
    components: { AppMenuItem },
    setup() {
        const user = computed((): IUser => store.getters['auth/user']);
        const sidebarSkin = process.env.VUE_APP_SIDEBAR_SKIN;
        const MENU = getMenu();

        return { user, sidebarSkin, MENU };
    }
});
