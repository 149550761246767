import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/totem');
};

const update = (value: any, frequency: any, limitCount: any, isSpecial: any, cooldown: any) => {
    return http.doPut('/admin/totem', {
        value: value,
        frequency: frequency,
        limitCount: limitCount,
        isSpecial: isSpecial,
        cooldown: cooldown
    });
};

export function totemService() {
    return {
        get,
        update
    };
}
