
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'app-error',
    props: {
        validations: {
            default: null
        }
    },
    setup(props) {
        const isInvalidValue = computed(() => {
            return props.validations && props.validations.$errors.length;
        });

        return { isInvalidValue };
    }
});
