
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppButton } from '@/components';
import { useSurpriseCategoryAdd } from '@/modules/surprise/category/model/add';

export default defineComponent({
    name: 'SurpriseCategoryAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppButton },
    setup() {
        const { model, v$, create } = useSurpriseCategoryAdd();

        return { v$, model, create };
    }
});
