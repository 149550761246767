import localeService from '@/services/locale';
import { useFetchList } from '@/utils/useFetch';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useLocale() {
    const store = useStore();
    const toast = useToast();

    const localeStore = {
        getPage: () => store.getters['locale/page'],
        setPage: (page: number) => store.dispatch('locale/changePage', page),
        getCriteria: () => store.getters['locale/criteria'],
        setCriteria: () => store.dispatch('locale/changeCriteria', criteria.value)
    };

    const localeListSupported = ref([]);
    const criteria = ref(localeStore.getCriteria());

    const { itemList, total, currentPage, changePage } = useFetchList(localeService.list, {
        pagingStore: localeStore,
        options: criteria,
        onSuccess: () => localeStore.setCriteria()
    });

    const getListSupported = async () => {
        const response = await localeService.listSupported();
        if (response.code === 200) {
            localeListSupported.value = response.data;
            total.value = response.data.total;
        } else {
            toast.error(response.data);
            localeListSupported.value = [];
        }
    };

    const localeSupportedOptions = computed(() =>
        localeListSupported.value.map(l => ({ label: l.language, value: l.id }))
    );

    const actions = {
        add: async (locale: string) => {
            const response = await localeService.add(locale);
            if (response.code === 200) {
                toast.success('Add successfully');
                changePage(currentPage.value);
            } else {
                toast.error(response.data);
            }
        },

        publish: async (locale: string) => {
            const response = await localeService.publish(locale);
            if (response.code === 200) {
                toast.success('Publish successfully');
                changePage(currentPage.value);
            } else {
                toast.error(response.data);
            }
        },

        unpublish: async (locale: string) => {
            const response = await localeService.unpublish(locale);
            if (response.code === 200) {
                toast.success('Unpublish successfully');
                changePage(currentPage.value);
            } else {
                toast.error(response.data);
            }
        },

        setDefault: async (locale: string) => {
            const response = await localeService.setDefault(locale);
            if (response.code === 200) {
                toast.success('Successfully');
                changePage(currentPage.value);
            } else {
                toast.error(response.data);
            }
        },

        remove: async (locale: string) => {
            const response = await localeService.remove(locale);
            if (response.code === 200) {
                toast.success('Delete successfully');
                changePage(currentPage.value);
            } else {
                toast.error(response.data);
            }
        }
    };

    return {
        itemList,
        localeListSupported,
        localeSupportedOptions,
        total,
        changePage,
        currentPage,
        getListSupported,
        criteria,
        actions
    };
}
