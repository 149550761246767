import http from '@/utils/axios';

export const avatarService = {
    gender: {
        get(gender: any) {
            return http.doGet('admin/avatar/default/gender/' + gender);
        },

        update(gender: any, image: any) {
            return http.doPost(
                'admin/avatar/default/gender/' + gender,
                {},
                {
                    image: image
                }
            );
        },

        remove(gender: any) {
            return http.doDelete('admin/avatar/default/gender/' + gender);
        }
    },

    default: {
        list(paging: { offset: any; limit: any }, options: { type: any }) {
            return http.doGet('admin/avatar/default', {
                ...paging,
                ...options
            });
        },

        get(id: any) {
            return http.doGet('admin/avatar/default/' + id);
        },

        create(type: any, name: any, gender: any, firstnames: any, isPublished: any, image: any) {
            return http.doPost(
                'admin/avatar/default',
                {
                    type: type,
                    name: name,
                    gender: gender,
                    firstnames: firstnames,
                    isPublished: isPublished
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, firstnames: any, isPublished: any, image: any) {
            return http.doPost(
                'admin/avatar/default/' + id,
                {
                    name: name,
                    firstnames: firstnames,
                    isPublished: isPublished
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/default/' + id);
        }
    },

    bundle: {
        create(name: any, gender: any) {
            return http.doPost('admin/avatar/bundle', {
                name: name,
                gender: gender
            });
        },

        update(id: any, name: any, gender: any, status: any) {
            return http.doPut('admin/avatar/bundle/' + id, {
                name: name,
                gender: gender,
                status: status
            });
        },

        list(paging: { offset: any; limit: any }) {
            return http.doGet('admin/avatar/bundle', {
                ...paging
            });
        },

        listEditing() {
            return http.doGet('admin/avatar/bundle/editing');
        },

        get(id: any) {
            return http.doGet('admin/avatar/bundle/' + id);
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/bundle/' + id);
        },

        listItem(_: any, id: any) {
            return http.doGet('admin/avatar/bundle/' + id + '/item');
        },

        link(id: any, itemId: any) {
            return http.doPut('admin/avatar/bundle/' + id + '/item/' + itemId);
        },

        unlink(id: any, itemId: any) {
            return http.doDelete('admin/avatar/bundle/' + id + '/item/' + itemId);
        },

        getListForItem(itemId: any) {
            return http.doGet('admin/avatar/bundle/item/' + itemId);
        },

        updateForItem(itemId: any, bundleIds: any) {
            return http.doPut('admin/avatar/bundle/item/' + itemId, {
                bundleIds: bundleIds
            });
        }
    },

    category: {
        breadcrumb(id: any) {
            return http.doGet('admin/avatar/category/' + id + '/breadcrumb');
        },

        list(paging: { offset: any; limit: any }, options: { parentId: any; name: any }) {
            return http.doGet('admin/avatar/category', {
                ...paging,
                ...options
            });
        },

        get(id: any) {
            return http.doGet('admin/avatar/category/' + id);
        },

        create(
            parentId: any,
            name: any,
            position: any,
            gender: any,
            isPublished: any,
            isRequired: any,
            posX: any,
            posY: any,
            isBody: any,
            image: any
        ) {
            return http.doPost(
                'admin/avatar/category',
                {
                    parentId: parentId,
                    name: name,
                    position: position,
                    gender: gender,
                    isPublished: isPublished,
                    isRequired: isRequired,
                    posX: posX,
                    posY: posY,
                    isBody: isBody
                },
                {
                    image: image
                }
            );
        },

        update(
            id: any,
            name: any,
            position: any,
            gender: any,
            isPublished: any,
            isRequired: any,
            posX: any,
            posY: any,
            isBody: any,
            image: any
        ) {
            return http.doPost(
                'admin/avatar/category/' + id,
                {
                    name: name,
                    position: position,
                    gender: gender,
                    isPublished: isPublished,
                    isRequired: isRequired,
                    posX: posX,
                    posY: posY,
                    isBody: isBody
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/category/' + id);
        }
    },

    color: {
        list() {
            return http.doGet('admin/avatar/color');
        },

        get(id: any) {
            return http.doGet('admin/avatar/color/' + id);
        },

        create(name: any, color: any) {
            return http.doPost('admin/avatar/color', {
                name: name,
                color: color
            });
        },

        update(id: any, name: any, color: any) {
            return http.doPut('admin/avatar/color/' + id, {
                name: name,
                color: color
            });
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/color/' + id);
        }
    },

    item: {
        list(paging: { offset: any; limit: any }, options: { categoryId: any; name: any; isNew: any }) {
            return http.doGet('admin/avatar/item', {
                ...paging,
                ...options
            });
        },

        create(
            name: any,
            categoryId: any,
            price: any,
            isPublished: any,
            isNew: any,
            isDefault: any,
            isSpecial: any,
            limitSale: any
        ) {
            return http.doPost('admin/avatar/item', {
                name: name,
                categoryId: categoryId,
                price: price,
                isPublished: isPublished,
                isNew: isNew,
                isDefault: isDefault,
                isSpecial: isSpecial,
                limitSale: limitSale
            });
        },

        get(id: any) {
            return http.doGet('admin/avatar/item/' + id);
        },

        update(
            id: any,
            name: any,
            price: any,
            isPublished: any,
            isNew: any,
            isDefault: any,
            isSpecial: any,
            limitSale: any
        ) {
            return http.doPut('admin/avatar/item/' + id, {
                name: name,
                price: price,
                isPublished: isPublished,
                isNew: isNew,
                isDefault: isDefault,
                isSpecial: isSpecial,
                limitSale: limitSale
            });
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/item/' + id);
        }
    },

    image: {
        add(itemId: any, colorId: any, layerId: any, isDefault: any, image: any) {
            return http.doPost(
                'admin/avatar/item/' + itemId + '/image',
                {
                    colorId: colorId,
                    layerId: layerId,
                    isDefault: isDefault
                },
                {
                    image: image
                }
            );
        },

        update(itemId: any, id: any, colorId: any, layerId: any, isDefault: any, image: any) {
            return http.doPost(
                'admin/avatar/item/' + itemId + '/image/' + id,
                {
                    colorId: colorId,
                    layerId: layerId,
                    isDefault: isDefault
                },
                {
                    image: image
                }
            );
        },

        list(paging: { offset: any; limit: any }, itemId: any) {
            return http.doGet('admin/avatar/item/' + itemId + '/image', {
                ...paging
            });
        },

        get(itemId: any, id: any) {
            return http.doGet('admin/avatar/item/' + itemId + '/image/' + id);
        },

        remove(itemId: any, id: any) {
            return http.doDelete('admin/avatar/item/' + itemId + '/image/' + id);
        }
    },

    layer: {
        list() {
            return http.doGet('admin/avatar/layer');
        },

        get(id: any) {
            return http.doGet('admin/avatar/layer/' + id);
        },

        create(name: any, position: any) {
            return http.doPost('admin/avatar/layer', {
                name: name,
                position: position
            });
        },

        update(id: any, name: any, position: any) {
            return http.doPut('admin/avatar/layer/' + id, {
                name: name,
                position: position
            });
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/layer/' + id);
        }
    },

    archive(file: any) {
        return http.doPost(
            'admin/avatar/archive',
            {},
            {
                file: file
            }
        );
    },

    background: {
        list(paging: { offset: any; limit: any }) {
            return http.doGet('admin/avatar/background', { ...paging });
        },

        get(id: any) {
            return http.doGet('admin/avatar/background/' + id);
        },

        create(name: any, price: any, isPublished: any, isDefault: any, isNew: any, image: any) {
            return http.doPost(
                'admin/avatar/background',
                {
                    name: name,
                    price: price,
                    isPublished: isPublished,
                    isDefault: isDefault,
                    isNew: isNew
                },
                {
                    image: image
                }
            );
        },

        update(id: any, name: any, price: any, isPublished: any, isDefault: any, isNew: any, image: any) {
            return http.doPost(
                'admin/avatar/background/' + id,
                {
                    name: name,
                    price: price,
                    isPublished: isPublished,
                    isDefault: isDefault,
                    isNew: isNew
                },
                {
                    image: image
                }
            );
        },

        remove(id: any) {
            return http.doDelete('admin/avatar/background/' + id);
        }
    }
};
