import http from '@/utils/axios';

export const maintenanceService = {
    get() {
        return http.doGet('admin/maintenance');
    },

    notify(startTime: any, endTime: any) {
        return http.doPut('admin/maintenance/notify', {
            startTime: startTime,
            endTime: endTime
        });
    },

    cancelNotify() {
        return http.doDelete('admin/maintenance/notify');
    },

    enter() {
        return http.doPut('admin/maintenance/enter');
    },

    exit() {
        return http.doPut('admin/maintenance/exit');
    }
};
