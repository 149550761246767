import { reactive, ref } from 'vue';
import { minValue, required } from '@vuelidate/validators';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';

export function useAvatarItemAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const category = ref(null);

    const model = reactive({
        categoryId: route.query?.categoryId ?? null,
        name: '',
        price: 0,
        isPublished: false,
        isNew: false,
        isDefault: false,
        isSpecial: false,
        limitSale: 0
    });

    const validations = {
        name: { required },
        price: { required, min: minValue(0) },
        limitSale: { required, min: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (model.categoryId) {
            const response = await avatarService.category.get(model.categoryId);
            if (response.code === 200) {
                category.value = response.data;
            } else {
                toast.error(response.data);
                router.push({ name: 'SysAvatarCategoryList' });
            }
        } else {
            toast.error('Not found category');
            router.push({ name: 'SysAvatarCategoryList' });
        }
    };

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.item.create(
            model.name,
            model.categoryId,
            model.price,
            model.isPublished,
            model.isNew,
            model.isDefault,
            model.isSpecial,
            model.limitSale
        );
        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'SysAvatarItemEdit', params: { itemId: response.data } });
        } else {
            toast.error(response.data);
        }
    };

    return { model, category, v$, fetch, create };
}
