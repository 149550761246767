import { useLocale } from '@/modules/locale/model/locale';
import { transactionService } from '@/services/transaction';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useTransactionList() {
    const store = useStore();
    const router = useRouter();
    const { localeListSupported, getListSupported } = useLocale();

    const model = reactive({
        locale: null,
        locales: []
    });

    const transactionStore = {
        getPage: () => store.getters['transaction/page'],
        setPage: (page: number) => store.dispatch('transaction/changePage', page),
        getFilter: () => store.getters['transaction/filter'],
        setFilter: () => store.dispatch('transaction/changeFilter', { locale: model.locale })
    };

    const { itemList, total, currentPage, changePage } = useFetchList(transactionService.text.list, {
        pagingStore: transactionStore,
        options: toRef(model, 'locale'),
        onSuccess: () => transactionStore.setFilter()
    });

    const beforeFetch = async () => {
        // load filter from store
        model.locale = transactionStore.getFilter()?.locale ?? null;

        await getListSupported();
        model.locales = localeListSupported.value.map(l => {
            return { label: l.language, value: l.id };
        });

        if (!model?.locale?.length) {
            model.locale = model.locales?.[0]?.value ?? null;
            transactionStore.setFilter();
        }
    };

    const routes = {
        edit: (code: any, locale: any) =>
            router.push({ name: 'TransactionTextEdit', params: { code: code, locale: locale } })
    };

    return { model, itemList, total, currentPage, beforeFetch, changePage, routes };
}
