import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarImageList() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarImageStore = {
        getPage: () => store.getters['avatarImage/page'],
        setPage: (page: number) => store.dispatch('avatarImage/changePage', page)
    };

    const avatarItemId = route.params.itemId;
    const avatarItem = ref(null);
    const { fetchList: fetchColors, itemList: colors } = useAvatarColorList();
    const { fetchList: fetchLayers, itemList: layers } = useAvatarLayerList();

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.image.list, {
        pagingStore: avatarImageStore,
        options: ref(avatarItemId)
    });

    const beforeFetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);

        const response = await avatarService.item.get(avatarItemId);
        if (response.code === 200) {
            avatarItem.value = response.data;
        } else {
            toast.error(response.data);
            router.push({ name: 'SysAvatarItemList' });
        }
    };

    const fetchImages = async (page: number) => {
        await changePage(page);

        itemList.value = itemList.value.map(item => {
            const color = colors.value.find(c => c.id == item.colorId)?.name;
            const layer = layers.value.find(c => c.id == item.layerId)?.name;

            return { ...item, colorName: color, layerName: layer };
        });
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this image?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.image.remove(avatarItemId, id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchImages(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarItemImageAdd', params: { itemId: avatarItemId } }),
        editImage: (imageId: any) =>
            router.push({ name: 'SysAvatarItemImageEdit', params: { itemId: avatarItemId, imageId: imageId } }),
        editItem: () => router.push({ name: 'SysAvatarItemEdit', params: { itemId: avatarItemId } })
    };

    return { avatarItem, itemList, total, currentPage, beforeFetch, fetchImages, actions, routes };
}
