import { pageSize } from '@/utils/helpers';
import { reactive, ref, ToRef, ToRefs } from 'vue';
import { useToast } from 'vue-toastification';

export interface IPagingStore {
    getPage: () => number;
    setPage: (page: number) => void;
}

export interface IFetchListParams {
    pagingStore?: IPagingStore;
    options?: ToRef<any> | ToRefs<any>;
    onSuccess?: () => void;
}

export function useFetchList(_request: any, _params: IFetchListParams = null) {
    const toast = useToast();
    const itemList = ref([]);
    const total = ref(0);
    const currentPage = ref(_params?.pagingStore?.getPage() ?? 1);

    const paging = reactive({
        page: currentPage.value,
        offset: 0,
        limit: _params?.pagingStore ? pageSize : Number.MAX_SAFE_INTEGER
    });

    const changePage = async (page: number) => {
        currentPage.value = page;
        _params?.pagingStore?.setPage(page);

        paging.page = page;
        paging.offset = (page - 1) * paging.limit;
        await fetchList();

        // case: offset is out of the total item
        if (total.value > 0 && itemList.value.length === 0 && page !== 1) {
            // retry with page = 1
            currentPage.value = 1;
        }
    };

    const fetchList = async () => {
        const response = await _request(paging, _params?.options?.value ?? {});
        if (response.code === 200) {
            if (response.data?.list) {
                itemList.value = response.data.list;
                total.value = response.data.total;
            } else {
                itemList.value = response?.data ?? [];
                total.value = response?.data?.length ?? 0;
            }

            if (typeof _params?.onSuccess === 'function') {
                _params.onSuccess();
            }
        } else {
            toast.error(response.data);
            itemList.value = [];
            total.value = 0;
        }
    };

    return { itemList, total, currentPage, pageSize, fetchList, changePage };
}
