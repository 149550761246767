import { useVDialog } from '@/plugins/dialog/v-dialog';
import { bonusService } from '@/services/bonus';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useBonusLoginEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        type: null,
        value: '',
        adsBoost: '',
        adsRescue: 0,
        timeUnit: 0,
        timeDuration: 0,
        startTime: new Date(),
        canDelete: false
    });

    const validations = {
        type: { required },
        timeDuration: { required, min: minValue(1), max: maxValue(32000) },
        value: { required, format: validateFormat(/^\d+(,\d+)*$/) },
        adsBoost: { required, format: validateFormat(/^\d+:\d+(,\d+:\d+)*$/) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await bonusService.login.get(route.params.id);
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push({ name: 'BonusLoginList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await bonusService.login.update(
                model.id,
                model.name,
                model.value,
                model.adsBoost,
                model.adsRescue,
                model.timeUnit,
                model.timeDuration,
                model?.startTime?.getTime() ?? null
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({ name: 'BonusLoginList' });
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await bonusService.login.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'BonusLoginList' });
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
