
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton, AppDateTimePicker } from '@/components';
import useGiftLucky from '@/modules/gift/model/lucky';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'GiftLucky',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, actions } = useGiftLucky();

        onMounted(async () => {
            await fetch();
        });

        return { v$, timeUnits, model, actions };
    }
});
