import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { decimal, maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';

export function useMixins() {
    const model = reactive({
        id: null,
        name: null,
        currency: 'EUR',
        creditInit: 0,
        friendLimit: 0,
        popupRate: null,
        winsForRating: -1,
        bindSuggestTime: -1,
        remindApple: false,
        remainVipMinutes: '',
        vipTermMinutes: 0,
        creditWelcomeLimit: 0,
        thumbnail: null,
        thumbnailFile: null,
        removeTime: null,
        createTime: null,
        canDelete: null
    });

    const validations = {
        thumbnailFile: {},
        name: { required },
        currency: { required },
        creditInit: { required, decimal, minValue: minValue(0) },
        friendLimit: { required, numeric, minValue: minValue(0) },
        winsForRating: { required, decimal, minValue: minValue(-1) },
        bindSuggestTime: { required, decimal, minValue: minValue(-1) },
        vipTermMinutes: { required, decimal, minValue: minValue(0) },
        creditWelcomeLimit: { required, decimal, minValue: minValue(0), maxValue: maxValue(10000) },
        remindApple: { required },
        remainVipMinutes: {
            required,
            format: validateFormat(/^\d+(,\d+)*$/, 'Please enter in the correct format: Ex: 2,3,5 [in integer numbers]')
        }
    };

    const v$ = useVuelidate(validations, model);

    return { model, v$, currencyOptions };
}

export const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' }
];
