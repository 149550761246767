
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useRfmFrequency } from '@/modules/rfm/frequency/model/edit';

export default defineComponent({
    name: 'FrequencyEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, rfmGroupOptions, beforeFetch, actions } = useRfmFrequency();

        onMounted(async () => {
            await beforeFetch();
            await fetch(route.params.id);
        });

        return { v$, model, rfmGroupOptions, actions };
    }
});
