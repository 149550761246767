
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useAccountDetail } from '@/modules/account/app/model/detail';
import { formatDate } from '@/utils/helpers';
import * as bootstrap from 'admin-lte/plugins/bootstrap/js/bootstrap.min.js';

export default defineComponent({
    name: 'Account Profile',
    components: { AppCard, AppButton },
    setup() {
        const { model, fetch, actions, routes } = useAccountDetail();

        onMounted(async () => {
            await fetch();
            document.querySelectorAll('[data-toggle="tooltip"]').forEach(el => new bootstrap.Tooltip(el));
        });

        return { model, actions, routes, formatDate };
    }
});
