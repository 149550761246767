
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppCard, AppButton, AppSelect, AppInput } from '@/components';
import { useItemEdit } from '@/modules/item/system/model/link';

export default defineComponent({
    name: 'SystemItemLink',
    components: { AppFormRow, AppCard, AppButton, AppSelect, AppInput },
    setup() {
        const { model, actions, beforeFetch, fetch } = useItemEdit();

        onMounted(() => {
            beforeFetch();
        });

        return { model, actions, fetch };
    }
});
