import { useVDialog } from '@/plugins/dialog/v-dialog';
import { chatService } from '@/services/chat';
import useVuelidate from '@vuelidate/core';
import { integer, minValue } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        banTimeout: '0',
        minGameWin: '0'
    });

    const validations = {
        banTimeout: { integer, minValue: minValue(1) },
        minGameWin: { integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await chatService.setting.get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = true;
            model.banTimeout = result.banTimeout;
            model.minGameWin = result.minGameWin;
        } else if (response.code === 404) {
            model.isNew = false;
            model.banTimeout = '';
            model.minGameWin = '';
        } else {
            toast.error(response.data, { timeout: 2000 });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await chatService.setting.update(model.banTimeout, model.minGameWin);
            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                await fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.setting.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            await fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
