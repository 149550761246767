import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "file",
    accept: _ctx.accept,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event)))
  }, null, 40, _hoisted_1))
}