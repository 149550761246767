import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarBundleList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarBundleStore = {
        getPage: () => store.getters['avatarBundle/page'],
        setPage: (page: number) => store.dispatch('avatarBundle/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.bundle.list, {
        pagingStore: avatarBundleStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.bundle.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarBundleAdd' }),
        edit: (bundleId: any) => router.push({ name: 'SysAvatarBundleEdit', params: { bundleId: bundleId } }),
        item: (bundleId: any) => router.push({ name: 'SysAvatarBundleItem', params: { bundleId: bundleId } }),
        specialShop: (bundleId: any) => router.push({ name: 'SystemItemAdd', query: { data: bundleId, feature: 1 } })
    };

    return { itemList, total, currentPage, changePage, actions, routes };
}
