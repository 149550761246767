
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton } from '@/components';
import { useGameVariantEdit } from '@/modules/game/bet/model/edit';
export default defineComponent({
    name: 'GameBetEdit',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, beforeFetch, fetch, actions, options } = useGameVariantEdit();

        onMounted(async () => {
            await beforeFetch();
            await fetch(route.params.id);
        });

        return { model, v$, options, actions };
    }
});
