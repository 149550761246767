
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useApple } from '@/modules/apple/model/edit';

export default defineComponent({
    name: 'Apple',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, fetch, actions } = useApple();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions };
    }
});
