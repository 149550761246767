import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update application setting"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Create Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.createTime,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.createTime) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Currency" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.currencyOptions,
              modelValue: _ctx.model.currency,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.currency) = $event)),
              validations: _ctx.v$.currency
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Credit Init" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.creditInit,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.creditInit) = $event)),
              validations: _ctx.v$.creditInit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Credit Welcome Limit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.creditWelcomeLimit,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.creditWelcomeLimit) = $event)),
              validations: _ctx.v$.creditWelcomeLimit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Friend Limit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.friendLimit,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.friendLimit) = $event)),
              validations: _ctx.v$.friendLimit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Popup Rate" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.popupRate,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.popupRate) = $event)),
              validations: _ctx.v$.popupRate
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Wins For Rating" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.winsForRating,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.winsForRating) = $event)),
              validations: _ctx.v$.winsForRating
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bind suggest time (seconds)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.bindSuggestTime,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.bindSuggestTime) = $event)),
              validations: _ctx.v$.bindSuggestTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Remain Apple" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.remindApple,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.remindApple) = $event)),
              validations: _ctx.v$.remindApple
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Remain Vip Minutes" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.remainVipMinutes,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.remainVipMinutes) = $event)),
              validations: _ctx.v$.remainVipMinutes
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Vip Term Minutes" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.vipTermMinutes,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.vipTermMinutes) = $event)),
              validations: _ctx.v$.vipTermMinutes
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              icon: "fa-save",
              theme: "info",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.removeTime)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  name: "Undelete",
                  icon: "fa-undo",
                  theme: "warning",
                  onClick: _withModifiers(_ctx.actions.unremove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}