import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Add Default Avatar"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.typeList,
              modelValue: _ctx.model.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.type) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.type
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.type === 1 || _ctx.model.type === 4)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "First Name(s)"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  modelValue: _ctx.model.firstnames,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.firstnames) = $event)),
                  validations: _ctx.v$.firstnames,
                  placeholder: "Name1,Name2..."
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.model.type === 3)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 1,
              label: "Gender"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_select, {
                  options: _ctx.genderList,
                  modelValue: _ctx.model.gender,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.gender) = $event)),
                  modelModifiers: { number: true }
                }, null, 8, ["options", "modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_file_upload, {
              accept: "image/png",
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.imageFile) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Is Published" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_switch, {
              modelValue: _ctx.model.isPublished,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.isPublished) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Create",
              theme: "info",
              icon: "fa-save",
              onClick: _withModifiers(_ctx.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}