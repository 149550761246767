
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { useSurpriseCategoryList } from '@/modules/surprise/category/model/list';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'SurpriseCategoryList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, changePage, actions, routes } = useSurpriseCategoryList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Is published', display: (row: any) => renderBooleanValue(row.isPublished) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-leaf',
                        theme: 'info',
                        tooltip: 'View items',
                        onclick: () => routes.item(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await changePage(1);
        });

        return { table, changePage, routes };
    }
});

function renderBooleanValue(value: any) {
    return `<span class='badge badge-${value ? 'success' : 'secondary'}'>${value ? 'Yes' : 'No'}</span>`;
}
