import http from '@/utils/axios';

export const iapService = {
    list() {
        return http.doGet('/admin/product/iap');
    },

    listForOtherApp(appId: any) {
        return http.doGet('/admin/product/iap', {
            appId: appId
        });
    },

    get(id: any) {
        return http.doGet(`/admin/product/iap/${id}`);
    },

    update(itemId: any, name: any, feature: any, packageId: any, priceId: any, price: any, credit: any) {
        return http.doPut(`/admin/product/iap/${itemId}`, {
            name: name,
            feature: feature,
            packageId: packageId,
            priceId: priceId,
            price: price,
            credit: credit
        });
    },

    create(name: any, feature: any, packageId: any, priceId: any, price: any, credit: any) {
        return http.doPost(`/admin/product/iap`, {
            name: name,
            feature: feature,
            packageId: packageId,
            priceId: priceId,
            price: price,
            credit: credit
        });
    },

    remove(itemId: any) {
        return http.doDelete('/admin/product/iap/' + itemId);
    }
};
