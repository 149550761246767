import { iapService } from '@/services/iap';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export default function () {
    const router = useRouter();
    const toast = useToast();

    const { v$, model, features } = useMixins();

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await iapService.create(
            model.name,
            model.feature,
            model.packageId,
            model.priceId,
            model.price,
            model.credit
        );

        if (response.code === 200) {
            toast.success('create successfully');
            router.push({ name: 'IAPList' });
        }
    };

    return { v$, model, features, create };
}
