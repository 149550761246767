import http from '@/utils/axios';

export const challengeService = {
    list: async () => {
        return http.doGet('/admin/challenge');
    },

    get: (id: any) => {
        return http.doGet('/admin/challenge/' + id);
    },

    create: (name: any, toApp: any, amount: any, link: any, startTime: any, endTime: any, image: any) => {
        return http.doPost(
            '/admin/challenge',
            {
                name: name,
                toApp: toApp,
                amount: amount,
                link: link,
                startTime: startTime,
                endTime: endTime,
                image: image
            },
            {
                image: image
            }
        );
    },

    update: (id: any, name: any, amount: any, link: any, image: any) => {
        return http.doPost(
            '/admin/challenge/' + id,
            {
                name: name,
                amount: amount,
                link: link
            },
            {
                image: image
            }
        );
    },

    remove: (id: any) => {
        return http.doDelete('/admin/challenge/' + id);
    },

    enable: (id: any) => {
        return http.doPut(`/admin/challenge/${id}/enable`);
    },

    disable: (id: any) => {
        return http.doPut(`/admin/challenge/${id}/disable`);
    },

    updateContent: (id: any, locale: any, title: any) => {
        return http.doPost('admin/challenge/' + id + '/text/' + locale, {
            title: title
        });
    },

    removeContent: (id: any, locale: any) => {
        return http.doDelete('admin/challenge/' + id + '/text/' + locale);
    },

    getContent: (id: any, locale: any) => {
        return http.doGet('admin/challenge/' + id + '/text/' + locale);
    }
};
