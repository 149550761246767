
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'app-card',
    props: {
        label: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        }
    }
});
