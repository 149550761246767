import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card card-info card-outline" }
const _hoisted_2 = {
  key: 0,
  class: "card-header"
}
const _hoisted_3 = { class: "card-title m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.label), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["card-body xs-px-1", _ctx.classes])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _renderSlot(_ctx.$slots, "footer")
  ]))
}