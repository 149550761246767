import { transactionService } from '@/services/transaction';
import FileSaver from 'file-saver';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useTransactionExport() {
    const toast = useToast();
    const model = reactive({
        file: null
    });

    const exportXml = async () => {
        const response = await transactionService.text.export();
        if (response.code === 200) {
            const blob = new Blob([response.data], { type: 'text/xml;charset=utf-8' });
            FileSaver.saveAs(blob, 'translation.xml');
        }
    };

    const importXml = async () => {
        const response = await transactionService.text.import(model.file);
        if (response.code === 200) {
            toast.success('Import successfully!');
            model.file = null;
        } else {
            toast.error(response.data);
        }
    };

    return { model, fetch, exportXml, importXml };
}
