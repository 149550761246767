import { newsService } from '@/services/news';
import { useVuelidate } from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useNewsEdit() {
    const toast = useToast();
    const router = useRouter();
    const { model, validations, options, fetch } = useMixins();

    const v$ = useVuelidate(validations, model);

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const startTime = model?.startTime?.getTime() ?? null;
            const endTime = model?.endTime?.getTime() ?? null;

            let target = null;
            if (model.targetType == 0) {
                target = 'link:' + model.target;
            } else if (model.targetType == 1) {
                target = 'popup:' + model.target;
            } else if (model.targetType == 2) {
                target = 'deeplink:' + model.target;
            }

            const response = await newsService.update(
                model.id,
                model.name,
                target,
                startTime,
                endTime,
                model.publishTime.getTime(),
                model.unpublishTime.getTime(),
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({ name: 'NewsList' });
            }
        }
    };

    return { model, v$, options, fetch, actions };
}
