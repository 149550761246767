
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useRfmGroup } from '@/modules/rfm/group/model/edit';

export default defineComponent({
    name: 'RfmGroupAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, actions } = useRfmGroup();

        return { v$, model, actions };
    }
});
