
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton } from '@/components';
import { useVersionList } from '@/modules/version/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'VersionList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const { storeId, itemList, total, currentPage, changePage, actions, routes } = useVersionList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: 'id' },
                { label: 'Store', display: (row: any) => storeOptions.find(store => store.value === row.store)?.label },
                { label: 'Version', field: 'version' },
                { label: 'Released date', display: (row: any) => formatDate(row.releasedDate) },
                { label: 'Dropped date', display: (row: any) => formatDate(row.droppedDate) },
                {
                    label: 'Status',
                    display: (row: any) => {
                        let html = '';
                        if (row.status === 2) {
                            html = "<span class='badge badge-success'>Active</span>";
                        }
                        if (row.status === 1) {
                            html = "<span class='badge badge-primary'>Support</span>";
                        }
                        if (row.status === 0) {
                            html = "<span class='badge badge-warning'>Unsupport</span>";
                        }
                        return html;
                    }
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-thumbs-up',
                        theme: 'warning',
                        tooltip: 'Undrop',
                        isShow: row.canUndrop,
                        onclick: () => actions.undrop(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-thumbs-down',
                        theme: 'warning',
                        tooltip: 'Drop',
                        isShow: row.canDrop,
                        onclick: () => actions.drop(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-check',
                        theme: 'success',
                        tooltip: 'Activate',
                        isShow: row.canActive,
                        onclick: () => actions.activate(row.id)
                    }),
                    group: 2
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        isShow: row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { table, changePage, routes, storeId, storeOptions };
    }
});

const storeOptions = [
    { value: 0, label: 'Apple' },
    { value: 1, label: 'Google' },
    { value: 2, label: 'WebGL' }
];
