import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Arena Item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Bet" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.betList,
              modelValue: _ctx.model.betId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.betId) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.betId
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Max rejection" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.maxRejection,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.maxRejection) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.maxRejection
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Price (chip)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.price,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.price) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.price
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Jackpot init (chip)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.jackpotInit,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.jackpotInit) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.jackpotInit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Jackpot win percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.jackpotWinPercent,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.jackpotWinPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.jackpotWinPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Jackpot donation (chip)" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.jackpotDonation,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.jackpotDonation) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.jackpotDonation
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Prizes" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.prizes,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.prizes) = $event)),
              validations: _ctx.v$.prizes
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.typeList,
              modelValue: _ctx.model.ticketType,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.ticketType) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.ticketType
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Publish time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.publishTime,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.publishTime) = $event)),
              validations: _ctx.v$.publishTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Start time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.startTime) = $event)),
              validations: _ctx.v$.startTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Close time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.closeTime,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.closeTime) = $event)),
              validations: _ctx.v$.closeTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "End time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.endTime,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.endTime) = $event)),
              validations: _ctx.v$.endTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Unpublish time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.unpublishTime,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.unpublishTime) = $event)),
              validations: _ctx.v$.unpublishTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}