
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb } from '@/components';
import { useAvatarItemEdit } from '@/modules/avatar/item/model/edit';

export default defineComponent({
    name: 'AvatarItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSwitch, AppAvatarBreadcrumb },
    setup() {
        const { model, category, v$, fetch, actions, routes } = useAvatarItemEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, category, actions, routes };
    }
});
