
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload } from '@/components';
import { useArenaMedalAdd } from '@/modules/arena/medal/model/add';

export default defineComponent({
    name: 'ArenaMedalAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload },
    setup() {
        const { model, v$, create } = useArenaMedalAdd();

        return { v$, model, create };
    }
});
