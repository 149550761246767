import { reactive } from 'vue';
import { email, required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { passwordService } from '@/services/password';
import { useVuelidate } from '@vuelidate/core';
import { useRouter } from 'vue-router';

export function useForgotPassword() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        email: ''
    });

    const validations = {
        email: { required, email }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        submit: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await passwordService.generate(model.email);

            if (response.code === 200) {
                toast.success('Please check your mailbox');
                router.push({ name: 'Login' });
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, actions };
}
