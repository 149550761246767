import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56b0abe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fas fa-exclamation-triangle"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "fas fa-exclamation-triangle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_dropdown = _resolveComponent("app-dropdown")!

  return (_openBlock(), _createBlock(_component_app_dropdown, null, {
    "dropdown-button": _withCtx(() => [
      _createElementVNode("a", null, [
        _createTextVNode(_toDisplayString(_ctx.getApplicationName) + " ", 1),
        (_ctx.isMaintained)
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : _createCommentVNode("", true)
      ])
    ]),
    "dropdown-menu": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.applications, (application) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: application.id
        }, [
          (application.id !== _ctx.model.selectedApplication)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass(["dropdown-item", { active: _ctx.model.selectedApplication === application.id }]),
                onClick: ($event: any) => (_ctx.changeApplication(application.id))
              }, [
                _createTextVNode(_toDisplayString(application.name) + " ", 1),
                (_ctx.isMaintained)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}