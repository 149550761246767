import { loginByEmail } from '@/services/auth';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useLogin() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        email: '',
        password: ''
    });

    const validations = {
        email: { required, email },
        password: { required }
    };

    const v$ = useVuelidate(validations, model);

    async function login() {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await loginByEmail(model.email, model.password);
        if (response.code === 200) {
            const token = response.data;
            store.dispatch('auth/setToken', token);
            await store.dispatch('auth/loadRoles');
            await store.dispatch('auth/loadAppRoles');
            await store.dispatch('application/loadApps');
            store.dispatch('ui/resetLoading');
            toast.success('Login succeeded');
            router.push('/');
        }
    }

    return { model, v$, login };
}
