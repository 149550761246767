import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }
const _hoisted_4 = { class: "form-horizontal" }
const _hoisted_5 = { class: "card-footer" }
const _hoisted_6 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_form_row = _resolveComponent("app-form-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      class: "col-lg-8 mx-auto",
      label: "Export"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_button, {
                name: "Export",
                icon: "fa-file-export",
                theme: "info",
                onClick: _withModifiers(_ctx.exportXml, ["prevent"])
              }, null, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, {
      class: "col-lg-8 mx-auto",
      label: "Import"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_4, [
          _createVNode(_component_app_form_row, { label: "XML File" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_file_upload, {
                accept: ".xml",
                modelValue: _ctx.model.file,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.file) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_button, {
                name: "Import",
                icon: "fa-file-import",
                theme: "info",
                disabled: !_ctx.model.file,
                onClick: _withModifiers(_ctx.importXml, ["prevent"])
              }, null, 8, ["disabled", "onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}