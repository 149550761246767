
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useBonusLoginEdit } from '@/modules/bonus/login/model/edit';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'BonusLoginEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, actions } = useBonusLoginEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, timeUnits, types };
    }
});

const types = [
    { label: 'New Player', value: 1 },
    { label: 'Routine Player', value: 2 }
];
