import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Piggybank Boost"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Status" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.renderStatus(_ctx.model.status)
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.startTime) = $event)),
              validations: _ctx.v$.startTime,
              disabled: !_ctx.model.canEdit
            }, null, 8, ["modelValue", "validations", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "End Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.endTime,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.endTime) = $event)),
              validations: _ctx.v$.endTime,
              disabled: !_ctx.model.canEdit
            }, null, 8, ["modelValue", "validations", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ratio" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.ratio,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.ratio) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.ratio
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.image
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.imageFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            (_ctx.model.canEnable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Enable",
                  icon: "fa-play",
                  theme: "success",
                  onClick: _withModifiers(_ctx.actions.enable, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDisable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  name: "Disable",
                  icon: "fa-stop",
                  theme: "warning",
                  onClick: _withModifiers(_ctx.actions.disable, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 2,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}