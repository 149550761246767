import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Update Content News"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              validations: _ctx.v$.name
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Target type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.options.types,
              modelValue: _ctx.model.targetType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.targetType) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.targetType,
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.model.target = ''))
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.targetType > -1)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 0,
              label: "Taget"
            }, {
              default: _withCtx(() => [
                (_ctx.model.targetType == 1)
                  ? (_openBlock(), _createBlock(_component_app_select, {
                      key: 0,
                      options: _ctx.options.targets,
                      modelValue: _ctx.model.target,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.target) = $event)),
                      modelModifiers: { number: true },
                      validations: _ctx.v$.target
                    }, null, 8, ["options", "modelValue", "validations"]))
                  : (_openBlock(), _createBlock(_component_app_input, {
                      key: 1,
                      modelValue: _ctx.model.target,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.target) = $event)),
                      validations: _ctx.v$.target
                    }, null, 8, ["modelValue", "validations"]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Thumbnail" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.thumbnail
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.thumbnailFile,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.thumbnailFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Image" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_image_holder, {
              image: _ctx.model.image
            }, null, 8, ["image"]),
            _createVNode(_component_app_file_upload, {
              modelValue: _ctx.model.imageFile,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.imageFile) = $event)),
              onChange: _ctx.v$.$touch
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Publish Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.publishTime,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.publishTime) = $event)),
              validations: _ctx.v$.publishTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.startTime) = $event)),
              validations: _ctx.v$.startTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "End Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.endTime,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.endTime) = $event)),
              validations: _ctx.v$.endTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Unpublish Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.unpublishTime,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.unpublishTime) = $event)),
              validations: _ctx.v$.unpublishTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "From Register Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.fromRegisterTime,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.fromRegisterTime) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "To Register Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.toRegisterTime,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.toRegisterTime) = $event)),
              validations: _ctx.v$.toRegisterTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}