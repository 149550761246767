
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import useAppRoleAdd from '@/modules/role/app/model/add';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
    name: 'AppRoleAdd',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard },
    setup() {
        const { model, validations, roleOption, addAppRole } = useAppRoleAdd();
        const v$ = useVuelidate(validations, model);

        return { v$, model, roleOption, addAppRole };
    }
});
