import http from '@/utils/axios';

export const piggybankService = {
    item: {
        list() {
            return http.doGet('admin/piggybank');
        },

        get(itemId: any) {
            return http.doGet('admin/piggybank/' + itemId);
        },

        create(
            cloneId: any,
            name: any,
            packageId: any,
            price: any,
            value: any,
            baseLog: any,
            fromAvgPayment: any,
            toAvgPayment: any,
            adsBoost: any,
            adsBoostPercent: any,
            adsBoostMin: any,
            adsBoostSeconds: any,
            image: any,
            thumbnail: any
        ) {
            return http.doPost(
                'admin/piggybank',
                {
                    cloneId: cloneId,
                    name: name,
                    packageId: packageId,
                    price: price,
                    value: value,
                    baseLog: baseLog,
                    fromAvgPayment: fromAvgPayment,
                    toAvgPayment: toAvgPayment,
                    adsBoost: adsBoost,
                    adsBoostPercent: adsBoostPercent,
                    adsBoostMin: adsBoostMin,
                    adsBoostSeconds: adsBoostSeconds
                },
                {
                    image: image,
                    thumbnail: thumbnail
                }
            );
        },

        update(
            itemId: any,
            name: any,
            packageId: any,
            price: any,
            value: any,
            baseLog: any,
            fromAvgPayment: any,
            toAvgPayment: any,
            adsBoost: any,
            adsBoostPercent: any,
            adsBoostMin: any,
            adsBoostSeconds: any,
            image: any,
            thumbnail: any
        ) {
            return http.doPost(
                'admin/piggybank/' + itemId,
                {
                    name: name,
                    packageId: packageId,
                    price: price,
                    value: value,
                    baseLog: baseLog,
                    fromAvgPayment: fromAvgPayment,
                    toAvgPayment: toAvgPayment,
                    adsBoost: adsBoost,
                    adsBoostPercent: adsBoostPercent,
                    adsBoostMin: adsBoostMin,
                    adsBoostSeconds: adsBoostSeconds
                },
                {
                    image: image,
                    thumbnail: thumbnail
                }
            );
        },

        remove(itemId: any) {
            return http.doDelete('admin/piggybank/' + itemId);
        },

        enable(itemId: any) {
            return http.doPut('admin/piggybank/' + itemId + '/enable');
        },

        disable(itemId: any) {
            return http.doPut('admin/piggybank/' + itemId + '/disable');
        }
    },

    text: {
        get(itemId: any, locale: any) {
            return http.doGet('admin/piggybank/' + itemId + '/text/' + locale);
        },

        update(itemId: any, locale: any, title: any, description: any) {
            return http.doPut('admin/piggybank/' + itemId + '/text/' + locale, {
                title: title,
                description: description
            });
        },

        remove(itemId: any, locale: any) {
            return http.doDelete('admin/piggybank/' + itemId + '/text/' + locale);
        }
    },

    boost: {
        get() {
            return http.doGet('admin/piggybank/boost');
        },

        update(startTime: any, endTime: any, ratio: any, image: any, thumbnail: any) {
            return http.doPost(
                'admin/piggybank/boost',
                {
                    startTime: startTime,
                    endTime: endTime,
                    ratio: ratio
                },
                {
                    image: image,
                    thumbnail: thumbnail
                }
            );
        },

        updateImage(image: any, thumbnail: any) {
            return http.doPost(
                'admin/piggybank/boost/image',
                {},
                {
                    image: image,
                    thumbnail: thumbnail
                }
            );
        },

        enable() {
            return http.doPut('admin/piggybank/boost/enable');
        },

        disable() {
            return http.doPut('admin/piggybank/boost/disable');
        },

        remove() {
            return http.doDelete('admin/piggybank/boost');
        }
    }
};
