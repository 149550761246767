
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder } from '@/components';
import { useArenaMedalEdit } from '@/modules/arena/medal/model/edit';

export default defineComponent({
    name: 'ArenaMedalEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions } = useArenaMedalEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, fetch, actions };
    }
});
