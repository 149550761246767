
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'AppDropdownMenu',
    props: {
        size: { type: String, default: 'md' }
    },
    setup() {
        const dropdownMenuElement = ref<HTMLElement>(null);

        const fixStyles = computed(() => {
            if (dropdownMenuElement.value) {
                const windowWidth = document.getElementById('app').offsetWidth;
                const offsetLeft = dropdownMenuElement.value.getBoundingClientRect().left;
                const offsetWidth = dropdownMenuElement.value.offsetWidth;
                const visiblePart = windowWidth - offsetLeft;

                if (offsetLeft < 0) {
                    return {
                        left: 'inherit',
                        right: `${offsetLeft - 5}px`
                    };
                } else if (visiblePart < offsetWidth) {
                    return { left: 'inherit', right: `0px` };
                }
                return { left: 'inherit', right: `0px` };
            }
            return { left: 'inherit', right: `0px` };
        });

        return { dropdownMenuElement, fixStyles };
    }
});
