import { useVDialog } from '@/plugins/dialog/v-dialog';
import { appleService } from '@/services/apple';
import useVuelidate from '@vuelidate/core';
import { numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useApple() {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        appStoreId: null,
        appBundleId: '',
        appSharedSecret: ''
    });

    const validations = {
        appStoreId: { required, numeric },
        appBundleId: { required },
        appSharedSecret: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await appleService().get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.appStoreId = result.appStoreId;
            model.appBundleId = result.appBundleId;
            model.appSharedSecret = result.appSharedSecret;
        } else if (response.code === 404) {
            model.isNew = true;
            model.appStoreId = null;
            model.appBundleId = '';
            model.appSharedSecret = '';
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await appleService().update(model.appStoreId, model.appBundleId, model.appSharedSecret);
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await appleService().remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
