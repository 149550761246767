
import { defineComponent, computed } from 'vue';
import * as bootstrap from 'admin-lte/plugins/bootstrap/js/bootstrap.min.js';

export default defineComponent({
    name: 'app-button',
    props: {
        icon: { type: String },
        block: { type: String, default: '' },
        theme: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        name: { type: String, default: '' },
        size: { type: String, default: 'md' },
        tooltip: { type: String, default: '' }
    },

    setup(props) {
        const getIcon = computed(() => {
            return `fas ${props.icon}`;
        });
        const getTheme = computed(() => {
            return `btn-${props.theme}`;
        });
        const getSize = computed(() => {
            return `btn-${props.size}`;
        });
        const isDisabled = computed(() => {
            return props.loading || props.disabled;
        });

        return { getIcon, getTheme, getSize, isDisabled };
    },

    mounted() {
        if (this.tooltip?.length) {
            new bootstrap.Tooltip(this.$el, {
                trigger: 'hover'
            });

            this.$el.addEventListener('click', () => {
                document.querySelectorAll('.tooltip').forEach(el => el.remove());
            });
        }
    }
});
