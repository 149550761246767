import { avatarService } from '@/services/avatar';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarColorAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        color: ''
    });

    const validations = {
        name: { required },
        color: { required, format: validateFormat(/^[0-9A-Fa-f]{6}$/) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.color.create(model.name, model.color);
        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'SysAvatarColorList' });
        }
    };

    return { model, v$, create };
}
