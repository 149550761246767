import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import useVuelidate from '@vuelidate/core';
import { integer, minValue } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useGameReport() {
    const dialog = useVDialog();
    const toast = useToast();

    const model = reactive({
        warningThreshold: 0,
        penaltyThreshold: 0,
        penaltyGame: 0,
        canRemove: false
    });

    const validations = {
        warningThreshold: { integer, minValue: minValue(0) },
        penaltyThreshold: { integer, minValue: minValue(0) },
        penaltyGame: { integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await gameService.report.get();
        if (response.code === 200) {
            const result = response.data;
            model.warningThreshold = result.warningThreshold;
            model.penaltyThreshold = result.penaltyThreshold;
            model.penaltyGame = result.penaltyGame;
            model.canRemove = true;
        } else {
            model.warningThreshold = 0;
            model.penaltyThreshold = 0;
            model.penaltyGame = 0;
            model.canRemove = false;
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await gameService.report.update(
                model.warningThreshold,
                model.penaltyThreshold,
                model.penaltyGame
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.report.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                            v$.value.$reset();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
