
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useQuestBoostEdit } from '@/modules/quest/boost/model/edit';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'QuestBoostEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetch, actions, iapOptions } = useQuestBoostEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, iapOptions, timeUnits };
    }
});
