
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useArenaTicketList } from '@/modules/arena/ticket/model/index';

export default defineComponent({
    name: 'ArenaTicketList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions, routes } = useArenaTicketList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Type', display: (row: any) => renderType(row.type) },
                { label: 'Name', field: 'name' },
                { label: 'Actions', field: 'type' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-shipping-fast',
                        theme: 'info',
                        tooltip: 'Add special shop',
                        onClick: () => routes.specialShop(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-tags',
                        theme: 'info',
                        tooltip: 'Add promo code',
                        onClick: () => routes.promoCode(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.type)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.type)
                    }),
                    group: 1
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, routes };
    }
});

const typeList = [
    { label: 'Standard', value: 1, background: 'info' },
    { label: 'Premium', value: 2, background: 'success' }
];

function renderType(type: any) {
    const data = typeList.find(item => item.value === type);
    return `<span class='badge badge-${data?.background}'>${data?.label}</span>`;
}
