
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppAvatarBreadcrumb, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAvatarImageList } from '@/modules/avatar/item/image/model/list';

export default defineComponent({
    name: 'AvatarImage',
    components: { AppTable, AppCard, AppButton, AppAvatarBreadcrumb },
    setup() {
        const { itemList, total, currentPage, beforeFetch, fetchImages, avatarItem, actions, routes } =
            useAvatarImageList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Color', field: 'colorName' },
                { label: 'Layer', field: 'layerName' },
                { label: 'Is default', display: (row: any) => renderOptionColumn(row.isDefault) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.editImage(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await beforeFetch();
            await fetchImages(currentPage.value);
        });

        return { table, fetchImages, avatarItem, routes, actions };
    }
});

const booleanList = [
    { label: 'Yes', value: true, background: 'success' },
    { label: 'No', value: false, background: 'secondary' }
];

function renderOptionColumn(value: any) {
    const data = booleanList.find(item => item.value == value);
    return `<span class='badge badge-${data?.background}'>${data?.label ?? ''}</span>`;
}
