
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useLeagueRewardEdit } from '@/modules/league/tier/reward/model/edit';

export default defineComponent({
    name: 'LeagueRewardEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, update } = useLeagueRewardEdit();

        onMounted(() => fetch());

        return { v$, model, fetch, update };
    }
});
