
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTicketAdd } from '@/modules/scratch/ticket/model/add';

export default defineComponent({
    name: 'ScratchAdd',
    components: { AppFormRow, AppInput, AppSelect, AppDateTimePicker, AppButton, AppCard },
    setup() {
        const { model, v$, fetch, timeUnits, actions } = useTicketAdd();

        onMounted(() => {
            fetch();
        });

        return { model, v$, types, timeUnits, actions };
    }
});

export const types = [
    { value: 1, label: 'Silver' },
    { value: 2, label: 'Golden' }
];
