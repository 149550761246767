import { useVDialog } from '@/plugins/dialog/v-dialog';
import { progressService } from '@/services/progress';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useProgressList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const progressStore = {
        getPage: () => store.getters['progress/page'],
        setPage: (page: number) => store.dispatch('progress/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(progressService.item.list, {
        pagingStore: progressStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await progressService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await progressService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await progressService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ProgressAdd' }),
        clone: (id: any) => router.push({ name: 'ProgressClone', params: { id: id } }),
        edit: (id: any) => router.push({ name: 'ProgressEdit', params: { id: id } }),
        level: (id: any) => router.push({ name: 'ProgressLevelList', params: { progressId: id } })
    };

    return { itemList, total, currentPage, routes, changePage, actions };
}
