
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useTransactionEdit } from '@/modules/transaction/text/model/edit';

export default defineComponent({
    name: 'TransactionEdit',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const route = useRoute();

        const { model, fetch, update } = useTransactionEdit();

        onMounted(async () => {
            await fetch(route.params.code, route.params.locale);
        });

        return { model, update };
    }
});
