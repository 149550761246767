import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarDefaultList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarDefaultStore = {
        getPage: () => store.getters['avatarDefault/page'],
        setPage: (page: number) => store.dispatch('avatarDefault/changePage', page),
        getFilter: (): { type: string } => store.getters['avatarDefault/filter'],
        setFilter: () => store.dispatch('avatarDefault/changeFilter', model.filter)
    };

    const model = reactive({
        filter: avatarDefaultStore.getFilter() as { type: string }
    });

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.default.list, {
        pagingStore: avatarDefaultStore,
        options: toRef(model, 'filter'),
        onSuccess: () => avatarDefaultStore.setFilter()
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.default.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            model.filter = avatarDefaultStore.getFilter();
                            await changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarDefaultAdd' }),
        edit: (id: any) => router.push({ name: 'SysAvatarDefaultEdit', params: { avatarId: id } })
    };

    return { model, itemList, total, currentPage, changePage, actions, routes };
}
