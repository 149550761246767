import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/slot/item');
};

const update = (
    price: any,
    notificationValue: any,
    jackpotPrize: any,
    jackpotDonationPercent: any,
    jackpotWinPercent: any
) => {
    return http.doPut('/admin/slot/item', {
        price: price,
        notificationValue: notificationValue,
        jackpotPrize: jackpotPrize,
        jackpotDonationPercent: jackpotDonationPercent,
        jackpotWinPercent: jackpotWinPercent
    });
};

const remove = () => {
    return http.doDelete('/admin/slot/item');
};

export function slotService() {
    return { get, remove, update };
}
