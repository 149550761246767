
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppCard, AppButton, AppDateTimePicker } from '@/components';
import { useMaintenance } from '@/modules/maintenance/maintenance';

export default defineComponent({
    name: 'SystemMaintenance',
    components: { AppFormRow, AppCard, AppButton, AppDateTimePicker },
    setup() {
        const { model, v$, fetch, actions } = useMaintenance();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions };
    }
});
