import { useVDialog } from '@/plugins/dialog/v-dialog';
import { facebookService } from '@/services/facebook';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useFacebook() {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        fbAppId: null,
        name: null,
        secret: null,
        accessToken: null,
        hasBusiness: false
    });

    const validations = {
        fbAppId: { required },
        name: { required },
        secret: { required },
        accessToken: { required },
        hasBusiness: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await facebookService().get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.fbAppId = result.fbAppId;
            model.name = result.name;
            model.secret = result.secret;
            model.accessToken = result.accessToken;
            model.hasBusiness = result.hasBusiness;
        } else if (response.code === 404) {
            model.isNew = true;
            model.fbAppId = null;
            model.name = null;
            model.secret = null;
            model.accessToken = null;
            model.hasBusiness = false;
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await facebookService().update(
                model.fbAppId,
                model.name,
                model.secret,
                model.accessToken,
                model.hasBusiness
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await facebookService().remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
