
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import useBadword from '@/modules/badword/model/edit';

export default defineComponent({
    name: 'BadWord',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { v$, model, localeSupportedOptions, beforeFetch, fetch, actions } = useBadword();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, localeSupportedOptions, fetch, actions };
    }
});
