
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { useTranslateText } from '@/modules/translation/model/translate';

export default defineComponent({
    name: 'TranslateText',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { v$, model, localeSupportedOptions, fetch, beforeFetch, actions } = useTranslateText();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, localeSupportedOptions, fetch, actions };
    }
});
