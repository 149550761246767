
import { defineComponent, onMounted } from 'vue';
import { AppCard } from '@/components';
import { useActivityDetail } from '@/modules/activity/model/detail';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'ActivityDetail',
    components: { AppCard },
    setup() {
        const { model, fetch } = useActivityDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model, formatDate, tasks };
    }
});

const tasks = [
    { label: 'Create', value: 1 },
    { label: 'Update', value: 2 },
    { label: 'Delete', value: 3 },
    { label: 'Add', value: 4 },
    { label: 'Remove', value: 5 }
];
