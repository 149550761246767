import { useVDialog } from '@/plugins/dialog/v-dialog';
import { newsService } from '@/services/news';
import { useFetchList } from '@/utils/useFetch';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useMixins } from './mixins';

export function useNewsList() {
    const dialog = useVDialog();
    const toast = useToast();
    const store = useStore();
    const newsStore = {
        getPage: () => store.getters['news/page'],
        setPage: (page: number) => store.dispatch('news/changePage', page)
    };

    const { routes } = useMixins();

    const { itemList, total, currentPage, changePage } = useFetchList(newsService.list, {
        pagingStore: newsStore
    });

    const actions = {
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await newsService.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await newsService.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        },
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await newsService.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    return { itemList, total, currentPage, options, routes, changePage, actions };
}

const options = {
    statusList: [
        { label: 'None', value: 0, background: 'info' },
        { label: 'Enabled', value: 1, background: 'success' },
        { label: 'Published', value: 2, background: 'warning' },
        { label: 'Started', value: 3, background: 'info' },
        { label: 'Ended', value: 4, background: 'success' },
        { label: 'Unpublished', value: 5, background: 'warning' },
        { label: 'Disabled', value: 6, background: 'info' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        return `<span class='badge badge-${type.background}'>${type.label}</span>`;
    }
};
