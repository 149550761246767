import http from '@/utils/axios';

export const questService = {
    type: {
        list() {
            return http.doGet('admin/quest/type');
        },

        get(typeId: any) {
            return http.doGet('admin/quest/type/' + typeId);
        },

        create(
            name: any,
            displayType: any,
            timeUnit: any,
            timeDuration: any,
            openCycle: any,
            restCycle: any,
            startTime: any
        ) {
            return http.doPost('admin/quest/type', {
                name: name,
                displayType: displayType,
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                openCycle: openCycle,
                restCycle: restCycle,
                startTime: startTime
            });
        },

        remove(typeId: any) {
            return http.doDelete('admin/quest/type/' + typeId);
        },

        enableNext(typeId: any) {
            return http.doPut('admin/quest/type/' + typeId + '/enable/next');
        },

        disable(typeId: any) {
            return http.doPut('admin/quest/type/' + typeId + '/disable');
        },

        disableNext(typeId: any) {
            return http.doPut('admin/quest/type/' + typeId + '/disable/next');
        }
    },

    item: {
        list(paging: any, typeId: any) {
            return http.doGet('admin/quest/item', {
                typeId: typeId
            });
        },

        get(itemId: any) {
            return http.doGet('admin/quest/item/' + itemId);
        },

        create(
            typeId: any,
            name: any,
            game: any,
            fromAvgGame: any,
            toAvgGame: any,
            fromGameBet: any,
            toGameBet: any,
            gameType: any,
            reward: any,
            adsBoost: any,
            adsBoostRate: any
        ) {
            return http.doPost('admin/quest/item', {
                typeId: typeId,
                name: name,
                game: game,
                fromAvgGame: fromAvgGame,
                toAvgGame: toAvgGame,
                fromGameBet: fromGameBet,
                toGameBet: toGameBet,
                gameType: gameType,
                reward: reward,
                adsBoost: adsBoost,
                adsBoostRate: adsBoostRate
            });
        },

        update(itemId: any, name: any, game: any, reward: any, adsBoost: any, adsBoostRate: any) {
            return http.doPut('admin/quest/item/' + itemId, {
                name: name,
                game: game,
                reward: reward,
                adsBoost: adsBoost,
                adsBoostRate: adsBoostRate
            });
        },

        updateTime(itemId: any, startTime: any, endTime: any) {
            return http.doPut('admin/quest/item/' + itemId + '/time', {
                startTime: startTime,
                endTime: endTime
            });
        },

        remove(itemId: any) {
            return http.doDelete('admin/quest/item/' + itemId);
        },

        enable(itemId: any) {
            return http.doPut('admin/quest/item/' + itemId + '/enable');
        },

        enableNext(itemId: any) {
            return http.doPut('admin/quest/item/' + itemId + '/enable/next');
        },

        disable(itemId: any) {
            return http.doPut('admin/quest/item/' + itemId + '/disable');
        },

        disableNext(itemId: any) {
            return http.doPut('admin/quest/item/' + itemId + '/disable/next');
        },

        getText(itemId: any, locale: any) {
            return http.doGet('admin/quest/item/' + itemId + '/text/' + locale);
        },

        updateText(itemId: any, locale: any, title: any) {
            return http.doPut('admin/quest/item/' + itemId + '/text/' + locale, {
                title: title
            });
        },

        removeText(itemId: any, locale: any) {
            return http.doDelete('admin/quest/item/' + itemId + '/text/' + locale);
        }
    },

    boost: {
        list() {
            return http.doGet('admin/quest/boost');
        },

        get(itemId: any) {
            return http.doGet('admin/quest/boost/' + itemId);
        },

        create(packageId: any, price: any, ratio: any, timeUnit: any, timeDuration: any) {
            return http.doPost('admin/quest/boost', {
                packageId: packageId,
                price: price,
                ratio: ratio,
                timeUnit: timeUnit,
                timeDuration: timeDuration
            });
        },

        update(itemId: any, packageId: any, price: any, ratio: any, timeUnit: any, timeDuration: any) {
            return http.doPut('admin/quest/boost/' + itemId, {
                packageId: packageId,
                price: price,
                ratio: ratio,
                timeUnit: timeUnit,
                timeDuration: timeDuration
            });
        },

        remove(itemId: any) {
            return http.doDelete('admin/quest/boost/' + itemId);
        },

        enable(itemId: any) {
            return http.doPut('admin/quest/boost/' + itemId + '/enable');
        },

        disable(itemId: any) {
            return http.doPut('admin/quest/boost/' + itemId + '/disable');
        },

        getText(itemId: any, locale: any) {
            return http.doGet('admin/quest/boost/' + itemId + '/text/' + locale);
        },

        updateText(itemId: any, locale: any, title: any, description: any) {
            return http.doPut('admin/quest/boost/' + itemId + '/text/' + locale, {
                title: title,
                description: description
            });
        },

        removeText(itemId: any, locale: any) {
            return http.doDelete('admin/quest/boost/' + itemId + '/text/' + locale);
        }
    }
};
