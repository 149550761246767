import { newsService } from '@/services/news';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useMixins() {
    const toast = useToast();
    const router = useRouter();

    const model = reactive({
        id: null,
        cloneId: null,
        forAppId: null,
        name: '',
        targetType: -1,
        target: '',
        startTime: null,
        endTime: null,
        publishTime: null,
        unpublishTime: null,
        fromRegisterTime: null,
        toRegisterTime: null,
        image: null,
        imageFile: null,
        thumbnail: null,
        thumbnailFile: null
    });

    const updateContentValidation = {
        name: { required },
        targetType: { required },
        imageFile: {},
        thumbnailFile: {},
        target: {
            requiredIf: requiredIf(() => model.targetType >= 0),
            urlIf: helpers.withMessage(
                'The value is not a valid URL address',
                (value: string, vm: any) =>
                    vm.targetType != 0 ||
                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
                        value
                    )
            )
        },
        toRegisterTime: {
            requiredIf: requiredIf(() => model.fromRegisterTime),
            validateDateAfter: validateDateAfter('fromRegisterTime')
        }
    };

    const validations = {
        ...updateContentValidation,
        publishTime: { required },
        startTime: { validateDateAfter: validateDateAfter() },
        endTime: { requiredIf: requiredIf(() => model.startTime), validateDateAfter: validateDateAfter('startTime') },
        unpublishTime: { required, validateDateAfter: validateDateAfter('endTime') }
    };

    const fetch = async (id: any) => {
        if (!id) {
            return;
        }

        const response = await newsService.get(id);
        if (response.code == 200) {
            const data = response.data;
            model.id = data.id;
            model.name = data.name;
            model.targetType = data.targetType;
            model.target = data.target;
            model.image = data.image;
            model.thumbnail = data.thumbnail;
            model.startTime = data?.startTime ? new Date(data.startTime) : null;
            model.endTime = data?.endTime ? new Date(data.endTime) : null;
            model.publishTime = new Date(data.publishTime);
            model.unpublishTime = new Date(data.unpublishTime);
            model.fromRegisterTime = data?.fromRegisterTime ? new Date(data.fromRegisterTime) : null;
            model.toRegisterTime = data?.toRegisterTime ? new Date(data.toRegisterTime) : null;

            if (data.target) {
                if (data.target.startsWith('link:')) {
                    model.target = data.target.replace(/link:/, '');
                    model.targetType = 0;
                } else if (data.target.startsWith('popup:')) {
                    model.target = data.target.replace(/popup:/, '');
                    model.targetType = 1;
                } else if (data.target.startsWith('deeplink:')) {
                    model.target = data.target.replace(/deeplink:/, '');
                    model.targetType = 2;
                } else {
                    model.targetType = -1;
                }
            } else {
                model.targetType = -1;
            }
        } else {
            toast.error(response.data);
            router.push({ name: 'Home' });
        }
    };

    const routes = {
        add: () => router.push({ name: 'NewsAdd' }),
        edit: (id: any) => router.push({ name: 'NewsEdit', params: { id: id } }),
        clone: (id: any) => router.push({ name: 'NewsClone', params: { id: id } }),
        content: (id: any) => router.push({ name: 'NewsContent', params: { id: id } }),
        translate: (id: any) => router.push({ name: 'NewsTranslate', params: { id: id } })
    };

    return { model, validations, updateContentValidation, routes, options, fetch };
}

const options = {
    types: [
        { label: 'None', value: -1, background: 'badge-info' },
        { label: 'Links', value: 0, background: 'badge-success' },
        { label: 'Popup', value: 1, background: 'badge-primary' },
        { label: 'Deep', value: 2, background: 'badge-warning' }
    ],
    targets: [
        { label: 'DailyBonus', value: 'DailyBonus' },
        { label: 'Shop', value: 'Shop' },
        { label: 'StartTotem', value: 'StartTotem' },
        { label: 'Tutorial', value: 'Tutorial' },
        { label: 'ChangePassword', value: 'ChangePassword' },
        { label: 'NewVersion', value: 'NewVersion' },
        { label: 'Rating', value: 'Rating' },
        { label: 'AvatarShop', value: 'AvatarShop' },
        { label: 'Arena', value: 'Arena' },
        { label: 'Messenger', value: 'Messenger' },
        { label: 'Inbox', value: 'Inbox' },
        { label: 'League', value: 'League' },
        { label: 'Quest', value: 'Quest' },
        { label: 'Minigames', value: 'Minigames' },
        { label: 'Challenge', value: 'Challenge' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        return `<span class='badge ${type?.background ?? ''}'>${type?.label ?? ''}</span>`;
    }
};
