import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canAddNewItem)
      ? (_openBlock(), _createBlock(_component_app_card, {
          key: 0,
          label: "Function",
          classes: "pb-0"
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_app_button, {
                name: "Change config",
                icon: "fa-edit",
                theme: "info",
                size: "md",
                onClick: _ctx.routes.add
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          "is-slot-mode": true,
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          page: Number.MAX_SAFE_INTEGER,
          actions: _ctx.table.actions
        }, null, 8, ["columns", "rows", "total", "page", "actions"])
      ]),
      _: 1
    })
  ], 64))
}