
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useLeagueEdit } from '@/modules/league/model/edit';

export default defineComponent({
    name: 'LeagueEdit',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, v$, fetch, actions, routes, timeUnits } = useLeagueEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, routes, timeUnits };
    }
});
