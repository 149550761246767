import { creditService } from '@/services/credit';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useCreditTicketAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        amount: 0
    });

    const validations = {
        name: { required },
        amount: { required, numeric, min: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await creditService.ticket.create(model.name, model.amount);
        if (response.code === 200) {
            toast.success('Create successfully', { timeout: 2000 });
            router.push({ name: 'CreditTicketList' });
        } else {
            toast.error(response.data, { timeout: 2000 });
        }
    };

    return { model, v$, create };
}
