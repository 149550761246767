import { useVDialog } from '@/plugins/dialog/v-dialog';
import { questService } from '@/services/quest';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestBoostList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(questService.boost.list, null);

    const actions = {
        disable: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.disable(typeId);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.enable(typeId);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: (typeId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await questService.boost.remove(typeId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'QuestBoostAdd' }),
        edit: (id: any) => router.push({ name: 'QuestBoostEdit', params: { boostId: id } }),
        translate: (id: any) => router.push({ name: 'QuestBoostTranslate', params: { boostId: id } }),
        specialShop: (id: any) => router.push({ name: 'SystemItemAdd', query: { data: id, feature: 3 } })
    };

    return { itemList, total, fetchList, actions, routes };
}
