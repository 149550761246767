
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload } from '@/components';
import { useTournamentMedalAdd } from '@/modules/tournament/medal/model/add';

export default defineComponent({
    name: 'TournamentMedalAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload },
    setup() {
        const { model, v$, create } = useTournamentMedalAdd();

        return { v$, model, create };
    }
});
