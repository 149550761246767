import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarBackgroundList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarBackgroundStore = {
        getPage: () => store.getters['avatarBackground/page'],
        setPage: (page: number) => store.dispatch('avatarBackground/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.background.list, {
        pagingStore: avatarBackgroundStore
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.background.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarBackgroundAdd' }),
        edit: (id: any) => router.push({ name: 'SysAvatarBackgroundEdit', params: { id: id } })
    };

    return { itemList, total, currentPage, changePage, actions, routes };
}
