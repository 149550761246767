
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useCreditTicketAdd } from '@/modules/credit/ticket/model/add';

export default defineComponent({
    name: 'CreditTicketAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, create } = useCreditTicketAdd();

        return { v$, model, create };
    }
});
