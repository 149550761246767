
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppDateTimePicker } from '@/components';
import { usePromotionCodeAdd } from '@/modules/promotion/code/model/add';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'PromotionCodeAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppDateTimePicker },
    setup() {
        const route = useRoute();
        const { model, v$, options, create } = usePromotionCodeAdd();

        const getDataOption = () => {
            if (model.feature == 2) {
                return options.arenas;
            }
            if (model.feature == 5) {
                return options.scratchTickets;
            }

            return [{ label: model.data, value: model.data }];
        };

        onMounted(async () => {
            model.feature = route.query?.feature ? Number(route.query?.feature) : null;
            model.data = route.query?.data ? Number(route.query?.data) : null;
            model.dataOption = getDataOption();
        });

        return { model, v$, options, getDataOption, create };
    }
});
