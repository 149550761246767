import { messageService } from '@/services/message';
import { validateFormat } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const options = {
    messageTypes: [
        { value: 0, label: 'No Message' },
        { value: 1, label: 'Promotion' },
        { value: 2, label: 'Maintenance' },
        { value: 3, label: 'Disconnected' },
        { value: 4, label: 'Reward' },
        { value: 5, label: 'Facebook' },
        { value: 6, label: 'Purchase' },
        { value: 7, label: 'Quest' },
        { value: 8, label: 'Challenge' }
    ],
    creditTypes: [
        { value: 0, label: 'Chip' },
        { value: 1, label: 'Ruby' }
    ]
};

export default function () {
    const route = useRoute();
    const toast = useToast();
    const model = reactive({
        accountIds: (route.query?.accountIds ?? '') as string,
        amount: 0,
        messageType: 0,
        creditType: 0
    });

    const validations = {
        accountIds: { required, format: validateFormat(/^[1-9][0-9]*(,[1-9][0-9]*)*$/) },
        amount: { required, integer }
    };

    const v$ = useVuelidate(validations, model);

    const send = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        let response;
        if (model.messageType === 0) {
            response = await messageService.add(model.accountIds, model.creditType, model.amount);
        } else {
            response = await messageService.send(model.messageType, model.accountIds, model.creditType, model.amount);
        }

        if (response.code === 200) {
            toast.success('Send successfully', { timeout: 2000 });
            v$.value.$reset();
            model.accountIds = '';
            model.amount = 0;
            model.messageType = 0;
            model.creditType = 0;
        }
    };

    return { model, v$, options, send };
}
