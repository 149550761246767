export function useMixins() {
    return { selectableValues };
}

const selectableValues = {
    types: [
        { value: 1, label: 'SingleJoin', background: 'badge-info' },
        { value: 2, label: 'RecurringJoin', background: 'badge-info' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        if (type) {
            return `<span class='badge ${type.background}'>${type.label}</span>`;
        }
        return '';
    }
};
