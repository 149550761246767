import http from '@/utils/axios';

export const translationService = {
    list(paging: any, options: any) {
        return http.doGet('admin/translation', {
            ...options,
            ...paging
        });
    },

    create(name: any) {
        return http.doPost('admin/translation', {
            name: name
        });
    },

    update(id: string, name: any) {
        return http.doPut('admin/translation/' + id, {
            name: name
        });
    },

    remove(id: string) {
        return http.doDelete('admin/translation/' + id);
    },

    get(id: string) {
        return http.doGet('admin/translation/' + id);
    },

    updateContent(id: string, locale: string, content: any) {
        return http.doPut('admin/translation/' + id + '/' + locale, {
            content: content
        });
    },

    getContent(id: string, locale: string) {
        return http.doGet('admin/translation/' + id + '/' + locale);
    },

    removeContent(id: string, locale: string) {
        return http.doDelete('admin/translation/' + id + '/' + locale);
    },

    export() {
        return http.doGet('admin/translation/export');
    },

    import(file: any) {
        return http.doPost(
            'admin/translation/import',
            {},
            {
                file: file
            }
        );
    }
};
