
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import { useSysRoleAdd } from '@/modules/role/system/model/add';

export default defineComponent({
    name: 'SysRoleEdit',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard },
    setup() {
        const { model, v$, actions, roleOption } = useSysRoleAdd();

        return { v$, model, actions, roleOption };
    }
});
