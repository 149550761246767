import { useVDialog } from '@/plugins/dialog/v-dialog';
import { itemService } from '@/services/item';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useItemSettingList() {
    const toast = useToast();
    const router = useRouter();
    const dialog = useVDialog();

    const itemList = ref([]);
    const total = ref(0);
    const canAddNewItem = ref(false);

    const fetch = async () => {
        const response = await itemService.setting.list();
        if (response.code === 200) {
            canAddNewItem.value = true;
            const now = new Date().getTime();

            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].startTime > now) {
                    // already have next item
                    canAddNewItem.value = false;
                    break;
                }
            }

            itemList.value = response.data;
            total.value = itemList.value.length;
        } else {
            toast.error(response.data);
        }
    };

    const actions = {
        remove: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await itemService.setting.remove(itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'AppItemSettingAdd' }),
        edit: (id: any) => router.push({ name: 'AppItemSettingEdit', params: { id: id } })
    };

    return { itemList, total, canAddNewItem, fetch, actions, routes };
}
