import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Link Application"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Feature" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.model.linkableApps,
              modelValue: _ctx.model.selectedAppId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.selectedAppId) = $event)),
              modelModifiers: { number: true },
              onChange: _ctx.fetch
            }, null, 8, ["options", "modelValue", "onChange"])
          ]),
          _: 1
        }),
        (_ctx.model.selectedAppId)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_form_row, { label: "Start Time" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.startTime,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.startTime) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "End Time" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.endTime,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.endTime) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.model.canLink)
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 0,
                name: "Link",
                theme: "success",
                icon: "fa-link",
                onClick: _withModifiers(_ctx.actions.link, ["prevent"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.model.canUnlink)
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 1,
                name: "Unlink",
                theme: "warning",
                icon: "fa-unlink",
                onClick: _withModifiers(_ctx.actions.unlink, ["prevent"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.model.canLinkNext)
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 2,
                name: "Link in next cycle",
                theme: "warning",
                icon: "fa-link",
                onClick: _withModifiers(_ctx.actions.linkNext, ["prevent"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.model.canUnlinkNext)
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 3,
                name: "Unlink in next cycle",
                theme: "danger",
                icon: "fa-unlink",
                onClick: _withModifiers(_ctx.actions.unlinkNext, ["prevent"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}