import { activityService } from '@/services/activity';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { minLength, numeric } from '@vuelidate/validators';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useActivity() {
    const store = useStore();
    const router = useRouter();

    const activityStore = {
        getPage: () => store.getters['activity/page'],
        setPage: (page: number) => store.dispatch('activity/changePage', page),
        getFilter: () => store.getters['activity/filter'],
        changeFilter: () => store.dispatch('activity/changeFilter', { ...filter.value })
    };

    const storedFilter = activityStore.getFilter();
    const filter = ref({
        accountId: storedFilter?.accountId ?? null,
        feature: storedFilter?.feature ?? null,
        action: storedFilter?.action ?? 0
    });

    const validations = {
        feature: { min: minLength(3) },
        accountId: { numeric }
    };

    const v$ = useVuelidate(validations, filter);

    const { itemList, total, currentPage, changePage } = useFetchList(activityService.app.list, {
        pagingStore: activityStore,
        options: filter,
        onSuccess: () => activityStore.changeFilter()
    });

    const fetchList = async (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        await changePage(page);
    };

    const routes = {
        detail: (id: any) => router.push({ name: 'ActivityDetail', params: { id: id } })
    };

    return { filter, v$, itemList, total, currentPage, fetchList, routes, tasks };
}

const tasks = [
    { label: 'All', value: 0 },
    { label: 'Create', value: 1 },
    { label: 'Update', value: 2 },
    { label: 'Delete', value: 3 },
    { label: 'Add', value: 4 },
    { label: 'Remove', value: 5 }
];
