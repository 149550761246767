import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Create Ticket"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.types,
              modelValue: _ctx.model.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.type) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.type,
              onChange: _ctx.actions.changeType
            }, null, 8, ["options", "modelValue", "validations", "onChange"])
          ]),
          _: 1
        }),
        (_ctx.model.type == 1)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_form_row, { label: "Cost (pay with chips)" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.cost,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.cost) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.cost
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Ads View" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.adsView,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.adsView) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.adsView
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Free Limit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.freeLimit,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.freeLimit) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.freeLimit
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              })
            ], 64))
          : (_ctx.model.type == 2)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_app_form_row, { label: "IAP Item" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_select, {
                      options: _ctx.model.iapOptions,
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actions.selectIAP($event.target.value)))
                    }, null, 8, ["options"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_form_row, { label: "Package ID" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_input, {
                      modelValue: _ctx.model.packageId,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.packageId) = $event)),
                      validations: _ctx.v$.packageId
                    }, null, 8, ["modelValue", "validations"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_form_row, { label: "Price" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_input, {
                      type: "number",
                      modelValue: _ctx.model.price,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.price) = $event)),
                      modelModifiers: { number: true },
                      validations: _ctx.v$.price
                    }, null, 8, ["modelValue", "validations"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_form_row, { label: "Quantty" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_input, {
                      type: "number",
                      modelValue: _ctx.model.quantity,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.quantity) = $event)),
                      modelModifiers: { number: true },
                      validations: _ctx.v$.quantity
                    }, null, 8, ["modelValue", "validations"])
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Base Value" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.baseValue,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.baseValue) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.baseValue
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Payout Rate" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              placeholder: "X1:Y1,X2:Y2,X3:Y3... Ex: 0.3:10,1.5:15,4:100",
              modelValue: _ctx.model.payoutRate,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.payoutRate) = $event)),
              validations: _ctx.v$.payoutRate
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.type == 1)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 2,
              label: "Ads Payout Rate"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  placeholder: "X1:Y1,X2:Y2,X3:Y3... Ex: 0.3:10,1.5:15,4:100",
                  modelValue: _ctx.model.adsPayoutRate,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.adsPayoutRate) = $event)),
                  validations: _ctx.v$.adsPayoutRate
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Display Rate" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              placeholder: "Ex: 2,3,4,5,6 [in integer numbers]",
              modelValue: _ctx.model.displayRate,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.displayRate) = $event)),
              validations: _ctx.v$.displayRate
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.type == 1)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 3,
              label: "Time Unit"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_select, {
                  options: _ctx.timeUnits,
                  disabled: false,
                  modelValue: _ctx.model.timeUnit,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.timeUnit
                }, null, 8, ["options", "modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.model.type == 1)
          ? (_openBlock(), _createBlock(_component_app_form_row, {
              key: 4,
              label: "Time Duration"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_input, {
                  type: "number",
                  modelValue: _ctx.model.timeDuration,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
                  modelModifiers: { number: true },
                  validations: _ctx.v$.timeDuration
                }, null, 8, ["modelValue", "validations"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.startTime) = $event)),
              validations: _ctx.v$.startTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "End Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.endTime,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.endTime) = $event)),
              validations: _ctx.v$.endTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Save",
              icon: "fa-save",
              theme: "info",
              size: "md",
              onClick: _withModifiers(_ctx.actions.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}