import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["placeholder", "value", "autocomplete", "disabled", "validations"]
const _hoisted_3 = ["type", "placeholder", "value", "autocomplete", "disabled", "validations"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.type && _ctx.type === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 0,
            rows: "10",
            class: "form-control",
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            autocomplete: _ctx.autocomplete,
            disabled: _ctx.disabled,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event))),
            validations: _ctx.validations
          }, null, 40, _hoisted_2))
        : (_openBlock(), _createElementBlock("input", {
            key: 1,
            type: _ctx.type,
            class: "form-control",
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            autocomplete: _ctx.autocomplete,
            disabled: _ctx.disabled,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValueChange($event))),
            validations: _ctx.validations
          }, null, 40, _hoisted_3))
    ]),
    _createVNode(_component_app_error, { validations: _ctx.validations }, null, 8, ["validations"])
  ]))
}