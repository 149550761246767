import { useVDialog } from '@/plugins/dialog/v-dialog';
import { slotService } from '@/services/slot';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { integer, maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useSlot() {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        price: '',
        notificationValue: null,
        jackpotPrize: null,
        jackpotDonationPercent: 0,
        jackpotWinPercent: 0
    });

    const validations = {
        price: {
            required,
            format: validateFormat(/^\d+(,\d+)*$/, 'Please enter in the correct format: Ex: 2,3,5 [in integer numbers]')
        },
        notificationValue: { required, integer },
        jackpotPrize: { required, integer },
        jackpotDonationPercent: { required, integer, minValue: minValue(0), maxValue: maxValue(100) },
        jackpotWinPercent: { required, integer, minValue: minValue(0), maxValue: maxValue(100) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await slotService().get();
        if (response.code === 200) {
            model.isNew = false;
            Object.assign(model, response.data);
        } else if (response.code === 404) {
            model.isNew = true;
            model.price = '';
            model.notificationValue = null;
            model.jackpotPrize = null;
            model.jackpotDonationPercent = 0;
            model.jackpotWinPercent = 0;
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await slotService().update(
                model.price,
                model.notificationValue,
                model.jackpotPrize,
                model.jackpotDonationPercent,
                model.jackpotWinPercent
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await slotService().remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            v$.value.$reset();
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
