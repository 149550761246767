import http from '@/utils/axios';

const list = (offset: number, limit: number) => {
    return http.doGet('/admin/scratch/ticket', {
        offset: offset,
        limit: limit
    });
};

const get = (id: any) => {
    return http.doGet(`/admin/scratch/ticket/${id}`);
};

const update = (
    id: any,
    quantity: any,
    freeLimit: any,
    payoutRate: any,
    adsPayoutRate: any,
    displayRate: any,
    endTime: any
) => {
    return http.doPut(`/admin/scratch/ticket/${id}`, {
        quantity: quantity,
        freeLimit: freeLimit,
        payoutRate: payoutRate,
        adsPayoutRate: adsPayoutRate,
        displayRate: displayRate,
        endTime: endTime
    });
};

const remove = (id: any) => {
    return http.doDelete('admin/scratch/ticket/' + id);
};

const create = (
    type: any,
    packageId: any,
    price: any,
    cost: any,
    quantity: any,
    baseValue: any,
    payoutRate: any,
    adsPayoutRate: any,
    adsView: any,
    freeLimit: any,
    displayRate: any,
    timeUnit: any,
    timeDuration: any,
    startTime: any,
    endTime: any
) => {
    return http.doPost('/admin/scratch/ticket', {
        type: type,
        packageId: packageId,
        price: price,
        cost: cost,
        quantity: quantity,
        baseValue: baseValue,
        payoutRate: payoutRate,
        adsPayoutRate: adsPayoutRate,
        adsView: adsView,
        freeLimit: freeLimit,
        displayRate: displayRate,
        timeUnit: timeUnit,
        timeDuration: timeDuration,
        startTime: startTime,
        endTime: endTime
    });
};

export function scratchTicketService() {
    return { list, get, update, create, remove };
}
