import { helpers } from '@vuelidate/validators';

export const CURRENT_TIME_STAMP = 'CURRENT_TIME_STAMP';
export const INVALID_FORMAT_MESSAGE = 'Format incorrect';

// Todo: apply validatorHelpers for old pages
export const validateDate = () =>
    helpers.withMessage(`Value must be 'Date' type`, (value: any) => value instanceof Date);

export const validateAllowBlank = () => helpers.withMessage('', (value: any) => !value);

export const validateDateAfter = (beforeDateField: string = CURRENT_TIME_STAMP) => {
    return helpers.withParams(
        { beforeDateField },
        helpers.withMessage(`Value must be greater than ${beforeDateField}`, (value: Date, vm: any) => {
            const beforeDateValue = beforeDateField == CURRENT_TIME_STAMP ? new Date() : vm?.[beforeDateField];
            return !value || !beforeDateValue || value > beforeDateValue;
        })
    );
};

export const validateFormat = (regex: RegExp, message: string = INVALID_FORMAT_MESSAGE) =>
    // blank or matched regex
    helpers.withMessage(message, (value: string) => !value?.length || regex.test(value));
