
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import useIapClone from '@/modules/iap/model/clone';

export default defineComponent({
    name: 'IAPClone',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard },
    setup() {
        const route = useRoute();
        const { v$, model, features, fetch, create } = useIapClone();

        onMounted(() => {
            fetch(route.params.id);
        });

        return { v$, model, features, create };
    }
});
