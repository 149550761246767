
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton } from '@/components';
import { useGameVariantAdd } from '@/modules/game/bet/model/add';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'GameBetAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppSelect, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, beforeFetch, actions, options } = useGameVariantAdd();

        onMounted(async () => {
            await beforeFetch();
            model.variantId = route.query?.variantId ? Number(route.query?.variantId) : null;
        });
        return { model, v$, options, actions };
    }
});
