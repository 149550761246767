
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppSelect, AppCard } from '@/components';
import { useMailSetting } from '@/modules/mail/model/edit';

export default defineComponent({
    name: 'MailSetting',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard },
    setup() {
        const { model, v$, fetch, actions } = useMailSetting();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions, mailTypes };
    }
});

const mailTypes = [
    { label: 'Gmail', value: 0 },
    { label: 'Outlook', value: 1 }
];
