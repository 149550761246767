import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-466e0a84"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "badge badge-warning"
}
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.status === 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Processing"))
      : (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.path,
            alt: _ctx.alt,
            class: "img-fluid image-background",
            width: "100"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
  ]))
}