
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useProfile } from '@/modules/profile/model/edit';

export default defineComponent({
    name: 'Profile',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useProfile();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions };
    }
});
