
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSwitch } from '@/components';
import { useFacebook } from '@/modules/facebook/model/edit';

export default defineComponent({
    name: 'Facebook',
    components: { AppFormRow, AppInput, AppButton, AppCard, AppSwitch },
    setup() {
        const { model, v$, fetch, actions } = useFacebook();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions };
    }
});
