
import { defineComponent, onMounted } from 'vue';
import { AppCard, AppButton } from '@/components';
import { useDuplicateCycleDetail } from '@/modules/duplicate/cycle/model/detail';
import { formatDate, renderTimeUnit } from '@/utils/helpers';

export default defineComponent({
    name: 'Duplicate Detail',
    components: { AppCard, AppButton },
    setup() {
        const { model, routes, fetch, beforeFetch } = useDuplicateCycleDetail();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { model, routes, formatDate, renderTimeUnit, renderStatus };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Started', value: 1, background: 'success' },
    { label: 'Opened', value: 2, background: 'info' },
    { label: 'Closed', value: 3, background: 'warning' },
    { label: 'Ranked', value: 4, background: 'info' },
    { label: 'Archived', value: 5, background: 'success' },
    { label: 'Disabled', value: 6, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
