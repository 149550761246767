
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useSysItemList } from '@/modules/item/system/model/list';

export default defineComponent({
    name: 'SysItem',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const { model, v$, itemList, total, currentPage, fetchList, actions, routes, appList, featureList } =
            useSysItemList();

        const appNames = [] as Array<any>;
        appList.forEach(app => (appNames[app.value] = app.label));

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Name', field: 'name' },
                { label: 'Ruby', field: 'ruby' },
                { label: 'Feature', display: (row: any) => renderFeature(row.feature) },
                { label: 'Quantity', field: 'quantity' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'App (current cycle)', display: (row: any) => renderApp(row.curAppIds) },
                { label: 'App (next cycle)', display: (row: any) => renderApp(row.nextAppIds) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-link',
                        theme: 'info',
                        tooltip: 'Link',
                        onclick: () => routes.link(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        const renderFeature = (status: any) => {
            const type = featureList.find(item => item.value === status);
            return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
        };

        const renderApp = (appIds: Array<any>) => {
            return appIds.map((id: any) => appNames[id]).join(', ');
        };

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { model, v$, table, fetchList, routes, actions, appList, appNames, featureList };
    }
});
