import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/apple');
};

const remove = () => {
    return http.doDelete('/admin/apple');
};

const update = (appStoreId: string, appBundleId: string, appSharedSecret: string) => {
    return http.doPut('/admin/apple', {
        appStoreId: appStoreId,
        appBundleId: appBundleId,
        appSharedSecret: appSharedSecret
    });
};

export function appleService() {
    return {
        get,
        remove,
        update
    };
}
