
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useMixins } from '@/modules/chat/sentence/model/mixins';

export default defineComponent({
    name: 'ChatSentenceAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, actions } = useMixins();

        return { model, v$, actions };
    }
});
