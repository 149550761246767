
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useAvatarLayerAdd } from '@/modules/avatar/layer/model/add';

export default defineComponent({
    name: 'AvatarLayerAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, create } = useAvatarLayerAdd();

        return { v$, model, create };
    }
});
