
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppSwitch } from '@/components';
import { useAvatarDefaultAdd } from '@/modules/avatar/default/model/add';

export default defineComponent({
    name: 'AvatarDefaultAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppSwitch },
    setup() {
        const { model, v$, create, typeList, genderList } = useAvatarDefaultAdd();

        return { v$, model, create, typeList, genderList };
    }
});
