import { useVDialog } from '@/plugins/dialog/v-dialog';
import { giftService } from '@/services/gift';
import { validateDate, validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();
    const dialog = useVDialog();

    const model = reactive({
        isNew: true,
        amount: 0,
        receiveLimit: 0,
        gamePercent: 0,
        slotPercent: 0,
        questPercent: 0,
        timeUnit: 0,
        timeDuration: 0,
        startTime: new Date(),
        endTime: new Date()
    });

    const validations = {
        amount: { required, numeric, minValue: minValue(1) },
        receiveLimit: { required, numeric, minValue: minValue(1) },
        gamePercent: { required, numeric, minValue: minValue(0), maxValue: maxValue(100) },
        slotPercent: { required, numeric, minValue: minValue(0), maxValue: maxValue(100) },
        questPercent: { required, numeric, minValue: minValue(0), maxValue: maxValue(100) },
        timeUnit: { required, numeric, minValue: minValue(0) },
        timeDuration: { required, numeric, minValue: minValue(1) },
        startTime: { validateDate: validateDate() },
        endTime: { validateDate: validateDate(), validateDateAfter: validateDateAfter('startTime') }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await giftService().lucky.get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.amount = result.amount;
            model.receiveLimit = result.receiveLimit;
            model.gamePercent = result.gamePercent;
            model.slotPercent = result.slotPercent;
            model.questPercent = result.questPercent;
            model.timeDuration = result.timeDuration;
            model.timeUnit = result.timeUnit;
            model.startTime = new Date(result.startTime);
            model.endTime = new Date(result.endTime);
        } else if (response.code === 404) {
            model.isNew = true;
            model.amount = 0;
            model.receiveLimit = 0;
            model.gamePercent = 0;
            model.slotPercent = 0;
            model.questPercent = 0;
            model.timeUnit = 0;
            model.timeDuration = 0;
            model.startTime = new Date();
            model.endTime = new Date();
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await giftService().lucky.update(
                model.amount,
                model.receiveLimit,
                model.gamePercent,
                model.slotPercent,
                model.questPercent,
                model.timeUnit,
                model.timeDuration,
                model.startTime.getTime(),
                model.endTime.getTime()
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await giftService().lucky.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
