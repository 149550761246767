import { itemService } from '@/services/item';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useItemAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        feature: Number(route.query.feature),
        data: route.query.data as string,
        name: '',
        ruby: 0,
        quantity: null,
        imageFile: null
    });

    const validations = {
        feature: { required },
        data: { required },
        name: { required },
        ruby: { required, min: minValue(0), max: maxValue(32000) },
        quantity: { min: minValue(1), max: maxValue(100) }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await itemService.item.create(
                model.feature,
                model.data,
                model.name,
                model.ruby,
                model.quantity,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create Successfully');
                router.push({ name: 'SystemItemList' });
            }
        }
    };

    return { model, v$, actions, featureList };
}

const featureList = [
    { label: 'AvatarBundle', value: 1 },
    { label: 'ArenaTicket', value: 2 },
    { label: 'QuestBoost', value: 3 },
    { label: 'CreditTicket', value: 4 },
    { label: 'ScratchTicket', value: 5 }
];
