
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppCard, AppTable, AppButton } from '@/components';
import { useSysRoleList } from '@/modules/role/system/model/list';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'SysRoleList',
    components: { AppTable, AppButton, AppCard },
    setup() {
        const { itemList, total, currentPage, changePage, actions, routes } = useSysRoleList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'Account Id', field: 'accountId' },
                { label: 'Name', field: 'name' },
                { label: 'Email', field: 'email' },
                { label: 'Role', field: 'role' },
                { label: 'Actions', field: 'accountId' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.accountId, row.role)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { table, changePage, routes };
    }
});
