
import { defineComponent, onMounted, reactive, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useActivity } from '@/modules/activity/model/list';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'ActivityList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const { filter, v$, itemList, total, currentPage, fetchList, routes, tasks } = useActivity();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Account ID', field: 'accountId' },
                { label: 'Name', display: row => `${row.firstname} ${row.lastname}` },
                { label: 'Feature', field: 'feature' },
                { label: 'Task', display: row => tasks.find(item => item.value == row.action)?.label ?? '' },
                { label: 'Date/Time', display: row => formatDate(row.createTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        onclick: () => routes.detail(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { filter, itemList, v$, table, fetchList, routes, tasks };
    }
});
