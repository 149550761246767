import { totemService } from '@/services/totem';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { integer, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useTotem() {
    const toast = useToast();

    const model = reactive({
        value: null,
        frequency: 0,
        limitCount: 0,
        cooldown: 0,
        isSpecial: false
    });

    const validations = {
        value: {
            required,
            format: validateFormat(/^\d+(,\d+)*$/, 'Please enter in the correct format: Ex: 2,3,5 [in integer numbers]')
        },
        frequency: { required, integer },
        limitCount: { required, integer },
        cooldown: { required, integer },
        isSpecial: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await totemService().get();
        if (response.code === 200) {
            const result = response.data;
            model.value = result.value;
            model.frequency = result.frequency;
            model.limitCount = result.limitCount;
            model.cooldown = result.cooldown;
            model.isSpecial = result.isSpecial;
        } else if (response.code === 404) {
            model.value = null;
            model.frequency = 0;
            model.limitCount = 0;
            model.cooldown = 0;
            model.isSpecial = false;
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await totemService().update(
            model.value,
            model.frequency,
            model.limitCount,
            model.isSpecial,
            model.cooldown
        );

        if (response.code === 200) {
            toast.success('Update successfully');
            fetch();
        }
    };

    return { model, v$, fetch, update };
}
