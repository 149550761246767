import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useMixins() {
    const dialog = useVDialog();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const model = reactive({
        id: null,
        feature: null,
        data: null,
        name: '',
        code: '',
        status: null,
        quantity: 0,
        receiveLimit: -1,
        receiveCounter: 0,
        startTime: new Date(),
        endTime: new Date(),
        canEnable: false,
        canDisable: false,
        canDelete: false
    });

    const fetch = async (id: any) => {
        const response = await promotionService.code.get(id);
        if (response.code == 200) {
            const result = response.data;
            model.id = result.id;
            model.feature = result.feature;
            model.data = result.data;
            model.name = result.name;
            model.code = result.code;
            model.status = result.status;
            model.quantity = result.quantity;
            model.receiveLimit = result.receiveLimit;
            model.receiveCounter = result.receiveCounter;
            model.startTime = new Date(result.startTime);
            model.endTime = new Date(result.endTime);
            model.canEnable = result.canEnable;
            model.canDisable = result.canDisable;
            model.canDelete = result.canDelete;
        } else {
            toast.error(response.data);
            router.push({ name: 'PromotionCodeList' });
        }
    };

    const actions = {
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'PromotionCodeList' });
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully');
                            await fetch(route.params.id);
                        }
                    }
                }
            });
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.code.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully');
                            await fetch(route.params.id);
                        }
                    }
                }
            });
        }
    };

    return { model, options, fetch, actions };
}

const options = {
    features: [
        // { label: 'AvatarBundle', value: 1, background: 'badge-info', routeName: 'SysAvatarBundleList' },
        { label: 'ArenaTicket', value: 2, background: 'badge-success', routeName: 'ArenaTicketList' },
        // { label: 'QuestBoost', value: 3, background: 'badge-info', routeName: 'QuestBoostList' },
        { label: 'CreditTicket', value: 4, background: 'badge-success', routeName: 'CreditTicketList' },
        { label: 'ScratchTicket', value: 5, background: 'badge-info', routeName: 'ScratchTicketList' },
        { label: 'AddChips', value: 10, background: 'badge-primary', routeName: null }
    ],
    arenas: [
        { label: 'ArenaStandard', value: 1, background: 'badge-success' },
        { label: 'ArenaPremium', value: 2, background: 'badge-info' }
    ],
    scratchTickets: [
        { label: 'ScratchSilver', value: 1, background: 'badge-success' },
        { label: 'ScratchGolden', value: 2, background: 'badge-info' }
    ],
    statusList: [
        { value: 0, label: 'None', background: 'badge-secondary' },
        { value: 1, label: 'Enabled', background: 'badge-info' },
        { value: 2, label: 'Started', background: 'badge-success' },
        { value: 3, label: 'Ended', background: 'badge-warning' },
        { value: 4, label: 'Disabled', background: 'badge-warning' }
    ],
    render: (dataList: Array<any>, value: any) => {
        const type = dataList.find(item => item.value === value);
        return `<span class='badge ${type?.background ?? ''}'>${type?.label ?? ''}</span>`;
    }
};
