import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { subscriptionService } from '@/services/subscription';
import { reactive } from 'vue';

export default function () {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: route.params.id as string,
        name: '',
        packageId: '',
        priceId: '',
        price: 0,
        maxReceivableTime: '',
        cycleChip: 0,
        timeUnit: 0,
        timeDuration: 0,
        totalLinked: null,
        startTime: new Date(),
        canDelete: false,
        canEdit: false
    });

    const fetch = async () => {
        const response = await subscriptionService().item.get(model.id);
        if (response.code === 200) {
            const result = response.data;
            result.startTime = new Date(result.startTime);
            Object.assign(model, result);
        } else {
            toast.error('Not found');
            router.push({ name: 'SubscriptionItemList' });
        }
    };

    const remove = async () => {
        const response = await subscriptionService().item.remove(model.id);
        if (response.code === 200) {
            toast.success('Delete successfully');
            router.push({ name: 'SubscriptionItemList' });
        }
    };

    const routes = {
        edit: () => router.push({ name: 'SubscriptionItemEdit', params: { id: model.id } }),
        translate: () => router.push({ name: 'SubscriptionItemTranslate', params: { id: model.id } })
    };

    return { model, fetch, routes, remove };
}
