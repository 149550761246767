import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { usePromotionPrototypeList } from './list';

export function usePromotionPrototypeDetail() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { itemAll: prototypeAll, fetchAll: fetchPrototypeAll } = usePromotionPrototypeList();

    const model = reactive({
        name: null,
        personas: []
    });

    const fetch = async () => {
        if (!route.params?.id) {
            toast.error('Not found');
            router.push({ name: 'PromotionPrototypeList' });
        }

        await fetchPrototypeAll();

        const prototypeId = Number(route.params.id);
        const prototype = prototypeAll.value.find(item => item.id === prototypeId);
        if (prototype) {
            model.name = prototype.name;
            model.personas = prototype.personas;
        }
    };

    return { model, fetch };
}
