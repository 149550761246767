import { duplicateService } from '@/services/duplicate';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { integer } from '@vuelidate/validators';
import { reactive, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useDuplicateRank() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const duplicateRankStore = {
        getPage: () => store.getters['duplicateRank/page'],
        setPage: (page: number) => store.dispatch('duplicateRank/changePage', page),
        getFilter: () => store.getters['duplicateRank/filter'],
        setFilter: () => store.dispatch('duplicateRank/changeFilter', { accountId: model.filter.accountId })
    };

    const model = reactive({
        filter: {
            ...duplicateRankStore.getFilter(),
            duplicateItemId: route.params.itemId,
            duplicateCycleId: route.params.cycleId
        }
    });

    const validations = {
        filter: {
            accountId: { integer }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, changePage, currentPage } = useFetchList(duplicateService.account.getRankList, {
        pagingStore: duplicateRankStore,
        options: toRef(model, 'filter'),
        onSuccess: () => duplicateRankStore.setFilter()
    });

    const routes = {
        account: (accountId: any) => router.push({ name: 'AppAccountDetail', params: { accountId: accountId } })
    };

    return { v$, model, itemList, total, currentPage, changePage, routes };
}
