
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useMixins } from '@/modules/chat/sentence/model/mixins';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'ChatSentenceEdit',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const route = useRoute();
        const { model, v$, routes, fetch, actions } = useMixins();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { model, v$, routes, actions };
    }
});
