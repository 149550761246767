import { reactive } from 'vue';
import { required, minValue, maxValue, integer } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { questService } from '@/services/quest';
import { useVuelidate } from '@vuelidate/core';

export function useQuestTypeAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        displayType: 0,
        timeUnit: 0,
        timeDuration: '',
        openCycle: null,
        restCycle: null,
        startTime: new Date()
    });

    const validations = {
        name: { required },
        timeDuration: { required, integer, min: minValue(1), max: maxValue(32000) },
        openCycle: { required, integer, min: minValue(1) },
        restCycle: { required, integer, min: minValue(0) },
        startTime: { required }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await questService.type.create(
            model.name,
            model.displayType,
            model.timeUnit,
            model.timeDuration,
            model.openCycle,
            model.restCycle,
            model.startTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'QuestTypeList' });
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create };
}
