import { useQuestTypeList } from '@/modules/quest/type/model/list';
import { questService } from '@/services/quest';
import { useVuelidate } from '@vuelidate/core';
import { integer, maxValue, minValue, required, requiredIf } from '@vuelidate/validators';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useQuestItemAdd() {
    const router = useRouter();
    const toast = useToast();

    const typeList = ref([]);
    const { itemList: questTypeList, fetchList: fetchQuestTypeList } = useQuestTypeList();

    const beforeFetch = async () => {
        await fetchQuestTypeList();
        if (!questTypeList.value.length) {
            toast.error('There is no quest type');
            router.push({ name: 'QuestItemList' });
            return;
        }

        typeList.value = questTypeList.value.map(type => {
            return { label: type.name, value: type.id };
        });
    };

    const model = reactive({
        name: null,
        typeId: null,
        game: 0,
        fromAvgGame: -1,
        toAvgGame: -1,
        fromGameBet: -1,
        toGameBet: -1,
        gameType: 0,
        reward: 0,
        adsBoost: 0,
        adsBoostRate: 100
    });

    const validations = {
        name: { required },
        typeId: { required },
        game: { required, integer, min: minValue(1) },
        gameType: { required },
        reward: { required, integer, min: minValue(1) },
        adsBoost: { required, integer, min: minValue(0) },
        adsBoostRate: { required, integer, min: minValue(100) },
        fromAvgGame: {
            required: requiredIf(() => typeof model.toAvgGame === 'string' || model.toAvgGame < 0),
            validValue: computed(() => {
                if (typeof model.toAvgGame === 'string' || model.toAvgGame < 0) {
                    return minValue(0);
                } else {
                    return typeof model.fromAvgGame === 'string' || model.fromAvgGame < 0 || maxValue(model.toAvgGame);
                }
            })
        },
        toAvgGame: {
            required: requiredIf(() => typeof model.fromAvgGame === 'string' || model.fromAvgGame < 0),
            validValue: computed(() => {
                if (typeof model.fromAvgGame === 'string' || model.fromAvgGame < 0) {
                    return minValue(0);
                } else {
                    return typeof model.toAvgGame === 'string' || model.toAvgGame < 0 || minValue(model.fromAvgGame);
                }
            })
        },
        fromGameBet: {
            required: requiredIf(() => typeof model.toGameBet === 'string' || model.toGameBet < 0),
            validValue: computed(() => {
                if (typeof model.toGameBet === 'string' || model.toGameBet < 0) {
                    return minValue(0);
                } else {
                    return typeof model.fromGameBet === 'string' || model.fromGameBet < 0 || maxValue(model.toGameBet);
                }
            })
        },
        toGameBet: {
            required: requiredIf(() => typeof model.fromGameBet === 'string' || model.fromGameBet < 0),
            validValue: computed(() => {
                if (typeof model.fromGameBet === 'string' || model.fromGameBet < 0) {
                    return minValue(0);
                } else {
                    return typeof model.toGameBet === 'string' || model.toGameBet < 0 || minValue(model.fromGameBet);
                }
            })
        }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await questService.item.create(
                model.typeId,
                model.name,
                model.game,
                model.fromAvgGame,
                model.toAvgGame,
                model.fromGameBet,
                model.toGameBet,
                model.gameType,
                model.reward,
                model.adsBoost,
                model.adsBoostRate
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push({ name: 'QuestItemList' });
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, beforeFetch, typeList, actions };
}
