import http from '@/utils/axios';

export const transactionService = {
    text: {
        list(paging: any, locale: any) {
            return http.doGet('admin/transaction/text/' + locale, {
                ...paging
            });
        },

        getContent(code: string, locale: string) {
            return http.doGet('admin/transaction/text/' + code + '/' + locale);
        },

        updateContent(code: string, locale: string, content: string) {
            return http.doPut('admin/transaction/text/' + code + '/' + locale, {
                content: content
            });
        },

        export() {
            return http.doGet('admin/transaction/text/export');
        },

        import(file: any) {
            return http.doPost(
                'admin/transaction/text/import',
                {},
                {
                    file: file
                }
            );
        }
    }
};
