
import { defineComponent, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'AppMenuItem',
    props: {
        menuItem: { type: Object, default: null }, // MenuObject as { name: string, path: string, icon: string, children: Array<Menu>}
        icon: { type: String, default: null }
    },
    setup(props) {
        const router = useRouter();
        const model = reactive({
            isMenuExtended: false,
            isExpandable: false,
            isMainActive: false,
            isOneOfChildrenActive: false
        });

        function handleMainMenuAction(evt: any) {
            if (model.isExpandable) {
                const expandEl: HTMLElement = evt.target.closest('.expandable');
                const parenEl: HTMLElement = expandEl.parentElement;

                Array.from(parenEl.getElementsByClassName('menu-open'))
                    .filter(el => el != evt.target.parentElement)
                    .forEach(el => (el as HTMLElement).dispatchEvent(new Event('toggle')));

                toggleMenu();
                return;
            }

            router.push(props.menuItem.path);
        }

        function toggleMenu() {
            model.isMenuExtended = !model.isMenuExtended;
        }

        function calculateIsActive(url: string) {
            model.isMainActive = false;
            model.isOneOfChildrenActive = false;
            model.isMenuExtended = false;

            if (url.includes(props.menuItem.path)) {
                model.isMainActive = true;
                return;
            }

            if (model.isExpandable) {
                checkHasOneChildrenActive(props.menuItem.children, url);
            }
        }

        function checkHasOneChildrenActive(menuItem: any, url: string): void {
            menuItem.forEach((item: any) => {
                if (url.includes(item.path)) {
                    model.isOneOfChildrenActive = true;
                    model.isMenuExtended = true;
                } else if (item?.children?.length) {
                    checkHasOneChildrenActive(item.children, url);
                }
            });
        }

        onMounted(() => {
            model.isExpandable = props.menuItem && props.menuItem.children && props.menuItem.children.length > 0;
            calculateIsActive(router.currentRoute.value.path);
            router.afterEach(to => {
                calculateIsActive(to.path);
            });
        });

        return { model, handleMainMenuAction, toggleMenu };
    }
});
