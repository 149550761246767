import http from '@/utils/axios';

export const passwordService = {
    update(accountId: any, email: any, password: any) {
        return http.doPut('admin/password/reset', {
            accountId: accountId,
            email: email,
            password: password
        });
    },

    generate(email: any) {
        return http.doPut('admin/password/generate', {
            email: email
        });
    }
};
