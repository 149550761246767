
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useAvatarColorAdd } from '@/modules/avatar/color/model/add';

export default defineComponent({
    name: 'AvatarColorAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, create } = useAvatarColorAdd();

        return { v$, model, create };
    }
});
