
import { defineComponent, onMounted } from 'vue';
import {
    AppFormRow,
    AppInput,
    AppCard,
    AppButton,
    AppSelect,
    AppFileUpload,
    AppImageHolder,
    AppSwitch
} from '@/components';
import { useAvatarDefaultEdit } from '@/modules/avatar/default/model/edit';

export default defineComponent({
    name: 'AvatarDefaultEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload, AppImageHolder, AppSwitch },
    setup() {
        const { model, v$, fetch, actions, typeList, genderList } = useAvatarDefaultEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, typeList, genderList };
    }
});
