import { useVDialog } from '@/plugins/dialog/v-dialog';
import { googleService } from '@/services/google';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();

    const model = reactive({
        isNew: true,
        packageName: '',
        clientId: '',
        clientSecret: '',
        accessToken: '',
        refreshToken: '',
        prefixSchema: ''
    });

    const validations = {
        packageName: { required },
        clientId: { required },
        clientSecret: { required },
        accessToken: { required },
        refreshToken: { required },
        prefixSchema: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await googleService.payment.get();
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.packageName = result.packageName;
            model.clientId = result.clientId;
            model.clientSecret = result.clientSecret;
            model.accessToken = result.accessToken;
            model.refreshToken = result.refreshToken;
            model.prefixSchema = result.prefixSchema;
        } else if (response.code === 404) {
            model.isNew = true;
            model.packageName = '';
            model.clientId = '';
            model.clientSecret = '';
            model.accessToken = '';
            model.refreshToken = '';
            model.prefixSchema = '';
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await googleService.payment.update(
                model.packageName,
                model.clientId,
                model.clientSecret,
                model.accessToken,
                model.refreshToken,
                model.prefixSchema
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                await fetch();
            }
        },

        remove: async () => {
            useVDialog().confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await googleService.payment.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
