
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import useGiftSetting from '@/modules/gift/model/setting';

export default defineComponent({
    name: 'GiftSetting',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, update, fetch } = useGiftSetting();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, update };
    }
});
