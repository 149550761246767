
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useAccountActivity } from '@/modules/account/app/model/activity';
import { useRoute } from 'vue-router';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'AppAccountActivityList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, itemList, total, currentPage, fetchList, actions, routes } = useAccountActivity();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                { label: 'Account ID', field: 'accountId' },
                { label: 'Device ID', field: 'deviceId' },
                { label: 'IP Address', field: 'ipAddress' },
                { label: 'Platform', field: 'platform' },
                { label: 'Device OS', field: 'deviceOS' },
                { label: 'Store', display: (row: any) => renderStore(row.store) },
                { label: 'Version', field: 'version' },
                { label: 'Create Time', display: row => formatDate(row.createTime) },
                { label: 'Actions', field: 'accountId' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-map-marker-alt',
                        theme: 'info',
                        tooltip: 'Filter by address',
                        onclick: () => routes.address(row.ipAddress)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-tablet-alt',
                        theme: 'info',
                        tooltip: 'Filter by device',
                        onclick: () => routes.device(row.deviceId)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            model.filter.type = route.query?.type ? Number(route.query?.type) : null;
            model.filter.criteria = route.query?.criteria ? String(route.query?.criteria) : null;
            fetchList(currentPage.value);
        });

        return { model, itemList, v$, table, fetchList, actions, routes, types };
    }
});

const types = [
    { value: 0, label: 'Account Id' },
    { value: 1, label: 'Ip Address' },
    { value: 2, label: 'Device Id' }
];

function renderStore(storeId: any) {
    switch (storeId) {
        case 0:
            return 'Apple';
        case 1:
            return 'Google';
        case 3:
            return 'Facebook';
        case 4:
            return 'Website';

        default:
            return '';
    }
}
