
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSwitch } from '@/components';
import { useTotem } from '@/modules/totem/model/edit';

export default defineComponent({
    name: 'Totem',
    components: { AppFormRow, AppInput, AppButton, AppCard, AppSwitch },
    setup() {
        const { model, v$, fetch, update } = useTotem();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, update };
    }
});
