
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker } from '@/components';
import { useArenaItemClone } from '@/modules/arena/item/model/clone';

export default defineComponent({
    name: 'ArenaItemClone',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppDateTimePicker },
    setup() {
        const { model, v$, beforeFetch, fetch, betList, actions } = useArenaItemClone();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, actions, betList, typeList };
    }
});

const typeList = [
    { label: 'Standard', value: 1 },
    { label: 'Premium', value: 2 }
];
