
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppDateTimePicker } from '@/components';
import { useSubscriptionAdd } from '@/modules/subscription/item/model/add';

export default defineComponent({
    name: 'SubscriptionAdd',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker },
    setup() {
        const { model, v$, timeUnits, fetch, actions, iapOptions } = useSubscriptionAdd();

        onMounted(() => {
            fetch();
        });

        return { v$, model, timeUnits, iapOptions, actions };
    }
});
