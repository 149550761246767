import http from '@/utils/axios';

export default {
    get(locale: string) {
        return http.doGet('admin/badword/' + locale);
    },

    update(locale: string, content: any) {
        return http.doPut('admin/badword/' + locale, {
            content: content
        });
    },

    remove(locale: string) {
        return http.doDelete('admin/badword/' + locale);
    }
};
