import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b48ac0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "right fas fa-angle-left"
}
const _hoisted_3 = {
  key: 1,
  class: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_menu_item = _resolveComponent("app-menu-item")!

  return (_ctx.menuItem.hasPermission)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(["nav nav-pills nav-sidebar flex-column", { expandable: _ctx.model.isExpandable }]),
        "data-widget": "treeview",
        role: "menu",
        "data-accordion": "false"
      }, [
        _createElementVNode("a", {
          class: _normalizeClass({ 'nav-item mw-100': true, 'menu-open': _ctx.model.isMenuExtended }),
          href: _ctx.menuItem?.path,
          onToggle: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("a", {
            class: _normalizeClass({ 'nav-link mw-100': true, active: _ctx.model.isMainActive }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMainMenuAction($event)))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["nav-icon", _ctx.icon])
            }, null, 2),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.menuItem.name) + " ", 1),
              (_ctx.menuItem.children)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true),
              (_ctx.model.isExpandable)
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : _createCommentVNode("", true)
            ])
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.children, (item) => {
            return (_openBlock(), _createBlock(_component_app_menu_item, {
              class: "nav nav-treeview ml-3",
              key: item,
              menuItem: item,
              icon: item.icon
            }, null, 8, ["menuItem", "icon"]))
          }), 128))
        ], 42, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}