
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { useStore } from 'vuex';
import { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useTranslationList } from '@/modules/translation/model/list';

export default defineComponent({
    name: 'TranslationList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppInput, AppButton },
    setup() {
        const store = useStore();
        const { model, v$, itemList, total, currentPage, changePage, beforeFetch, actions, routes } =
            useTranslationList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Content', field: 'content' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.id, model.filter.locale)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        const fetchList = async (page: number) => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            await changePage(page);
        };

        onMounted(async () => {
            await beforeFetch();
            await fetchList(currentPage.value);
        });

        return { model, v$, table, fetchList, routes, store };
    }
});
