import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export default function () {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { model, v$, currencyOptions } = useMixins();

    const fetch = async () => {
        const id = route.params?.id;
        if (!id) {
            router.go(-1);
        }

        const response = await applicationService.app.get(id);
        if (response.code === 200) {
            const data = response.data;
            data.removeTime = data.removeTime ? new Date(data.removeTime) : null;
            data.createTime = data.createTime ? new Date(data.createTime).toString() : '';

            Object.assign(model, data);
        } else {
            router.go(-1);
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }
            const response = await applicationService.app.update(
                model.id,
                model.name,
                model.currency,
                model.creditInit,
                model.friendLimit,
                model.popupRate,
                model.winsForRating,
                model.bindSuggestTime,
                model.remindApple,
                model.remainVipMinutes,
                model.vipTermMinutes,
                model.creditWelcomeLimit,
                model.thumbnailFile
            );
            if (response.code === 200) {
                toast.success('Update successfully', { timeout: 2000 });
                fetch();
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.app.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        },
        unremove: () => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.app.undelete(model.id);
                        if (response.code === 200) {
                            toast.success('Undelete successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    return { model, currencyOptions, v$, fetch, actions };
}
