import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useArenaTicketAdd() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        type: Number(route.params.type),
        name: '',
        canDelete: false
    });

    const validations = {
        name: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await arenaService.ticket.get(model.type);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push({ name: 'ArenaTicketList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await arenaService.ticket.update(model.type, model.name);
            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'ArenaTicketList' });
            } else {
                toast.error(response.data);
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.ticket.remove(model.type);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'ArenaTicketList' });
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
