import { useVDialog } from '@/plugins/dialog/v-dialog';
import { creditService } from '@/services/credit';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useCreditList() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const { itemList, total, fetchList } = useFetchList(creditService.ticket.list, null);

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await creditService.ticket.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            fetchList();
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'CreditTicketAdd' }),
        edit: (id: any) => router.push({ name: 'CreditTicketEdit', params: { id: id } }),
        specialShop: (id: any) => router.push({ name: 'SystemItemAdd', query: { data: id, feature: 4 } }),
        promoCode: (id: any) => router.push({ name: 'PromotionCodeAdd', query: { data: id, feature: 4 } })
    };

    return { itemList, total, actions, routes, fetchList };
}
