
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { useNewsTranslate } from '@/modules/news/model/translate';

export default defineComponent({
    name: 'NewsTranslate',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { model, v$, localeSupportedOptions, fetch, beforeFetch, actions } = useNewsTranslate();

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, localeSupportedOptions, fetch, actions };
    }
});
