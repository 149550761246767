import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { reactive, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAvatarCategorytList() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarCategoryStore = {
        getPage: () => store.getters['avatarCategory/page'],
        setPage: (page: number) => store.dispatch('avatarCategory/changePage', page)
    };

    const model = reactive({
        query: {
            name: (route.query?.name ?? null) as string,
            parentId: route.query?.parentId ?? null
        }
    });

    const validations = {
        query: {
            name: { min: minLength(3) }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.category.list, {
        pagingStore: avatarCategoryStore,
        options: toRef(model, 'query')
    });

    const fetchCategories = async (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        changePage(page);
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.category.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchCategories(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        items: (categoryId: any) => router.push({ name: 'SysAvatarItemList', query: { categoryId: categoryId } }),
        sub: (parentId: any) => router.push({ name: 'SysAvatarCategoryList', query: { parentId: parentId } }),
        edit: (categoryId: any) => router.push({ name: 'SysAvatarCategoryEdit', params: { categoryId: categoryId } }),
        add: () =>
            router.push({
                name: 'SysAvatarCategoryAdd',
                query: model.query.parentId ? { parentId: model.query.parentId } : {}
            })
    };

    return { model, v$, itemList, total, currentPage, fetchCategories, actions, routes };
}
