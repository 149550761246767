import { promotionService } from '@/services/promotion';
import { rfmService } from '@/services/rfm';
import { App } from '@/store/applications';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function usePromotionItemAdd() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const store = useStore();

    const model = reactive({
        cloneId: route.params?.id ?? null,
        forAppId: null,
        name: '',
        status: null,
        displayType: 0,
        fromTime: null,
        toTime: null,
        expectedPackage: 0,
        image: '',
        imageStatus: null,
        imageFile: null,
        prototypeId: null,
        prototypeOptions: [],
        prototypeList: [],
        personas: [],
        appOptions: []
    });

    const validations = {
        name: { required },
        fromTime: { required },
        toTime: { required, validDate: validateDateAfter('fromTime') },
        displayType: { required },
        expectedPackage: { integer, minValue: minValue(0) },
        prototypeId: { required, integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const currentAppId = store.getters['application/appId'];
        actions.loadPrototypes(currentAppId);

        // clone case:
        if (model.cloneId) {
            const response = await promotionService.item.get(model.cloneId);
            if (response.code === 200) {
                const result = response.data;
                model.name = result.name;
                model.cloneId = result.id;
                model.forAppId = currentAppId;
                model.displayType = result.displayType;
                model.fromTime = new Date(result.fromTime);
                model.toTime = new Date(result.toTime);
                model.expectedPackage = result.expectedPackage;
                model.image = result.image;
                model.prototypeId = result.usedPrototypeId;
                actions.selectPrototype(model.prototypeId);
                model.appOptions = (store.getters['application/apps'] as Array<App>).map(app => ({
                    label: app.name,
                    value: app.id
                }));
            } else {
                toast.error('Not found');
                router.push({ name: 'PromotionItemList' });
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await promotionService.item.create(
                model.name,
                model.cloneId,
                model.forAppId,
                model.fromTime.getTime(),
                model.toTime.getTime(),
                model.displayType,
                model.expectedPackage,
                model.prototypeId,
                model.imageFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'PromotionItemList' });
            }
        },
        selectPrototype: (prototypeId: any) => {
            if (prototypeId > 0) {
                model.prototypeList.forEach(function (item) {
                    if (item.id == prototypeId) {
                        model.personas = item.personas;
                    }
                });
            }
        },
        loadPrototypes: async (forAppId: any) => {
            model.personas = [];
            model.prototypeId = null;

            let personaList: [];
            const protoRequest = await promotionService.prototype.listAll(forAppId);
            if (protoRequest.code === 200) {
                model.prototypeList = protoRequest.data;
            }

            const personaRequest = await rfmService().persona.listForOtherApp(forAppId);
            if (personaRequest.code === 200) {
                personaList = personaRequest.data;
            }

            model.prototypeOptions = [];
            model.prototypeList.forEach(function (item: any) {
                item.personas.forEach(function (per1: any) {
                    personaList.forEach(function (per2: any) {
                        if (per1.rfmPersonaId == per2.id) {
                            per1.rfmPersonaName = per2.name;
                        }
                    });
                });
                model.prototypeOptions.push({ value: item.id, label: item.name });
            });
        }
    };

    return { model, v$, fetch, actions };
}
