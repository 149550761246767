
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useSlot } from '@/modules/slot/model/edit';

export default defineComponent({
    name: 'Slot',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, fetch, actions } = useSlot();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, actions };
    }
});
