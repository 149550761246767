import { creditService } from '@/services/credit';
import { rubyService } from '@/services/ruby';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export function useAccountTransaction() {
    const store = useStore();
    const route = useRoute();

    const queryParams = reactive({
        accountId: route.params.accountId,
        typeId: route.query?.typeId ? Number(route.query?.typeId) : null
    });

    const transactionStore = {
        getPage: () => store.getters['accountTransaction/page'],
        setPage: (page: number) => store.dispatch('accountTransaction/changePage', page)
    };

    const request = () => {
        if (!queryParams.accountId || queryParams.typeId == null) {
            return;
        }

        if (queryParams.typeId === 0) {
            return creditService.transaction.list;
        } else if (queryParams.typeId === 1) {
            return creditService.transaction.listPayment;
        } else if (queryParams.typeId === 2) {
            return rubyService.transaction.list;
        }
    };

    const { itemList, total, currentPage, changePage } = useFetchList(request(), {
        pagingStore: transactionStore,
        options: toRef(queryParams, 'accountId')
    });

    const fetchList = async (page: number) => {
        if (!queryParams.accountId || queryParams.typeId == null) {
            return;
        }

        await changePage(page);
    };

    return { queryParams, itemList, total, currentPage, fetchList };
}
