import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Edit League"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        (_ctx.model.season == 2)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_form_row, { label: "Time Unit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_select, {
                    options: _ctx.timeUnits,
                    modelValue: _ctx.model.timeUnit,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.timeUnit
                  }, null, 8, ["options", "modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Time Duration" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.timeDuration,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.timeDuration
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Start Time" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_date_time_picker, {
                    modelValue: _ctx.model.startTime,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.startTime) = $event)),
                    validations: _ctx.v$.startTime
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_row, { label: "# of Game" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.game,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.game) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.game
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        (_ctx.model.season == 2)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_app_form_row, { label: "Max Player" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.maxPlayer,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.maxPlayer) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.maxPlayer
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Base Point" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.basePoint,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.basePoint) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.basePoint
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Default Point" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    type: "number",
                    modelValue: _ctx.model.defaultPoint,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.defaultPoint) = $event)),
                    modelModifiers: { number: true },
                    validations: _ctx.v$.defaultPoint
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              }),
              _createVNode(_component_app_form_row, { label: "Rank Ratio" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_input, {
                    modelValue: _ctx.model.rankRatio,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.rankRatio) = $event)),
                    validations: _ctx.v$.rankRatio
                  }, null, 8, ["modelValue", "validations"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Update",
              theme: "info",
              icon: "fa-save",
              disabled: !_ctx.v$.$anyDirty,
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_app_button, {
              name: "Tier",
              theme: "info",
              icon: "fa-layer-group",
              onClick: _withModifiers(_ctx.routes.tier, ["prevent"])
            }, null, 8, ["onClick"]),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Delete",
                  theme: "danger",
                  icon: "fa-trash-alt",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}