import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_card, {
      label: "Link / Unlink medals",
      classes: "mx-auto"
    }, {
      default: _withCtx(() => [
        (_ctx.model.unlinkedItems.length)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 0,
              name: "Link all",
              icon: "fa-link",
              theme: "info",
              onClick: _ctx.actions.linkAll
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.model.linkedItems.length)
          ? (_openBlock(), _createBlock(_component_app_button, {
              key: 1,
              name: "Unlink all",
              icon: "fa-unlink",
              theme: "warning",
              onClick: _ctx.actions.unlinkAll
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_app_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_app_table, {
          columns: _ctx.table.columns,
          rows: _ctx.table.rows,
          total: _ctx.table.totalRecordCount,
          "page-size": Number.MAX_SAFE_INTEGER,
          actions: _ctx.table.actions,
          onRefreshData: _ctx.fetch
        }, null, 8, ["columns", "rows", "total", "page-size", "actions", "onRefreshData"])
      ]),
      _: 1
    })
  ], 64))
}