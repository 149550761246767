
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard } from '@/components';
import { useTournamentCycle } from '@/modules/tournament/cycle/model/list';
import { RouterLink } from 'vue-router';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'TournamentCycles',
    components: { AppTable, AppCard },
    setup() {
        const { itemList, total, currentPage, fetchList } = useTournamentCycle();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: '#', field: '#' },
                {
                    label: 'Leaderboard',
                    component: (row: any) => ({
                        type: markRaw(RouterLink),
                        message: row.leaderboardName,
                        props: { to: `/app/tournament/leaderboard/${row.tournamentLeaderboardId}/detail` }
                    })
                },
                { label: 'Cycle', field: 'id' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'End Time', display: (row: any) => formatDate(row.endTime) },
                { label: 'Status', display: (row: any) => renderStatus(row.status) }
            ] as Array<any>
        });

        onMounted(() => {
            fetchList(currentPage.value);
        });

        return { table, fetchList };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Started', value: 1, background: 'success' },
    { label: 'Ended', value: 2, background: 'info' },
    { label: 'Disabled', value: 3, background: 'secondary' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
