import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Quest Type Detail"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Name" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Display Type" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.displayTypeList,
              modelValue: _ctx.model.displayType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.displayType) = $event)),
              disabled: ""
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Unit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.timeUnits,
              modelValue: _ctx.model.timeUnit,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
              disabled: ""
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Duration" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.timeDuration,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Open Cycle" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.openCycle,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.openCycle) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Rest Cycle" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.restCycle,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.restCycle) = $event)),
              modelModifiers: { number: true },
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.startTime) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Next Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.nextStartTime,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.nextStartTime) = $event)),
              disabled: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.model.canEnable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Enable next",
                  icon: "fa-play",
                  theme: "success",
                  onClick: _withModifiers(_ctx.actions.enableNext, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDisable)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  name: "Disable",
                  icon: "fa-stop",
                  theme: "warning",
                  onClick: _withModifiers(_ctx.actions.disable, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDisableNext)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 2,
                  name: "Disable next",
                  icon: "fa-stop",
                  theme: "info",
                  onClick: _withModifiers(_ctx.actions.disableNext, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.model.canDelete)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 3,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}