import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarCategoryAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const parentCategory = ref(null);

    const model = reactive({
        name: '',
        position: 0,
        gender: 0,
        isPublished: false,
        isRequired: false,
        posX: 0,
        posY: 0,
        isBody: false,
        imageFile: null
    });

    const validations = {
        name: { required },
        gender: { required },
        position: { required, min: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        if (route.query?.parentId) {
            const response = await avatarService.category.get(route.query.parentId);
            if (response.code === 200) {
                parentCategory.value = response.data;
            } else {
                toast.error(response.data);
                router.push({ name: 'SysAvatarCategoryList' });
            }
        }
    };

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await avatarService.category.create(
            parentCategory.value?.id ?? null,
            model.name,
            model.position,
            model.gender,
            model.isPublished,
            model.isRequired,
            model.posX,
            model.posY,
            model.isBody,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({
                name: 'SysAvatarCategoryList',
                query: parentCategory.value ? { parentId: parentCategory.value.id } : {}
            });
        }
    };

    return { parentCategory, model, v$, fetch, create, genderList };
}

const genderList = [
    { label: 'Female', value: 0 },
    { label: 'Male', value: 1 }
];
