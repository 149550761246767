import http from '@/utils/axios';

export const activityService = {
    account: {
        list(paging: any, options: any) {
            return http.doGet('admin/activity/login/account', {
                ...paging,
                ...options
            });
        },

        getLastLogin(accountId: any) {
            return http.doGet('admin/activity/login/account/' + accountId);
        }
    },

    app: {
        list(paging: { offset: any; limit: any }, filter: { accountId: any; feature: any; action: any }) {
            return http.doGet('admin/activity', {
                ...paging,
                ...filter
            });
        },

        get(id: any) {
            return http.doGet('admin/activity/' + id);
        }
    }
};
