
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useAccountAdd } from '@/modules/account/system/model/add';

export default defineComponent({
    name: 'SysAccountAdd',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, actions } = useAccountAdd();

        return { v$, model, actions };
    }
});
