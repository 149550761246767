import { transactionService } from '@/services/transaction';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useTransactionEdit() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        code: null,
        name: '',
        locale: null,
        content: ''
    });

    const fetch = async (code: any, locale: any) => {
        const response = await transactionService.text.getContent(code, locale);
        if (response.code === 200) {
            const result = response.data;
            model.code = code;
            model.locale = locale;
            model.name = result.name;
            model.content = result.content;
        } else {
            toast.error(response.data);
            router.push({ name: 'TransactionTextList' });
        }
    };

    const update = async () => {
        const response = await transactionService.text.updateContent(model.code, model.locale, model.content);
        if (response.code === 200) {
            toast.success('Update successfully');
            router.push({ name: 'TransactionTextList' });
        }
    };

    return { model, fetch, update };
}
