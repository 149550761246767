import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar_breadcrumb = _resolveComponent("app-avatar-breadcrumb")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_image_holder = _resolveComponent("app-image-holder")!
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_avatar_breadcrumb, {
      class: "col-lg-8 mx-auto",
      "category-id": _ctx.avatarItem?.categoryId
    }, null, 8, ["category-id"]),
    _createVNode(_component_app_card, {
      class: "col-lg-8 mx-auto",
      label: `Update avatar item's image - ` + _ctx.avatarItem?.name
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", _hoisted_1, [
          _createVNode(_component_app_form_row, { label: "Color" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_select, {
                options: _ctx.colorOption,
                modelValue: _ctx.model.colorId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.colorId) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Layer" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_select, {
                options: _ctx.layerOption,
                modelValue: _ctx.model.layerId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.layerId) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Image" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_image_holder, {
                image: _ctx.model.image,
                status: _ctx.model.imageStatus
              }, null, 8, ["image", "status"]),
              _createVNode(_component_app_file_upload, {
                accept: "image/png",
                modelValue: _ctx.model.imageFile,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.imageFile) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_app_form_row, { label: "Is default" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_switch, {
                modelValue: _ctx.model.isDefault,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.isDefault) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_button, {
                name: "Update",
                theme: "info",
                icon: "fa-save",
                onClick: _withModifiers(_ctx.actions.update, ["prevent"])
              }, null, 8, ["onClick"]),
              (_ctx.model.canDelete)
                ? (_openBlock(), _createBlock(_component_app_button, {
                    key: 0,
                    name: "Delete",
                    theme: "danger",
                    icon: "fa-trash-alt",
                    onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["label"])
  ], 64))
}