
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppSelect, AppCard, AppButton } from '@/components';
import { useChatSentenceTranslate } from '@/modules/chat/sentence/model/translate';

export default defineComponent({
    name: 'ChatSentenceTranslate',
    components: { AppFormRow, AppInput, AppSelect, AppCard, AppButton },
    setup() {
        const { v$, model, localeSupportedOptions, fetch, beforeFetch, actions } = useChatSentenceTranslate();
        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { v$, model, localeSupportedOptions, fetch, actions };
    }
});
