
import { defineComponent } from 'vue';
import ApplicationsDropdown from './applications/applications.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AppHeader',
    components: { ApplicationsDropdown },
    setup() {
        const store = useStore();

        const navbarVariant = process.env.VUE_APP_NAVBAR_VARIANT;

        function onToggleMenuSidebar(): void {
            store.dispatch('ui/toggleMenuSidebar');
        }

        // function onToggleControlSidebar(): void {
        //     store.dispatch('ui/toggleControlSidebar');
        // }

        function logout() {
            store.dispatch('auth/logout');
        }

        return { navbarVariant, onToggleMenuSidebar, logout };
    }
});
