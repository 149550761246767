
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { useStore } from 'vuex';
import { AppTable, AppCard, AppFormRow, AppSelect, AppButton } from '@/components';
import { useGameBetList } from '@/modules/game/bet/model/list';
import { IAction, IColumn } from '@/components/table/model';

export default defineComponent({
    name: 'TranslationList',
    components: { AppTable, AppCard, AppFormRow, AppSelect, AppButton },
    setup() {
        const store = useStore();
        const { model, itemList, total, options, fetch, beforeFetch, remove, routes } = useGameBetList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => options.render(options.betTypes, row.type) },
                { label: 'Cost', field: 'cost' },
                { label: 'Max round', field: 'maxRound' },
                { label: 'Max point', field: 'maxPoint' },
                { label: 'Lock type', display: (row: any) => options.render(options.lockTypes, row.lockType) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        onclick: () => routes.detail(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        onClick: () => remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        const onChangeVariant = () => {
            routes.index(model.variantId);
            fetch();
        };

        onMounted(async () => {
            await beforeFetch();
            await fetch();
        });

        return { model, table, fetch, onChangeVariant, routes, store, options };
    }
});
