import { itemService } from '@/services/item';
import { timeUnits } from '@/utils/helpers';
import { validateDateAfter } from '@/utils/validatorHelpers';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default function useItemSettingAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        timeUnit: 0,
        timeDuration: 0,
        startTime: new Date()
    });

    const validations = {
        timeDuration: { required, numeric, minValue: minValue(1), maxValue: maxValue(3200) },
        startTime: { required, validateDate: validateDateAfter() }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await itemService.setting.create(
            model.timeUnit,
            model.timeDuration,
            model.startTime.getTime()
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'AppItemSettingList' });
        }
    };

    return { model, v$, timeUnits, create };
}
