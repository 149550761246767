
import { defineComponent, onMounted } from 'vue';
import {
    AppFormRow,
    AppInput,
    AppCard,
    AppButton,
    AppSelect,
    AppSwitch,
    AppFileUpload,
    AppImageHolder,
    AppAvatarBreadcrumb
} from '@/components';
import { useAvatarCategoryEdit } from '@/modules/avatar/category/model/edit';

export default defineComponent({
    name: 'AvatarCategoryEdit',
    components: {
        AppFormRow,
        AppInput,
        AppCard,
        AppButton,
        AppSelect,
        AppSwitch,
        AppFileUpload,
        AppImageHolder,
        AppAvatarBreadcrumb
    },
    setup() {
        const { model, parentCategory, v$, fetch, actions, routes, genderList } = useAvatarCategoryEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, parentCategory, actions, routes, genderList };
    }
});
