import { useVDialog } from '@/plugins/dialog/v-dialog';
import { googleService } from '@/services/google';
import useVuelidate from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const dialog = useVDialog();
    const toast = useToast();

    const model = reactive({
        isNew: true,
        store: 4,
        clientId: '',
        clientSecret: '',
        redirectUri: '',
        storeOptions: [{ value: 4, label: 'Website' }]
    });

    const validations = {
        store: { required },
        clientId: { required },
        clientSecret: { required },
        redirectUri: { required, url }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await googleService.linkedApp.get(model.store);
        if (response.code === 200) {
            const result = response.data;
            model.isNew = false;
            model.store = result.store;
            model.clientId = result.clientId;
            model.clientSecret = result.clientSecret;
            model.redirectUri = result.redirectUri;
        } else if (response.code === 404) {
            model.isNew = true;
            model.store = 4;
            model.clientId = '';
            model.clientSecret = '';
            model.redirectUri = '';
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await googleService.linkedApp.update(
                model.store,
                model.clientId,
                model.clientSecret,
                model.redirectUri
            );

            if (response.code === 200) {
                toast.success('Update successfully');
                await fetch();
            }
        },

        remove: async () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await googleService.linkedApp.remove(model.store);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
