import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07887130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ol", _hoisted_1, [
    _createElementVNode("li", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routerLinkToAvatarCategory(null)))
    }, "Root"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id,
        onClick: ($event: any) => (_ctx.routerLinkToAvatarCategory(item.id))
      }, _toDisplayString(item.name), 9, _hoisted_2))
    }), 128))
  ]))
}