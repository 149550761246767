import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-responsive p-0" }
const _hoisted_2 = {
  key: 0,
  class: "table table-bordered text-nowrap",
  ref: "localTable"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items && _ctx.items.length)
      ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
          (_ctx.isVisibleHeader)
            ? (_openBlock(), _createElementBlock("thead", _hoisted_3, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (title) => {
                    return (_openBlock(), _createElementBlock("th", { key: title }, _toDisplayString(title), 1))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("tr", { key: item }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                  return (_openBlock(), _createElementBlock("td", { key: field }, _toDisplayString(item[field]), 1))
                }), 128))
              ]))
            }), 128))
          ])
        ], 512))
      : _createCommentVNode("", true)
  ]))
}