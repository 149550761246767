import { useVDialog } from '@/plugins/dialog/v-dialog';
import { activityService } from '@/services/activity';
import { applicationService } from '@/services/applications';
import { chatService } from '@/services/chat';
import { creditService } from '@/services/credit';
import { rfmService } from '@/services/rfm';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAccountDetail() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const model = reactive({ account: {} as any });
    const params = route.params;

    const fetch = async () => {
        let response = await applicationService.account.get(params.accountId);
        if (response.code === 200) {
            model.account = response.data;
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.go(-1);
            return;
        }

        response = await activityService.account.getLastLogin(params.accountId);
        if (response.code === 200) {
            model.account.lastLogin = response.data;
        } else if (response.code === 404) {
            model.account.lastLogin = {};
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.go(-1);
            return;
        }

        response = await creditService.getAvgPayment(params.accountId);
        if (response.code === 200) {
            model.account.avgPayment = response.data;
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.go(-1);
            return;
        }

        response = await rfmService().segmentation.get(params.accountId);
        if (response.code === 200) {
            model.account.rfmSegmentation = response.data;
        } else if (response.code === 404) {
            model.account.rfmSegmentation = {};
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.go(-1);
            return;
        }
    };

    const actions = {
        delete: () => {
            dialog.confirm({
                message: 'Are you sure you want to delete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.delete(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        undelete: () => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.undelete(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        ban: () => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        unban: () => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        mute: () => {
            dialog.confirm({
                message: 'Are you sure you want to mute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.mute(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        unmute: () => {
            dialog.confirm({
                message: 'Are you sure you want to unmute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unmute(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        banChat: () => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.ban(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        },
        unbanChat: () => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unban(params.accountId);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetch();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        addChip: () => router.push({ name: 'SendMessage', query: { accountIds: params.accountId } }),
        credit: () =>
            router.push({
                name: 'AppAccountTransaction',
                params: { accountId: params.accountId },
                query: { typeId: 0 }
            }),
        payment: () =>
            router.push({
                name: 'AppAccountTransaction',
                params: { accountId: params.accountId },
                query: { typeId: 1 }
            }),
        ruby: () =>
            router.push({
                name: 'AppAccountTransaction',
                params: { accountId: params.accountId },
                query: { typeId: 2 }
            }),
        activity: () => router.push({ name: 'AppAccountActivity', query: { criteria: params.accountId, type: 0 } })
    };

    return { model, fetch, actions, routes };
}
