import { useIAPList } from '@/modules/iap/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { piggybankService } from '@/services/piggybank';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, numeric, required, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePiggybankEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const { iapOptions, fetchIapOptions } = useIAPList();

    const model = reactive({
        id: null,
        name: null,
        packageId: '',
        price: 0,
        value: 0,
        baseLog: 0,
        fromAvgPayment: -1,
        toAvgPayment: 0,
        adsBoost: 0,
        adsBoostPercent: 0,
        adsBoostMin: 0,
        adsBoostSeconds: 0,
        totalLinked: null,
        status: null,
        image: null,
        imageStatus: null,
        imageFile: null,
        thumbnail: null,
        thumbnailStatus: null,
        thumbnailFile: null,
        canEnable: false,
        canDisable: false,
        canDelete: false
    });

    const validations = {
        imageFile: {},
        thumbnailFile: {},
        name: { required },
        packageId: { required },
        price: { required, numeric, min: minValue(1) },
        baseLog: { required, numeric, min: minValue(1) },
        value: { required, numeric, min: minValue(1) },
        adsBoost: { required, integer, min: minValue(0) },
        adsBoostPercent: { required, integer, min: minValue(0) },
        adsBoostMin: { required, integer, min: minValue(0) },
        adsBoostSeconds: { required, integer, min: minValue(0) },
        fromAvgPayment: { integer, min: minValue(-1) },
        toAvgPayment: {
            required: requiredIf(() => model.fromAvgPayment && model.fromAvgPayment >= 0),
            validValue: computed(() => minValue(model.fromAvgPayment + 1))
        }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await piggybankService.item.get(route.params.id);
        if (response.code === 200) {
            if (response.data.fromAvgPayment === -1) {
                response.data.fromAvgPayment = null;
            }

            if (response.data.toAvgPayment === -1) {
                response.data.toAvgPayment = null;
            }

            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.push({ name: 'PiggybankList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await piggybankService.item.update(
                model.id,
                model.name,
                model.packageId,
                model.price,
                model.value,
                model.baseLog,
                model.fromAvgPayment,
                model.toAvgPayment,
                model.adsBoost,
                model.adsBoostPercent,
                model.adsBoostMin,
                model.adsBoostSeconds,
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'PiggybankList' });
            } else {
                toast.error(response.data);
            }
        },
        disable: () => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.item.disable(model.id);
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        enable: () => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.item.enable(model.id);
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            fetch();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await piggybankService.item.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'PiggybankList' });
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        selectIAP: (iapId: any) => {
            iapOptions.value.forEach(item => {
                if (item.value == iapId) {
                    model.packageId = item.data.packageId;
                    model.price = item.data.price;
                    model.value = item.data.credit;
                }
            });
        }
    };

    const routes = {
        translate: () => router.push({ name: 'PiggybankTranslate', params: { id: model.id } })
    };

    return { model, v$, fetch, actions, routes, iapOptions, fetchIapOptions };
}
