import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-control custom-switch col-form-label" }
const _hoisted_2 = ["id", "name", "checked", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "custom-control-input",
      id: _ctx.ID,
      name: _ctx.ID,
      checked: _ctx.modelValue,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      class: "custom-control-label",
      for: _ctx.ID
    }, null, 8, _hoisted_3),
    _createVNode(_component_app_error, { validations: _ctx.validations }, null, 8, ["validations"])
  ]))
}