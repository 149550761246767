import { useVDialog } from '@/plugins/dialog/v-dialog';
import { surpriseService } from '@/services/surprise';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useSurpriseCategoryEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: '',
        isPublished: false
    });

    const validations = {
        name: { required },
        isPublished: { required }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await surpriseService.category.get(route.params.id);
        if (response.code === 200) {
            response.data.startTime = new Date(response.data.startTime);
            Object.assign(model, response.data);
        } else {
            toast.error(response.data, { timeout: 2000 });
            router.push({ name: 'SurpriseCategoryList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await surpriseService.category.update(model.id, model.name, model.isPublished);
            if (response.code === 200) {
                toast.success('Create successfully', { timeout: 2000 });
                router.push({ name: 'SurpriseCategoryList' });
            } else {
                toast.error(response.data, { timeout: 2000 });
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await surpriseService.category.remove(model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            router.push({ name: 'SurpriseCategoryList' });
                        } else {
                            toast.error(response.data, { timeout: 2000 });
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
