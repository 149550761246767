
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder } from '@/components';
import { useLeagueTierEdit } from '@/modules/league/tier/model/edit';

export default defineComponent({
    name: 'LeagueTierAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder },
    setup() {
        const { model, v$, fetch, actions, routes } = useLeagueTierEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, routes };
    }
});
