import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_time_picker = _resolveComponent("date-time-picker", true)!
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_date_time_picker, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _ctx.onValueChange,
      disabled: _ctx.disabled
    }, null, 8, ["model-value", "onUpdate:modelValue", "disabled"]),
    _createVNode(_component_app_error, { validations: _ctx.validations }, null, 8, ["validations"])
  ]))
}