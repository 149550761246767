import { useVDialog } from '@/plugins/dialog/v-dialog';
import { activityService } from '@/services/activity';
import { applicationService } from '@/services/applications';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { helpers, ipAddress, or, required } from '@vuelidate/validators';
import { reactive, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAccountActivity() {
    const dialog = useVDialog();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const model = reactive({
        filter: { type: 0, criteria: '' },
        isBanned: false
    });

    const validations = {
        filter: {
            criteria: {
                required,
                validIpAddress: helpers.withMessage(
                    `Invalid ip address`,
                    or(() => model.filter.type != 1, ipAddress)
                )
            }
        }
    };

    const v$ = useVuelidate(validations, model);

    const activityStore = {
        getPage: () => store.getters['accountActivity/page'],
        setPage: (page: number) => store.dispatch('accountActivity/changePage', page)
    };

    const { itemList, total, currentPage, changePage } = useFetchList(activityService.account.list, {
        pagingStore: activityStore,
        options: toRef(model, 'filter')
    });

    const fetchList = async (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        model.isBanned = false;
        await changePage(page);
        if (model.filter.type === 0) {
            const response = await applicationService.account.isBanned(model.filter.criteria);
            if (response.code == 200) {
                model.isBanned = response.data;
            }
        }
    };

    const actions = {
        ban: () => {
            if (model.filter.type !== 0) {
                return;
            }

            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(model.filter.criteria);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetchList(currentPage.value);
                        }
                    }
                }
            });
        },
        unban: () => {
            if (model.filter.type !== 0) {
                return;
            }

            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(model.filter.criteria);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            fetchList(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        address: (criteria: any) => router.push({ name: 'AppAccountActivity', query: { criteria: criteria, type: 1 } }),
        device: (criteria: any) => router.push({ name: 'AppAccountActivity', query: { criteria: criteria, type: 2 } })
    };

    watch(
        () => route.query,
        () => {
            model.filter.type = route.query?.type ? Number(route.query?.type) : null;
            model.filter.criteria = route.query?.criteria ? String(route.query?.criteria) : null;
            fetchList(1);
        }
    );

    return { model, v$, itemList, total, currentPage, fetchList, routes, actions };
}
