import { useVDialog } from '@/plugins/dialog/v-dialog';
import { leagueService } from '@/services/league';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useLeagueTierList() {
    const dialog = useVDialog();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const itemId = ref(route.params.itemId);
    const leagueItem = ref(null);

    const { itemList, total, fetchList } = useFetchList(leagueService.tier.list, {
        options: itemId
    });

    const beforeFetch = async () => {
        const response = await leagueService.item.get(itemId.value);
        if (response.code === 200) {
            leagueItem.value = response.data;
        } else {
            router.push({ name: 'LeagueList' });
        }
    };

    const actions = {
        remove: (tier: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await leagueService.tier.remove(itemId.value, tier);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'LeagueTierAdd', params: { itemId: itemId.value } }),
        edit: (tier: any) => router.push({ name: 'LeagueTierEdit', params: { itemId: itemId.value, tier: tier } }),
        reward: (tier: any) => router.push({ name: 'LeagueRewardList', params: { itemId: itemId.value, tier: tier } })
    };

    return { itemList, total, leagueItem, fetchList, beforeFetch, actions, routes };
}
