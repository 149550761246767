
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder, AppSwitch } from '@/components';
import { useAvatarBackgroundEdit } from '@/modules/avatar/background/model/edit';

export default defineComponent({
    name: 'AvatarBackgroundAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload, AppImageHolder, AppSwitch },
    setup() {
        const { model, v$, fetch, update } = useAvatarBackgroundEdit();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, fetch, update };
    }
});
