import { activityService } from '@/services/activity';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useActivityDetail() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const model = reactive({
        id: route.params.id,
        accountId: null,
        action: null,
        createTime: null,
        data: null,
        feature: null,
        firstname: null,
        lastname: null
    });

    const fetch = async () => {
        const response = await activityService.app.get(model.id);
        if (response.code === 200) {
            Object.assign(model, response.data);
        } else {
            toast.error(response.data);
            router.go(-1);
        }
    };

    return { model, fetch };
}
