import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarBundleItem() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const bundleId = route.params.bundleId;
    const bundle = ref(null);

    const { itemList, total, fetchList } = useFetchList(avatarService.bundle.listItem, {
        options: ref(bundleId)
    });

    const fetchItems = async () => {
        const response = await avatarService.bundle.get(bundleId);
        if (response.code !== 200) {
            toast.error(response.data);
            router.push({ name: 'SysAvatarBundleList' });
        }

        bundle.value = response.data;
        await fetchList();
    };

    const actions = {
        unlink: (itemId: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unlink this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.bundle.unlink(bundleId, itemId);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchItems();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        edit: (itemId: any) => router.push({ name: 'SysAvatarItemEdit', params: { itemId: itemId } }),
        image: (itemId: any) => router.push({ name: 'SysAvatarItemImage', params: { itemId: itemId } })
    };

    return { itemList, total, fetchItems, bundle, actions, routes };
}
