import { duplicateService } from '@/services/duplicate';
import { gameService } from '@/services/game';
import { timeUnits } from '@/utils/helpers';
import { useVuelidate } from '@vuelidate/core';
import { integer, minValue, required } from '@vuelidate/validators';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useDuplicateItemEdit() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const betList = ref([]);

    const beforeFetch = async () => {
        const [variantResponse, betResponse] = await Promise.all([
            gameService.variant.list(),
            gameService.bet.list({}, null)
        ]);

        if (variantResponse.code !== 200 || betResponse.code !== 200) {
            const errMessage = variantResponse.code !== 200 ? variantResponse.data : betResponse.data;
            toast.error(errMessage);
            router.push({ name: 'Home' });
            return;
        }

        const variants = variantResponse.data;
        const bets = betResponse.data;
        const result = [] as Array<any>;

        bets.filter((bet: any) => bet.type === 3).forEach((bet: any) => {
            const variant = variants.find((vari: any) => vari.id === bet.variantId);
            result.push({ value: bet.id, label: `${variant.name} - ${bet.name}`, cost: bet.cost, round: bet.maxRound });
        });

        betList.value = result;
    };

    const model = reactive({
        id: null,
        name: null,
        type: 0,
        betId: '',
        cost: 0,
        prizes: '',
        round: 0,
        game: 1,
        timeUnit: 0,
        timeDuration: 0,
        prepareSeconds: 0,
        openSeconds: 0,
        closeSeconds: 0,
        rankingSeconds: 0,
        publishTime: new Date(),
        unpublishTime: null,
        thumbnail: null,
        thumbnailFile: null
    });

    const validations = {
        name: { required },
        prepareSeconds: { required, integer, min: minValue(1) },
        openSeconds: { required, integer, min: minValue(1) },
        closeSeconds: { required, integer, min: minValue(1) },
        rankingSeconds: { required, integer, min: minValue(0) },
        unpublishTime: {}
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await duplicateService.item.get(route.params.id);
        if (response.code === 200) {
            const data = response.data;
            data.publishTime = new Date(data.publishTime);
            if (data.unpublishTime) {
                data.unpublishTime = new Date(data.unpublishTime);
            } else {
                data.unpublishTime = null;
            }

            Object.assign(model, data);
        } else {
            toast.error(response.data);
            router.push({ name: 'DuplicateItemList' });
        }
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await duplicateService.item.update(
                model.id,
                model.name,
                model.prepareSeconds,
                model.openSeconds,
                model.closeSeconds,
                model.rankingSeconds,
                model?.unpublishTime?.getTime() ?? null,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Successfully');
                router.push({ name: 'DuplicateItemList' });
            } else {
                toast.error(response.data);
            }
        }
    };

    return { model, v$, beforeFetch, fetch, betList, actions, timeUnits };
}
