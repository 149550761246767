import { usePromotionPrototypeList } from '@/modules/promotion/prototype/model/list';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { promotionService } from '@/services/promotion';
import { App } from '@/store/applications';
import { useFetchList } from '@/utils/useFetch';
import FileSaver from 'file-saver';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function usePromotionItemList() {
    const dialog = useVDialog();
    const toast = useToast();
    const router = useRouter();
    const store = useStore();

    const promotionItemStore = {
        getPage: () => store.getters['promotionItem/page'],
        setPage: (page: number) => store.dispatch('promotionItem/changePage', page),
        getFilter: () => store.getters['promotionItem/filter'],
        changeFilter: () => store.dispatch('promotionItem/changeFilter', { ...filter.value })
    };

    const currentApp = computed(() => {
        const apps = store.getters['application/apps'] as Array<App>;
        return apps.find(app => app.id == store.getters['application/appId']);
    });
    const storedFilter = promotionItemStore.getFilter();
    const filter = ref({
        type: storedFilter?.type ?? 0,
        status: storedFilter?.status ?? -1,
        rfmPersonaId: storedFilter?.rfmPersonaId ?? null
    } as { type: number; status: number; rfmPersonaId: number });

    const { personaOptions, fetchPersonaOptions } = useRfmPersonaList();
    const { itemAll: prototypeAll, fetchAll: fetchPrototypeAll } = usePromotionPrototypeList();

    const { itemList, total, currentPage, changePage } = useFetchList(promotionService.item.list, {
        pagingStore: promotionItemStore,
        options: filter,
        onSuccess: () => promotionItemStore.changeFilter()
    });

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        disable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to disable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.disable(id);
                        if (response.code === 200) {
                            toast.success('Disable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        enable: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to enable this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await promotionService.item.enable(id);
                        if (response.code === 200) {
                            toast.success('Enable successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        export: async () => {
            const response = await promotionService.item.export();
            if (response.code === 200) {
                const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                FileSaver.saveAs(blob, currentApp.value?.name.replace(/\s/g, '') + 'Promotion.csv');
            } else {
                toast.error(response.data);
            }
        },
        changeType: () => {
            filter.value.status = -1;
            filter.value.rfmPersonaId = null;
        }
    };

    const routes = {
        add: () => router.push({ name: 'PromotionItemAdd' }),
        edit: (id: any) => router.push({ name: 'PromotionItemEdit', params: { id: id } }),
        detail: (id: any) => router.push({ name: 'PromotionItemDetail', params: { id: id } }),
        clone: (id: any) => router.push({ name: 'PromotionItemClone', params: { id: id } }),
        translate: (id: any) => router.push({ name: 'PromotionItemTranslate', params: { id: id } })
    };

    return {
        itemList,
        total,
        currentPage,
        changePage,
        actions,
        routes,
        filter,
        fetchPersonaOptions,
        personaOptions,
        fetchPrototypeAll,
        prototypeAll,
        types,
        statusList
    };
}

const types = [
    { label: 'No filter', value: 0 },
    { label: 'Status', value: 1 },
    { label: 'Persona', value: 2 }
];

const statusList = [
    { label: 'All', value: -1 },
    { label: 'None', value: 0 },
    { label: 'Enabled', value: 1 },
    { label: 'Started', value: 2 },
    { label: 'Stopped', value: 3 },
    { label: 'Disabled', value: 4 }
];
