
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTournamentLeaderboardEdit } from '@/modules/tournament/leaderboard/model/edit';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'TournamentLeaderboardEdit',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useTournamentLeaderboardEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, timeUnits };
    }
});
