
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppCard, AppButton } from '@/components';
import { useMixins } from '@/modules/promotion/code/model/detail';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'PromotionCodeDetail',
    components: { AppCard, AppButton },
    setup() {
        const route = useRoute();
        const { model, options, fetch, actions } = useMixins();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { model, options, formatDate, actions };
    }
});
