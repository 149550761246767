
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppFileUpload, AppImageHolder, AppButton } from '@/components';
import { usePromotionItemEdit } from '@/modules/promotion/item/model/edit';

export default defineComponent({
    name: 'PromotionItemEdit',
    components: { AppFormRow, AppInput, AppCard, AppFileUpload, AppImageHolder, AppButton },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, update } = usePromotionItemEdit();

        onMounted(async () => {
            await fetch(route.params.id);
        });

        return { v$, model, update };
    }
});
