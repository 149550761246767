import http from '@/utils/axios';

export const messageService = {
    add: (accountIds: any, creditType: any, amount: any) => {
        return http.doPost('/admin/message', {
            accountIds: accountIds,
            creditType: creditType,
            amount: amount
        });
    },

    send: (messageType: any, accountIds: any, creditType: any, amount: any) => {
        return http.doPost('/admin/message/' + messageType, {
            accountIds: accountIds,
            creditType: creditType,
            amount: amount
        });
    }
};
