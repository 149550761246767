
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useBonusLoginAds } from '@/modules/bonus/login/model/ads';

export default defineComponent({
    name: 'BonusLoginEditAds',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useBonusLoginAds();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions };
    }
});
