
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useArenaTicketAdd } from '@/modules/arena/ticket/model/add';

export default defineComponent({
    name: 'ArenaTicketAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, create } = useArenaTicketAdd();

        return { v$, model, create, typeList };
    }
});

const typeList = [
    { label: 'Standard', value: 1 },
    { label: 'Premium', value: 2 }
];
