
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useProgressList } from '@/modules/progress/model/list';
import { formatDate } from '@/utils/helpers';

export default defineComponent({
    name: 'ProgressList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, currentPage, changePage, routes, actions } = useProgressList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Status', display: (row: any) => renderStatus(row.status) },
                { label: 'From Time', display: (row: any) => formatDate(row.fromTime) },
                { label: 'To Time', display: (row: any) => formatDate(row.toTime) },
                { label: 'Total Level', field: 'totalLevel' },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-tasks',
                        theme: 'info',
                        tooltip: 'View levels',
                        onclick: () => routes.level(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        onclick: () => routes.clone(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            changePage(currentPage.value);
        });

        return { table, changePage, routes };
    }
});

const statusList = [
    { label: 'None', value: 0, background: 'secondary' },
    { label: 'Enabled', value: 1, background: 'success' },
    { label: 'Started', value: 2, background: 'info' },
    { label: 'Stoped', value: 3, background: 'warning' },
    { label: 'Disabled', value: 4, background: 'warning' }
];

function renderStatus(status: any) {
    const type = statusList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
