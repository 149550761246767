
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useRfmRecency } from '@/modules/rfm/recency/model/edit';

export default defineComponent({
    name: 'RecencyEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const route = useRoute();
        const { model, v$, fetch, rfmGroupOptions, beforeFetch, actions } = useRfmRecency();

        onMounted(async () => {
            await beforeFetch();
            await fetch(route.params.id);
        });

        return { v$, model, rfmGroupOptions, actions };
    }
});
