
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppTable, AppCard, AppButton, AppImageHolder } from '@/components';
import { IAction, IColumn } from '@/components/table/model';
import { useProgressLevelList } from '@/modules/progress/level/model/list';

export default defineComponent({
    name: 'ProgressLevelList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, routes, actions } = useProgressLevelList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'Level', field: 'level' },
                { label: 'Name', field: 'name' },
                { label: 'Type', display: (row: any) => renderType(row.type) },
                { label: 'Reward', field: 'reward' },
                { label: 'Quantity', field: 'quantity' },
                { label: 'Item Id', field: 'itemId' },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Actions', field: 'level' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.level)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.level)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(() => {
            fetchList();
        });

        return { table, fetchList, routes };
    }
});

const typeList = [
    { label: 'Totem', value: 1, background: 'info' },
    { label: 'CompleteGame', value: 2, background: 'info' },
    { label: 'WinGame', value: 3, background: 'info' },
    { label: 'Promotion', value: 4, background: 'info' },
    { label: 'ArenaComplete', value: 5, background: 'info' },
    { label: 'ArenaWin', value: 6, background: 'info' },
    { label: 'SlotComplete', value: 7, background: 'info' },
    { label: 'SlotWin', value: 8, background: 'info' },
    { label: 'ScratchCardComplete', value: 10, background: 'info' },
    { label: 'ScratchCardWin', value: 11, background: 'info' },
    { label: 'DuplicateGameComplete', value: 12, background: 'info' }
];

function renderType(status: any) {
    const type = typeList.find(item => item.value === status);
    return `<span class='badge badge-${type?.background}'>${type?.label}</span>`;
}
