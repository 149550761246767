import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarLayerList() {
    const router = useRouter();
    const toast = useToast();
    const vDialog = useVDialog();

    const { itemList, total, fetchList } = useFetchList(avatarService.layer.list, null);

    const actions = {
        remove: (id: any) => {
            vDialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async result => {
                    if (result) {
                        const response = await avatarService.layer.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarLayerAdd' }),
        edit: (id: any) => router.push({ name: 'SysAvatarLayerEdit', params: { layerId: id } })
    };

    return { itemList, total, fetchList, actions, routes };
}
