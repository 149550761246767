
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload } from '@/components';
import { useLeagueTierAdd } from '@/modules/league/tier/model/add';

export default defineComponent({
    name: 'LeagueTierAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppFileUpload },
    setup() {
        const { model, v$, create } = useLeagueTierAdd();

        return { v$, model, create };
    }
});
