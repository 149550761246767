
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard, AppSelect, AppFileUpload, AppDateTimePicker } from '@/components';
import useChallengeAdd from '@/modules/challenge/model/add';

export default defineComponent({
    name: 'ChallengeCreate',
    components: { AppFormRow, AppInput, AppButton, AppSelect, AppCard, AppDateTimePicker, AppFileUpload },
    setup() {
        const { v$, model, appOptions, create } = useChallengeAdd();

        return { v$, model, create, appOptions };
    }
});
