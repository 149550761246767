import { duplicateService } from '@/services/duplicate';
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

export function useDuplicateCycleDeck() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const itemList = ref([]);

    const fetch = async () => {
        const params = route.params;

        const response = await duplicateService.cycle.getDeck(params.itemId, params.cycleId);
        if (response.code === 200) {
            itemList.value = response.data;
        } else {
            toast.error(response.data);
            router.push({ name: 'DuplicateItemList' });
        }
    };

    const routes = {
        deck: () => router.push({ name: 'DuplicateSetting' })
    };

    return { itemList, fetch, routes };
}
