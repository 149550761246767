import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_date_time_picker = _resolveComponent("app-date-time-picker")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Gift Lucky"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Amount" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.amount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.amount) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.amount
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Receive Limit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.receiveLimit,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.receiveLimit) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.receiveLimit
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Game Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.gamePercent,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.gamePercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.gamePercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Slot Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.slotPercent,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.slotPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.slotPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Quest Percent" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.questPercent,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.questPercent) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.questPercent
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Unit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.timeUnits,
              modelValue: _ctx.model.timeUnit,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.timeUnit
            }, null, 8, ["options", "modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time timeDuration" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.timeDuration,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
              validations: _ctx.v$.timeDuration
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Start Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.startTime,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.startTime) = $event)),
              validations: _ctx.v$.startTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "End Time" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_date_time_picker, {
              modelValue: _ctx.model.endTime,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.endTime) = $event)),
              validations: _ctx.v$.endTime
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Save",
              icon: "fa-save",
              theme: "info",
              onClick: _withModifiers(_ctx.actions.update, ["prevent"])
            }, null, 8, ["onClick"]),
            (!_ctx.model.isNew)
              ? (_openBlock(), _createBlock(_component_app_button, {
                  key: 0,
                  name: "Delete",
                  icon: "fa-trash-alt",
                  theme: "danger",
                  size: "md",
                  onClick: _withModifiers(_ctx.actions.remove, ["prevent"])
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}