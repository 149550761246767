
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSwitch, AppButton, AppImageHolder, AppFileUpload } from '@/components';
import { useSurpriseItemEdit } from '@/modules/surprise/category/item/model/edit';

export default defineComponent({
    name: 'SurpriseItemAdd',
    components: { AppFormRow, AppInput, AppCard, AppSwitch, AppButton, AppImageHolder, AppFileUpload },
    setup() {
        const { model, v$, fetch, actions } = useSurpriseItemEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions };
    }
});
