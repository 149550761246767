
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useLeagueAdd } from '@/modules/league/model/add';

export default defineComponent({
    name: 'LeagueAdd',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, v$, create, timeUnits } = useLeagueAdd();

        return { v$, model, create, timeUnits };
    }
});
