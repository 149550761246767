import { challengeService } from '@/services/challenge';
import { App } from '@/store/applications';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export default function () {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        id: null,
        name: null,
        toApp: null,
        amount: null,
        link: null,
        startTime: null,
        endTime: null,
        image: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        link: { required },
        imageFile: {},
        amount: { required, numeric, minValue: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await challengeService.get(route.params.id);
        if (response.code === 200) {
            const result = response.data;
            if (result.startTime) {
                result.startTime = new Date(result.startTime);
            }

            if (result.endTime) {
                result.endTime = new Date(result.endTime);
            }

            Object.assign(model, response.data);
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await challengeService.update(model.id, model.name, model.amount, model.link, model.imageFile);

        if (response.code === 200) {
            toast.success('Update successfully', { timeout: 2000 });
            router.push({ name: 'ChallengeList' });
        }
    };

    const appOptions = computed(() => {
        const apps = store.getters['application/apps'] as Array<App>;
        return apps.map(app => ({ label: app.name, value: app.id }));
    });

    return { v$, model, appOptions, fetch, update };
}
