
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTicketEdit } from '@/modules/scratch/ticket/model/edit';

export default defineComponent({
    name: 'ScratchEdit',
    components: { AppFormRow, AppInput, AppSelect, AppDateTimePicker, AppButton, AppCard },
    setup() {
        const route = useRoute();
        const { model, v$, timeUnits, fetch, update } = useTicketEdit();

        onMounted(() => {
            fetch(route.params.id);
        });

        return { model, v$, types, timeUnits, update };
    }
});

export const types = [
    { value: 1, label: 'Silver' },
    { value: 2, label: 'Golden' }
];
