import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/stripe');
};

const remove = () => {
    return http.doDelete('/admin/stripe');
};

const update = (secretKey: string, webhookSecretKey: string) => {
    return http.doPut('/admin/stripe', {
        secretKey: secretKey,
        webhookSecretKey: webhookSecretKey
    });
};

export function stripeService() {
    return {
        get,
        remove,
        update
    };
}
