import { giftService } from '@/services/gift';
import useVuelidate from '@vuelidate/core';
import { minValue, numeric, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default function () {
    const toast = useToast();

    const model = reactive({
        sendLimit: 0,
        receiveLimit: 0,
        amount: 0
    });

    const validations = {
        sendLimit: { required, numeric, minValue: minValue(1) },
        receiveLimit: { required, numeric, minValue: minValue(1) },
        amount: { required, numeric, minValue: minValue(1) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await giftService().setting.get();
        if (response.code === 200) {
            const result = response.data;
            model.sendLimit = result.sendLimit;
            model.amount = result.amount;
            model.receiveLimit = result.receiveLimit;
        } else if (response.code === 404) {
            model.sendLimit = 0;
            model.amount = 0;
            model.receiveLimit = 0;
        }
    };

    const update = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await giftService().setting.update(model.sendLimit, model.receiveLimit, model.amount);
        if (response.code === 200) {
            toast.success('Update successfully');
            fetch();
        }
    };

    return { model, v$, fetch, update };
}
