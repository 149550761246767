import { useVDialog } from '@/plugins/dialog/v-dialog';
import { shopService } from '@/services/shop';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default function () {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(shopService().item.list, null);

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await shopService().item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ShopItemAdd' }),
        edit: (id: any) => router.push({ name: 'ShopItemEdit', params: { id: id } }),
        clone: (id: any) => router.push({ name: 'ShopItemClone', params: { id: id } }),
        translate: (id: any) => router.push({ name: 'ShopItemTranslate', params: { id: id } })
    };

    return { itemList, total, fetchList, actions, routes };
}
