import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { avatarService } from '@/services/avatar';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImageAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const avatarItem = ref(null);
    const { fetchList: fetchColors, itemList: colors } = useAvatarColorList();
    const { fetchList: fetchLayers, itemList: layers } = useAvatarLayerList();
    const colorOption = computed(() => colors.value.map(c => ({ label: c.name, value: c.id })));
    const layerOption = computed(() => layers.value.map(l => ({ label: l.name, value: l.id })));

    const model = reactive({
        itemId: null,
        colorId: null,
        layerId: null,
        isDefault: false,
        imageFile: null
    });

    const fetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);

        const response = await avatarService.item.get(route.params.itemId);
        if (response.code === 200) {
            avatarItem.value = response.data;
            model.itemId = route.params.itemId;
            model.colorId = colorOption.value?.[0]?.value;
            model.layerId = layerOption.value?.[0]?.value;
        } else {
            toast.error(response.data);
            router.push({ name: 'SysAvatarItemImageList' });
        }
    };

    const create = async () => {
        const response = await avatarService.image.add(
            model.itemId,
            model.colorId,
            model.layerId,
            model.isDefault,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Add successfully');
            router.push({ name: 'SysAvatarItemImageList', params: { itemId: model.itemId } });
        }
    };

    return { model, colorOption, layerOption, avatarItem, fetch, create };
}
