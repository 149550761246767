
import { defineComponent, reactive, onMounted, toRef } from 'vue';
import { AppTable, AppCard, AppButton } from '@/components';
import { useLeagueList } from '@/modules/league/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate, renderTimeUnit } from '@/utils/helpers';

export default defineComponent({
    name: 'LeagueList',
    components: { AppTable, AppCard, AppButton },
    setup() {
        const { itemList, total, fetchList, actions, routes } = useLeagueList();

        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            columns: [
                { label: 'ID', field: 'id' },
                { label: 'Time Unit', display: (row: any) => renderTimeUnit(row.timeUnit) },
                { label: 'Max Player', field: 'maxPlayer' },
                { label: 'Base Point', field: 'basePoint' },
                { label: 'default Point', field: 'defaultPoint' },
                { label: 'Start Time', display: (row: any) => formatDate(row.startTime) },
                { label: 'Next Start Time', display: (row: any) => formatDate(row.nextStartTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-layer-group',
                        theme: 'info',
                        tooltip: 'Tier',
                        onclick: () => routes.tier(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        isShow: row.canEdit,
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        onMounted(async () => {
            await fetchList();
        });

        return { table, fetchList, routes };
    }
});
