import { reactive, ref } from 'vue';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { useVuelidate } from '@vuelidate/core';
import { progressService } from '@/services/progress';
import { useRoute, useRouter } from 'vue-router';

export function useProgressLevelAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const progressId = route.params.progressId;
    const progressInfo = ref({ name: '' } as any);
    const linkableItems = ref([]);

    const fetch = async () => {
        const [progressResponse, linkableResponse] = await Promise.all([
            progressService.item.get(progressId),
            progressService.level.listLinkableItem(progressId)
        ]);

        if (progressResponse.code !== 200 || linkableResponse.code !== 200) {
            const errMessage = progressResponse.code !== 200 ? progressResponse.data : linkableResponse.data;
            toast.error(errMessage);
            router.push({ name: 'ProgressList' });
            return;
        }

        progressInfo.value = progressResponse.data;

        const itemList: Array<any> = linkableResponse.data;
        linkableItems.value = itemList.map(item => {
            return { value: item.id, label: item.name };
        });
    };

    const model = reactive({
        progressId: progressId,
        name: '',
        type: null,
        reward: null,
        quantity: null,
        itemId: null,
        imageFile: null
    });

    const validations = {
        name: { required },
        type: { required }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const response = await progressService.level.create(
            progressId,
            model.name,
            model.type,
            model.reward,
            model.quantity,
            model.itemId,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'ProgressLevelList', params: { progressId: progressId } });
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create, fetch, progressInfo, linkableItems };
}
