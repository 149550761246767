import { useVDialog } from '@/plugins/dialog/v-dialog';
import { chatService } from '@/services/chat';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useChatSentenceList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(chatService.sentence.list, null);

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.sentence.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully', { timeout: 2000 });
                            fetchList();
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ChatSentenceAdd' }),
        edit: (id: any) => router.push({ name: 'ChatSentenceEdit', params: { id: id } }),
        translate: (id: any) => router.push({ name: 'ChatSentenceTranslate', params: { id: id } })
    };

    return { itemList, total, fetchList, actions, routes };
}
