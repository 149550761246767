import { useIAPList } from '@/modules/iap/model/list';
import { shopService } from '@/services/shop';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useMixins } from './mixins';

export function useShopItemAdd() {
    const router = useRouter();
    const toast = useToast();

    const { model, v$ } = useMixins();
    const { itemList: iapList, fetchList } = useIAPList();

    const fetch = async (id: any = null) => {
        await fetchList();
        model.iapItems = iapList.value;
        model.iapOptions = iapList.value.map(item => ({ value: item.id, label: item.name }));

        // clone case:
        if (id) {
            const response = await shopService().item.get(id);
            if (response.code === 200) {
                Object.assign(model, response.data);
            } else {
                toast.error(response.data || 'failed');
                router.push({ name: 'ShopItemList' });
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await shopService().item.create(
                model.id,
                model.name,
                model.price,
                model.credit,
                model.isPublished,
                model.packageId,
                model.isBestseller,
                model.imageFile,
                model.thumbnailFile
            );

            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'ShopItemList' });
            }
        },
        selectIAP: (iapId: any) => {
            model.iapItems.forEach(item => {
                if (item.id == iapId) {
                    model.packageId = item.packageId;
                    model.price = item.price;
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
