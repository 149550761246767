import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Add Quest Boost"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "IAP Item" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.iapOptions,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.selectIAP($event.target.value)))
            }, null, 8, ["options"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Package ID" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              modelValue: _ctx.model.packageId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.packageId) = $event)),
              validations: _ctx.v$.packageId
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Price" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.price,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.price) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.price
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Ratio" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.ratio,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.ratio) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.ratio
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Unit" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_select, {
              options: _ctx.timeUnits,
              modelValue: _ctx.model.timeUnit,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.timeUnit) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_app_form_row, { label: "Time Duration" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_input, {
              type: "number",
              modelValue: _ctx.model.timeDuration,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.timeDuration) = $event)),
              modelModifiers: { number: true },
              validations: _ctx.v$.timeDuration
            }, null, 8, ["modelValue", "validations"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: " Create",
              theme: "info",
              icon: "fa-save",
              onClick: _withModifiers(_ctx.actions.create, ["prevent"])
            }, null, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}