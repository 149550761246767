import { reactive } from 'vue';
import { minValue, required } from '@vuelidate/validators';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useVuelidate } from '@vuelidate/core';
import { surpriseService } from '@/services/surprise';

export function useSurpriseItemAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        price: 0,
        isPublished: false,
        imageFile: null
    });

    const validations = {
        name: { required },
        price: { minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const create = async () => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        const categoryId = route.params.categoryId;
        const response = await surpriseService.item.create(
            model.name,
            categoryId,
            model.price,
            model.isPublished,
            model.imageFile
        );

        if (response.code === 200) {
            toast.success('Create successfully');
            router.push({ name: 'SurpriseItemList', params: { categoryId: categoryId } });
        } else {
            toast.error(response.data);
        }
    };

    return { model, v$, create };
}
