
import { defineComponent } from 'vue';
import { AppFormRow, AppFileUpload, AppCard, AppButton } from '@/components';
import { useAvatarImport } from '@/modules/avatar/import/model/import';

export default defineComponent({
    name: 'ImportAvatar',
    components: { AppFormRow, AppFileUpload, AppCard, AppButton },
    setup() {
        const { model, importFile } = useAvatarImport();

        return { model, importFile };
    }
});
