
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'app-form-row',
    props: {
        label: {
            type: String,
            default: ''
        }
    }
});
