import { useVDialog } from '@/plugins/dialog/v-dialog';
import { useVModal } from '@/plugins/modal/v-modal';
import { avatarService } from '@/services/avatar';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { reactive, toRef, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

const isNewOptions = [
    { label: 'Not specified', value: '0', mapping: null },
    { label: 'No', value: '1', mapping: false },
    { label: 'Yes', value: '2', mapping: true }
];

export function useAvatarItemList() {
    const vModal = useVModal();
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const avatarItemStore = {
        getPage: () => store.getters['avatarItem/page'],
        setPage: (page: number) => store.dispatch('avatarItem/changePage', page)
    };

    const model = reactive({
        isNewSelected: (route.query?.isNew ?? null) as string,
        query: {
            categoryId: route.query?.categoryId ?? null,
            name: (route.query?.name ?? null) as string,
            isNew: null
        },
        bundle: {
            selectedItemId: null,
            linkableBundle: []
        }
    });

    const validations = {
        query: {
            name: { min: minLength(3) }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, changePage } = useFetchList(avatarService.item.list, {
        pagingStore: avatarItemStore,
        options: toRef(model, 'query')
    });

    const fetchItems = async (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        changePage(page);
    };

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.item.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetchItems(currentPage.value);
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        },
        linkBundle: async () => {
            if (model.bundle.selectedItemId && model.bundle.linkableBundle.length) {
                const bundleIds = model.bundle.linkableBundle.filter(item => item.hasLinked).map(item => item.id);
                const response = await avatarService.bundle.updateForItem(
                    model.bundle.selectedItemId,
                    bundleIds.join(',')
                );

                if (response.code === 200) {
                    toast.success('Update successfully');
                } else {
                    toast.error(response.data);
                }
            }

            actions.closeLinkBundle();
        },
        openLinkBundle: async (id: any) => {
            const response = await avatarService.bundle.getListForItem(id);
            if (response.code === 200) {
                model.bundle.selectedItemId = id;
                model.bundle.linkableBundle = response.data;
                vModal.open({ name: 'linkbundle' });
            } else {
                toast.error(response.data);
            }
        },
        closeLinkBundle: () => {
            model.bundle = { selectedItemId: null, linkableBundle: [] };
            vModal.close({ name: 'linkbundle' });
        }
    };

    const routes = {
        add: () => router.push({ name: 'SysAvatarItemAdd', query: { categoryId: model.query.categoryId } }),
        edit: (itemId: any) => router.push({ name: 'SysAvatarItemEdit', params: { itemId: itemId } }),
        image: (itemId: any) => router.push({ name: 'SysAvatarItemImageList', params: { itemId: itemId } })
    };

    watchEffect(() => {
        model.query.isNew = isNewOptions.find(option => option.value == model.isNewSelected)?.mapping;
    });

    return { model, v$, itemList, total, currentPage, fetchItems, actions, routes, isNewOptions };
}
