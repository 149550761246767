import { useVDialog } from '@/plugins/dialog/v-dialog';
import { applicationService } from '@/services/applications';
import { chatService } from '@/services/chat';
import { useFetchList } from '@/utils/useFetch';
import useVuelidate from '@vuelidate/core';
import { email, minLength, minValue, required, requiredIf } from '@vuelidate/validators';
import { computed, reactive, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export function useAccountList() {
    const dialog = useVDialog();
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const accountStore = {
        getPage: () => store.getters['account/page'],
        setPage: (page: number) => store.dispatch('account/changePage', page),
        getFilter: () => store.getters['account/filter'],
        setFilter: () => store.dispatch('account/changeFilter', { ...model.filter })
    };

    const model = reactive({
        filter: { ...accountStore.getFilter() },
        types: [
            { label: 'No filter', value: 0 },
            { label: 'Name', value: 1 },
            { label: 'Email', value: 2 },
            { label: 'Facebook Id', value: 3 },
            { label: 'Account Id', value: 4 },
            { label: 'Bought credit', value: 5 }
        ]
    });

    const validations = {
        filter: {
            criteria: {
                requiredIf: requiredIf(() => model.filter.type != 0),
                valueValid: computed(() => {
                    switch (model.filter.type) {
                        case 1:
                            return minLength(3);
                        case 2:
                            return email;
                        case 3:
                            return required;
                        case 4:
                        case 5:
                            return minValue(0);
                        default:
                            return true;
                    }
                })
            }
        }
    };

    const v$ = useVuelidate(validations, model);

    const { itemList, total, currentPage, changePage } = useFetchList(applicationService.account.list, {
        pagingStore: accountStore,
        options: toRef(model, 'filter'),
        onSuccess: () => accountStore.setFilter()
    });

    const fetchList = (page: number) => {
        v$.value.$touch();
        if (v$.value.$invalid) {
            return;
        }

        changePage(page);
    };

    const routes = {
        detail: (id: any) => router.push({ name: 'AppAccountDetail', params: { accountId: id } }),
        addChip: (id: any) => router.push({ name: 'SendMessage', query: { accountIds: id } }),
        credit: (accountId: any) =>
            router.push({ name: 'AppAccountTransaction', params: { accountId: accountId }, query: { typeId: 0 } }),
        payment: (accountId: any) =>
            router.push({ name: 'AppAccountTransaction', params: { accountId: accountId }, query: { typeId: 1 } }),
        ruby: (accountId: any) =>
            router.push({ name: 'AppAccountTransaction', params: { accountId: accountId }, query: { typeId: 2 } }),
        activity: (accountId: any) =>
            router.push({ name: 'AppAccountActivity', query: { criteria: accountId, type: 0 } })
    };

    const actions = {
        delete: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to delete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.delete(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        undelete: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to undelete this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.undelete(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        ban: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unban: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await applicationService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        mute: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to mute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.mute(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unmute: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unmute this account?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unmute(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        banChat: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to ban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.ban(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        },
        unbanChat: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to unban this account from chat?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await chatService.account.unban(id);
                        if (response.code === 200) {
                            toast.success('Successfully', { timeout: 2000 });
                            model.filter = { ...accountStore.getFilter() };
                            changePage(currentPage.value);
                        }
                    }
                }
            });
        }
    };

    return { v$, model, itemList, total, currentPage, fetchList, routes, actions };
}
