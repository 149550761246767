import { avatarService } from '@/services/avatar';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarBundleAdd() {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
        name: '',
        gender: 0
    });

    const validations = {
        name: { required },
        gender: { required }
    };

    const v$ = useVuelidate(validations, model);

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await avatarService.bundle.create(model.name, model.gender);
            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'SysAvatarBundleList' });
            }
        }
    };

    return { model, v$, actions, genderList };
}

const genderList = [
    { label: 'Female', value: 0 },
    { label: 'Male', value: 1 }
];
