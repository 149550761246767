import { accountService } from '@/services/account';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useMain() {
    const store = useStore();

    const darkModeSelected = process.env.VUE_APP_DARK_MODE.toLowerCase() == 'true';
    const menuSidebarCollapsed = computed(() => store.getters['ui/menuSidebarCollapsed']);
    const controlSidebarCollapsed = computed(() => store.getters['ui/controlSidebarCollapsed']);
    const screenSize = computed(() => store.getters['ui/screenSize']);
    const isLoading = computed(() => store.getters['ui/isLoading']);

    const fetchProfile = async () => {
        const response = await accountService.get();
        if (response.code === 200) {
            const user = response.data;
            store.dispatch('auth/setUser', user);
        } else {
            store.dispatch('auth/logout');
        }
    };

    const onToggleMenuSidebar = () => {
        store.dispatch('ui/toggleMenuSidebar');
    };

    return {
        darkModeSelected,
        menuSidebarCollapsed,
        controlSidebarCollapsed,
        screenSize,
        isLoading,
        fetchProfile,
        onToggleMenuSidebar
    };
}
