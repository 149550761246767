
import { defineComponent, reactive, onMounted, toRef, markRaw } from 'vue';
import { AppCard, AppTable, AppButton, AppSelect, AppFormRow, AppImageHolder } from '@/components';
import { usePromotionItemList } from '@/modules/promotion/item/model/list';
import { IAction, IColumn } from '@/components/table/model';
import { formatDate } from '@/utils/helpers';
import { RouterLink } from 'vue-router';

export default defineComponent({
    name: 'PromotionItemList',
    components: { AppTable, AppButton, AppSelect, AppFormRow, AppCard },
    setup() {
        const {
            itemList,
            total,
            currentPage,
            changePage,
            actions,
            routes,
            filter,
            fetchPersonaOptions,
            personaOptions,
            fetchPrototypeAll,
            prototypeAll,
            types,
            statusList
        } = usePromotionItemList();

        // Init table settings
        const table = reactive({
            rows: toRef(itemList, 'value'),
            totalRecordCount: toRef(total, 'value'),
            page: toRef(currentPage, 'value'),
            columns: [
                { label: 'Id', field: 'id' },
                { label: 'Name', field: 'name' },
                {
                    label: 'Used Prototype Id',
                    component: (row: any) => ({
                        type: getPrototypeById(row.usedPrototypeId) ? markRaw(RouterLink) : 'span',
                        message: getPrototypeName(row.usedPrototypeId),
                        props: { to: `/app/promotion/prototype/${row.usedPrototypeId}/detail` }
                    })
                },
                { label: 'Display', display: (row: any) => rederDisplayColumn(row.displayType) },
                {
                    label: 'Image',
                    component: (row: any) => ({
                        type: markRaw(AppImageHolder),
                        props: { image: row.image, status: row.imageStatus }
                    })
                },
                { label: 'Status', display: (row: any) => rederStatusColumn(row.status) },
                { label: 'To Time', display: (row: any) => formatDate(row.toTime) },
                { label: 'Actions', field: 'id' }
            ] as Array<IColumn>,
            actions: [
                {
                    props: (row: any) => ({
                        icon: 'fa-eye',
                        theme: 'info',
                        tooltip: 'Detail',
                        onclick: () => routes.detail(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-edit',
                        theme: 'info',
                        tooltip: 'Edit',
                        onclick: () => routes.edit(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-language',
                        theme: 'info',
                        tooltip: 'Translate',
                        onclick: () => routes.translate(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-copy',
                        theme: 'info',
                        tooltip: 'Clone',
                        onclick: () => routes.clone(row.id)
                    }),
                    group: 0
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-play',
                        theme: 'success',
                        tooltip: 'Enable',
                        isShow: row.canEnable,
                        onclick: () => actions.enable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-stop',
                        theme: 'warning',
                        tooltip: 'Disable',
                        isShow: row.canDisable,
                        onclick: () => actions.disable(row.id)
                    }),
                    group: 1
                },
                {
                    props: (row: any) => ({
                        icon: 'fa-trash-alt',
                        theme: 'danger',
                        tooltip: 'Delete',
                        disabled: !row.canDelete,
                        onClick: () => actions.remove(row.id)
                    })
                }
            ] as Array<IAction>
        });

        const getPrototypeById = (itemId: number) => prototypeAll.value.find(item => item.id === itemId);
        const getPrototypeName = (itemId: number) => {
            const prototype = getPrototypeById(itemId);
            return itemId + (prototype ? ' - ' + prototype.name : '');
        };

        onMounted(async () => {
            await fetchPersonaOptions();
            await fetchPrototypeAll();
            await changePage(currentPage.value);
        });

        return { table, changePage, routes, actions, filter, personaOptions, types, statusList };
    }
});

const rederDisplayColumn = (displayType: number) => {
    switch (displayType) {
        case 0:
            return `<span class='badge badge-success'>Shop</span>`;
        case 1:
            return `<span class='badge badge-success'>Popup</span>`;
        case 2:
            return `<span class='badge badge-success'>Event</span>`;
        default:
            return `<span class='badge badge-success'>Progressive</span>`;
    }
};

const rederStatusColumn = (status: number) => {
    switch (status) {
        case 0:
            return `<span class='badge badge-info'>None</span>`;
        case 1:
            return `<span class='badge badge-success'>Enabled</span>`;
        case 2:
            return `<span class='badge badge-success'>Started</span>`;
        case 3:
            return `<span class='badge badge-warning'>Stopped</span>`;
        case 4:
            return `<span class='badge badge-warning'>Disabled</span>`;
    }
};
