import { useIAPList } from '@/modules/iap/model/list';
import { useRfmGroupList } from '@/modules/rfm/group/model/list';
import { useRfmPersonaList } from '@/modules/rfm/persona/model/list';
import { promotionService } from '@/services/promotion';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function usePromotionPrototypeAdd() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { rfmGroupOptions, fetchRfmGroupOptions } = useRfmGroupList();
    const { itemList: personaList, fetchList: getPersonaList } = useRfmPersonaList();
    const { itemList: ipaList, fetchList: getIapList } = useIAPList();

    const model = reactive({
        id: null,
        rfmGroupId: null,
        name: '',
        list: [],
        iapOptions: []
    });

    const validations = {
        name: { required },
        rfmGroupId: { required }
    };

    const validateModel = computed(() => {
        if (isBlank(model.name)) {
            return false;
        }
        if (model.list.filter(i => !i.canAdd).length > 0) {
            return false;
        }

        return true;
    });

    const v$ = useVuelidate(validations, model);

    const beforeFetch = async () => {
        await fetchRfmGroupOptions();
        await getIapList();
        await getPersonaList();

        model.iapOptions = ipaList.value.map(item => {
            return { value: item.id, label: item.packageId };
        });
    };

    const fetch = async () => {
        if (!model.rfmGroupId) {
            return;
        }

        const personaListByGroup = personaList.value.filter(item => item.groupId == model.rfmGroupId);

        model.list = [];
        for (let i = 0; i < personaListByGroup.length; i++) {
            const data = {
                index: i,
                personaId: personaListByGroup[i].id,
                personaName: personaListByGroup[i].name,
                iapId: 0,
                packageId: '',
                price: 0,
                credit: 0,
                rate: 0,
                canAdd: false
            };

            model.list.push(data);
        }

        // clone case
        if (route.params.id) {
            const response = await promotionService.prototype.get(route.params.id);
            if (response.code === 200) {
                const result = response.data;

                model.id = route.params.id;
                model.list.forEach((item: any) => {
                    const prototypeItem = result.find((proto: any) => proto.rfmPersonaId == item.personaId);

                    const iapItem = prototypeItem
                        ? ipaList.value.find(
                              (iap: any) =>
                                  prototypeItem.packageId == iap.packageId &&
                                  prototypeItem.price == iap.price &&
                                  prototypeItem.credit == iap.credit
                          )
                        : null;

                    if (prototypeItem && iapItem) {
                        item.iapId = iapItem.id;
                        item.packageId = prototypeItem.packageId;
                        item.price = prototypeItem.price;
                        item.rate = prototypeItem.rate;
                        item.credit = prototypeItem.credit;
                        actions.validateItem(item);
                    }
                });
            } else {
                toast.error(response.data);
                router.push({ name: 'PromotionPrototypeList' });
            }
        }
    };

    const actions = {
        create: async () => {
            v$.value.$touch();
            if (v$.value.$invalid || !validateModel.value) {
                return;
            }

            const personaList: any[] = [];
            const packageList: any[] = [];
            const priceList: any[] = [];
            const creditList: any[] = [];
            const rateList: any[] = [];

            model.list.forEach(item => {
                const index = item.index;
                if (item.canAdd) {
                    personaList[index] = item.personaId;
                    packageList[index] = item.packageId;
                    priceList[index] = item.price;
                    creditList[index] = item.credit;
                    rateList[index] = item.rate;
                }
            });

            const response = await promotionService.prototype.create(
                model.name,
                personaList.toString(),
                packageList.toString(),
                priceList.toString(),
                creditList.toString(),
                rateList.toString()
            );
            if (response.code === 200) {
                toast.success('Create successfully');
                router.push({ name: 'PromotionPrototypeList' });
            }
        },
        validateItem: (subChangeset: any) => {
            subChangeset.canAdd = !isBlank(subChangeset.packageId) && Number(subChangeset.rate) > 100;
        },
        selectIAP: (iapId: any, subChangeset: any) => {
            ipaList.value.forEach(item => {
                if (item.id.toString() == iapId) {
                    subChangeset.packageId = item.packageId;
                    subChangeset.price = item.price;
                    subChangeset.credit = item.credit;
                }
            });

            actions.validateItem(subChangeset);
        },
        changeRate: (subChangeset: any) => {
            actions.validateItem(subChangeset);
        },
        clearRow: (subChangeset: any) => {
            subChangeset.iapId = '0';
            subChangeset.packageId = '';
            subChangeset.price = '0';
            subChangeset.credit = '0';
            subChangeset.rate = '0';
            subChangeset.canAdd = false;
        }
    };

    return { model, v$, validateModel, fetch, beforeFetch, rfmGroupOptions, actions };
}

function isBlank(str: string) {
    return !str || str.trim().length === 0;
}
