
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppButton, AppCard } from '@/components';
import { useCreditTicketEdit } from '@/modules/credit/ticket/model/edit';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'CreditTicketEdit',
    components: { AppFormRow, AppInput, AppButton, AppCard },
    setup() {
        const { model, v$, update, fetch } = useCreditTicketEdit();

        onMounted(async () => {
            await fetch(useRoute().params.id);
        });

        return { v$, model, update };
    }
});
