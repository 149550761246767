
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppCard, AppInput, AppSimpleTable } from '@/components';
import { usePromotionPrototypeDetail } from '@/modules/promotion/prototype/model/detail';

export default defineComponent({
    name: 'PrototypeDetail',
    components: { AppFormRow, AppCard, AppInput, AppSimpleTable },

    setup() {
        const { model, fetch } = usePromotionPrototypeDetail();

        onMounted(async () => {
            await fetch();
        });

        return { model };
    }
});
