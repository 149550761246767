
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import useIapAdd from '@/modules/iap/model/add';

export default defineComponent({
    name: 'IAPAdd',
    components: { AppFormRow, AppInput, AppSelect, AppButton, AppCard },
    setup() {
        const { v$, model, features, create } = useIapAdd();

        return { v$, model, features, create };
    }
});
