
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppButton } from '@/components';
import useMessageSend from '@/modules/message/model/send';

export default defineComponent({
    name: 'SendMessage',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppButton },
    setup() {
        const { model, send, options, v$ } = useMessageSend();

        return { v$, model, options, send };
    }
});
