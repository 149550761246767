
import { generateID } from '@/utils/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppCheckbox',
    props: {
        modelValue: { type: Boolean, default: false },
        type: { type: String, default: 'default' },
        label: { type: String, default: '' }
    },
    emits: ['update:modelValue'],
    setup(_, { emit }) {
        const ID: string = generateID();

        function onValueChange(event: any) {
            emit('update:modelValue', event.target.checked);
        }

        return { ID, onValueChange };
    }
});
