
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton } from '@/components';
import { useGameReport } from '@/modules/game/report/model/edit';

export default defineComponent({
    name: 'GameReport',
    components: { AppFormRow, AppInput, AppCard, AppButton },
    setup() {
        const { model, v$, fetch, actions } = useGameReport();

        onMounted(async () => {
            await fetch();
        });

        return { model, v$, actions };
    }
});
