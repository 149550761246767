
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect } from '@/components';
import { useAvatarBundleEdit } from '@/modules/avatar/bundle/model/edit';

export default defineComponent({
    name: 'AvatarBundleEdit',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect },
    setup() {
        const { model, v$, fetch, actions, routes, genderList, statusList } = useAvatarBundleEdit();

        onMounted(() => {
            fetch();
        });

        return { v$, model, actions, routes, genderList, statusList };
    }
});
