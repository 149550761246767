
import { defineComponent } from 'vue';
import { AppFormRow, AppFileUpload, AppButton, AppCard } from '@/components';
import { useTranslationExport } from '@/modules/translation/model/export';

export default defineComponent({
    name: 'TranslationExport',
    components: { AppFormRow, AppFileUpload, AppButton, AppCard },
    setup() {
        const { model, exportXml, importXml } = useTranslationExport();

        return { model, exportXml, importXml };
    }
});
