import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-horizontal" }
const _hoisted_2 = { class: "card-footer" }
const _hoisted_3 = { class: "col text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_file_upload = _resolveComponent("app-file-upload")!
  const _component_app_form_row = _resolveComponent("app-form-row")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_card = _resolveComponent("app-card")!

  return (_openBlock(), _createBlock(_component_app_card, {
    class: "col-lg-8 mx-auto",
    label: "Import Avatars"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createVNode(_component_app_form_row, { label: "Archive File" }, {
          default: _withCtx(() => [
            _createVNode(_component_app_file_upload, {
              accept: ".zip",
              modelValue: _ctx.model.file,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.file) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_button, {
              name: "Import",
              theme: "info",
              icon: "fa-file-import",
              disabled: !_ctx.model.file,
              onClick: _withModifiers(_ctx.importFile, ["prevent"])
            }, null, 8, ["disabled", "onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}