import http from '@/utils/axios';

const get = () => {
    return http.doGet('/admin/facebook/setting');
};

const remove = () => {
    return http.doDelete('/admin/facebook/setting');
};

const update = (fbAppId: any, name: any, secret: any, accessToken: any, hasBusiness: any) => {
    return http.doPut('/admin/facebook/setting', {
        fbAppId: fbAppId,
        name: name,
        secret: secret,
        accessToken: accessToken,
        hasBusiness: hasBusiness
    });
};

export function facebookService() {
    return {
        get,
        remove,
        update
    };
}
