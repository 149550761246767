import { useAvatarColorList } from '@/modules/avatar/color/model/list';
import { useAvatarLayerList } from '@/modules/avatar/layer/model/list';
import { useVDialog } from '@/plugins/dialog/v-dialog';
import { avatarService } from '@/services/avatar';
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useAvatarImageEdit() {
    const dialog = useVDialog();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const avatarItem = ref(null);
    const { fetchList: fetchColors, itemList: colors } = useAvatarColorList();
    const { fetchList: fetchLayers, itemList: layers } = useAvatarLayerList();
    const colorOption = computed(() => colors.value.map(c => ({ label: c.name, value: c.id })));
    const layerOption = computed(() => layers.value.map(l => ({ label: l.name, value: l.id })));

    const model = reactive({
        id: null,
        itemId: null,
        colorId: null,
        layerId: null,
        isDefault: false,
        image: null,
        imageStatus: null,
        imageFile: null,
        canDelete: false
    });

    const beforeFetch = async () => {
        await Promise.all([fetchColors(), fetchLayers()]);
    };

    const fetch = async () => {
        const [response1, response2] = await Promise.all([
            avatarService.item.get(route.params.itemId),
            avatarService.image.get(route.params.itemId, route.params.imageId)
        ]);

        if (response1.code !== 200 || response2.code !== 200) {
            const errMsg = response1.code !== 200 ? response1.data : response2.data;
            toast.error(errMsg);
            router.push({ name: 'SysAvatarItemImageList' });
        }

        avatarItem.value = response1.data;
        Object.assign(model, response2.data);
    };

    const actions = {
        update: async () => {
            const response = await avatarService.image.update(
                model.itemId,
                model.id,
                model.colorId,
                model.layerId,
                model.isDefault,
                model.imageFile
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                router.push({ name: 'SysAvatarItemImageList', params: { itemId: model.itemId } });
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this image?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await avatarService.image.remove(model.itemId, model.id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            router.push({ name: 'SysAvatarItemImageList', params: { itemId: model.itemId } });
                        }
                    }
                }
            });
        }
    };

    return { model, colorOption, layerOption, avatarItem, beforeFetch, fetch, actions };
}
