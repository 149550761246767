import { useVDialog } from '@/plugins/dialog/v-dialog';
import { gameService } from '@/services/game';
import { validateFormat } from '@/utils/validatorHelpers';
import useVuelidate from '@vuelidate/core';
import { helpers, integer, minValue, required } from '@vuelidate/validators';
import { reactive } from 'vue';
import { useToast } from 'vue-toastification';

export function useGameParameter() {
    const dialog = useVDialog();
    const toast = useToast();

    const model = reactive({
        phaseNames: '',
        phaseTimes: '',
        rankPenaltyPercent: 0,
        botPayPercent: 0,
        botPlayableTime: 0,
        betRange: '',
        rankBoostCharge: 0,
        rankBoostRejectLimit: 0,
        lobbyLimitTable: 0,
        canRemove: false
    });

    const validations = {
        phaseNames: { required, format: validateFormat(/^[a-zA-Z0-9,]*$/) },
        phaseTimes: {
            required,
            format: validateFormat(/^\d+(,\d+)*$/),
            validateConfirmArrayLength: helpers.withMessage(
                'Names and Times must have same number of item',
                (value: string, vm: any) => value.split(',').length == vm.phaseNames.split(',').length
            )
        },
        betRange: { required, format: validateFormat(/^(-?\d+,-?\d+,-?\d+)(;(-?\d+,-?\d+,-?\d+))*$/) },
        rankPenaltyPercent: { required, integer, minValue: minValue(100) },
        botPayPercent: { required, integer, minValue: minValue(0) },
        botPlayableTime: { required, integer, minValue: minValue(0) },
        rankBoostCharge: { required, integer, minValue: minValue(0) },
        rankBoostRejectLimit: { required, integer, minValue: minValue(0) },
        lobbyLimitTable: { required, integer, minValue: minValue(0) }
    };

    const v$ = useVuelidate(validations, model);

    const fetch = async () => {
        const response = await gameService.parameter.get();
        const result = response.data;
        model.phaseNames = result.phaseNames;
        model.phaseTimes = result.phaseTimes;
        model.rankPenaltyPercent = result.rankPenaltyPercent;
        model.botPayPercent = result.botPayPercent;
        model.botPlayableTime = result.botPlayableTime;
        model.betRange = result.betRange;
        model.rankBoostCharge = result.rankBoostCharge;
        model.rankBoostRejectLimit = result.rankBoostRejectLimit;
        model.lobbyLimitTable = result.lobbyLimitTable;
        model.canRemove = result.canRemove;
    };

    const actions = {
        update: async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return;
            }

            const response = await gameService.parameter.update(
                model.phaseNames,
                model.phaseTimes,
                model.rankPenaltyPercent,
                model.botPayPercent,
                model.botPlayableTime,
                model.betRange,
                model.rankBoostCharge,
                model.rankBoostRejectLimit,
                model.lobbyLimitTable
            );
            if (response.code === 200) {
                toast.success('Update successfully');
                fetch();
            }
        },
        remove: () => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await gameService.parameter.remove();
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            await fetch();
                            v$.value.$reset();
                        }
                    }
                }
            });
        }
    };

    return { model, v$, fetch, actions };
}
