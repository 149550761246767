import { useVDialog } from '@/plugins/dialog/v-dialog';
import { arenaService } from '@/services/arena';
import { useFetchList } from '@/utils/useFetch';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useArenaMedalList() {
    const dialog = useVDialog();
    const router = useRouter();
    const toast = useToast();

    const { itemList, total, fetchList } = useFetchList(arenaService.medal.list, null);

    const actions = {
        remove: (id: any) => {
            dialog.confirm({
                message: 'Are you sure you want to remove this item?',
                onConfirm: async (result: boolean) => {
                    if (result) {
                        const response = await arenaService.medal.remove(id);
                        if (response.code === 200) {
                            toast.success('Delete successfully');
                            fetchList();
                        } else {
                            toast.error(response.data);
                        }
                    }
                }
            });
        }
    };

    const routes = {
        add: () => router.push({ name: 'ArenaMedalAdd' }),
        edit: (id: any) => router.push({ name: 'ArenaMedalEdit', params: { id: id } })
    };

    return { itemList, total, fetchList, actions, routes };
}
