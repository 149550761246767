import http from '@/utils/axios';

export const bonusService = {
    login: {
        list() {
            return http.doGet('/admin/bonus/login');
        },

        get(id: any) {
            return http.doGet('/admin/bonus/login/' + id);
        },

        create(
            name: any,
            type: any,
            value: any,
            adsBoost: any,
            adsRescue: any,
            timeUnit: any,
            timeDuration: any,
            startTime: any
        ) {
            return http.doPost('/admin/bonus/login', {
                name: name,
                type: type,
                value: value,
                adsBoost: adsBoost,
                adsRescue: adsRescue,
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                startTime: startTime
            });
        },

        update(
            id: any,
            name: any,
            value: any,
            adsBoost: any,
            adsRescue: any,
            timeUnit: any,
            timeDuration: any,
            startTime: any
        ) {
            return http.doPut('/admin/bonus/login/' + id, {
                name: name,
                value: value,
                adsRescue: adsRescue,
                adsBoost: adsBoost,
                timeUnit: timeUnit,
                timeDuration: timeDuration,
                startTime: startTime
            });
        },

        updateAdsBoost(id: any, adsBoost: any) {
            return http.doPut('/admin/bonus/login/' + id + '/ads', {
                adsBoost: adsBoost
            });
        },

        remove(id: any) {
            return http.doDelete('/admin/bonus/login/' + id);
        }
    },

    boost: {
        get() {
            return http.doGet('admin/bonus/boost');
        },

        update(routineValue: any, newPlayerValue: any, startTime: any, endTime: any) {
            return http.doPut('admin/bonus/boost', {
                routineValue: routineValue,
                newPlayerValue: newPlayerValue,
                startTime: startTime,
                endTime: endTime
            });
        },

        remove() {
            return http.doDelete('/admin/bonus/boost');
        }
    }
};
