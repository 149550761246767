
import { defineComponent, onMounted } from 'vue';
import { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload } from '@/components';
import { useProgressLevelAdd } from '@/modules/progress/level/model/add';

export default defineComponent({
    name: 'ProgressAdd',
    components: { AppFormRow, AppInput, AppCard, AppButton, AppSelect, AppFileUpload },
    setup() {
        const { model, v$, create, progressInfo, linkableItems, fetch } = useProgressLevelAdd();

        onMounted(async () => {
            await fetch();
        });

        return { v$, model, create, progressInfo, linkableItems, typeList };
    }
});

const typeList = [
    { label: 'Totem', value: 1, background: 'info' },
    { label: 'CompleteGame', value: 2, background: 'info' },
    { label: 'WinGame', value: 3, background: 'info' },
    { label: 'Promotion', value: 4, background: 'info' },
    { label: 'ArenaComplete', value: 5, background: 'info' },
    { label: 'ArenaWin', value: 6, background: 'info' },
    { label: 'SlotComplete', value: 7, background: 'info' },
    { label: 'SlotWin', value: 8, background: 'info' },
    { label: 'ScratchCardComplete', value: 10, background: 'info' },
    { label: 'ScratchCardWin', value: 11, background: 'info' },
    { label: 'DuplicateGameComplete', value: 12, background: 'info' }
];
