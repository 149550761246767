
import { defineComponent } from 'vue';
import { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton } from '@/components';
import { useTournamentLeaderboardAdd } from '@/modules/tournament/leaderboard/model/add';
import { timeUnits } from '@/utils/helpers';

export default defineComponent({
    name: 'TournamentLeaderboardAdd',
    components: { AppFormRow, AppInput, AppCard, AppSelect, AppDateTimePicker, AppButton },
    setup() {
        const { model, v$, create } = useTournamentLeaderboardAdd();

        return { v$, model, create, timeUnits };
    }
});
