import { useGameVariantList } from '@/modules/game/variant/model/list';
import { gameService } from '@/services/game';
import { useFetchList } from '@/utils/useFetch';
import { reactive, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMixins } from './mixins';

export function useGameBetList() {
    const router = useRouter();
    const route = useRoute();
    const { itemList: variantList, fetchList: getVariantList } = useGameVariantList();
    const { options, remove } = useMixins();

    const model = reactive({
        variantId: route.query?.variantId ? Number(route.query?.variantId) : null,
        variantOptions: []
    });

    const { itemList, total, fetchList } = useFetchList(gameService.bet.list, {
        options: toRef(model, 'variantId')
    });

    const beforeFetch = async () => {
        await getVariantList();
        model.variantOptions = variantList.value.map((item: any) => {
            return { label: item.name, value: item.id };
        });
    };

    const fetch = async () => {
        if (model.variantId != null) {
            fetchList();
        }
    };

    const routes = {
        index: (id: any) => router.push({ name: 'GameBetList', query: { variantId: id } }),
        add: () => router.push({ name: 'GameBetAdd', query: { variantId: model.variantId } }),
        edit: (id: any) => router.push({ name: 'GameBetEdit', params: { id: id } }),
        detail: (id: any) => router.push({ name: 'GameBetDetail', params: { id: id } })
    };

    return { model, itemList, total, options, beforeFetch, fetch, remove, routes };
}
