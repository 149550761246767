import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nav-item dropdown",
  ref: "dropdownElement"
}
const _hoisted_2 = { class: "nav-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_dropdown_menu = _resolveComponent("app-dropdown-menu")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, [
      _renderSlot(_ctx.$slots, "dropdown-button")
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createBlock(_component_app_dropdown_menu, {
          key: 0,
          size: _ctx.size
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dropdown-menu")
          ]),
          _: 3
        }, 8, ["size"]))
      : _createCommentVNode("", true)
  ], 512))
}