
import { computed, defineComponent, reactive } from 'vue';
import { AppDropdown } from '@/components';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { App } from '@/store/applications';

export default defineComponent({
    name: 'ApplicationsDropdown',
    components: { AppDropdown },
    setup() {
        const router = useRouter();
        const store = useStore();

        const isMaintained = computed(() => store.getters['maintenance/isMaintained']);

        const model = reactive({
            selectedApplication: store.getters['application/appId'] as number,
            applications: [...store.getters['application/apps'], { id: null, name: 'All Application' }] as Array<App>
        });

        const getApplicationName = computed(() => {
            return model.applications.find(app => app.id === model.selectedApplication)?.name ?? '';
        });

        async function changeApplication(appId: number) {
            if (appId == null) {
                router.push({ name: 'ApplicationList' });
                return;
            }

            model.selectedApplication = appId;
            store.dispatch('application/changeAppId', appId);
            // TODO: re-fetch data
            router.go(0);
        }

        return { model, getApplicationName, isMaintained, changeApplication };
    }
});
