const DOTS = ' ... ';
const SIBLING = 1;

export const getPagination = (totalCount: number, pageSize: any, currentPage: number) => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as SIBLING + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = SIBLING + 5;

    /*
    Case 1:
    If the number of pages is less than the page numbers we want to show, 
    we return the range [1..totalPageCount]
  */
    if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
    }

    /*
      Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
  */
    const leftSiblingIndex = Math.max(currentPage - SIBLING, 1);
    const rightSiblingIndex = Math.min(currentPage + SIBLING, totalPageCount);

    /*
    We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
  */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
      Case 2: No left dots to show, but rights dots to be shown
  */
    if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 3 + 2 * SIBLING;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
    }

    /*
      Case 3: No right dots to show, but left dots to be shown
  */
    if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 3 + 2 * SIBLING;
        const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
        return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
      Case 4: Both left and right dots to be shown
  */
    if (shouldShowLeftDots && shouldShowRightDots) {
        const middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
};

const range = (start: number, end: number) => {
    const length = end - start + 1;
    /*
    Create an array of certain length and set the elements within it from start value to end value.
  */
    return Array.from({ length }, (_, idx) => idx + start);
};
